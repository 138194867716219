import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend, TimeScale } from 'chart.js';
import { format } from 'date-fns';
ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, TimeScale);
ChartJS.defaults.font.size = 14;
ChartJS.defaults.font.family = 'Poppins';

export const getYAsixTitle = (defaultNomSettings: DefaultDOM, titleText: string) => {
  if (defaultNomSettings?.temp_UOM) {
    return titleText + ' (°' + defaultNomSettings.temp_UOM + ')';
  }
};

export interface DefaultDOM {
  temp_UOM: string;
  washRate_UOM: string;
  velocity_UOM: string;
}

export const setTowerTopGraphConfig = (defaultUOM: DefaultDOM, setTowerTopOption: any) => {
  const now = new Date();
  const year = now.getFullYear();
  const length = 90;
  const days = Array.from({ length }, (_, days) => {
    let day = new Date(now); // clone "now"
    day.setDate(now.getDate() - days); // change the date
    return day;
  });
  const days2 = Array.from({ length }, (_, days2) => {
    let day2 = new Date(now); // clone "now"
    day2.setDate(now.getDate() + days2); // change the date
    return day2;
  });
  let minDate = days && days.length && days[89].toISOString().split('T')[0];
  let maxDate = days2 && days2.length && days2[1].toISOString().split('T')[0];
  const towerTopOptionData: any = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Dates (' + year + ' ,90 days)',
        },
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          maxTicksLimit: days && days.length && days.length / 9,
        },
        min: minDate,
        max: maxDate,
      },
      y: {
        title: {
          display: true,
          text: getYAsixTitle(defaultUOM, 'Temperature'),
        },
        min: 0,
        max: 350,
      },
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 50,
        top: 30,
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
        position: 'bottom',
      },
    },
  };
  setTowerTopOption(towerTopOptionData);
};

export const setTowerTopDiffGraphConfig = (
  setTowerTopDiffOption: any,
  defaultUOM: any,
  setCorrosionLowRiskLine: any,
  setCorrosionHighRiskLine: any,
  setShowTowerTopDiffGraph: any
) => {
  const now = new Date();
  const year = now.getFullYear();
  const length = 90;
  const days = Array.from({ length }, (_, days) => {
    let day = new Date(now); // clone "now"
    day.setDate(now.getDate() - days); // change the date
    return day;
  });
  const days2 = Array.from({ length }, (_, days2) => {
    let day2 = new Date(now); // clone "now"
    day2.setDate(now.getDate() + days2); // change the date
    return day2;
  });
  let minDate = days && days.length && days[89].toISOString().split('T')[0];
  let maxDate = days2 && days2.length && days2[1].toISOString().split('T')[0];

  const corrosionHighRiskLine = {
    id: 'corrosionHighRiskLine',
    afterDatasetsDraw(chart: ChartJS, args: any, pluginOptions: any) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
        scales: { x, y },
      } = chart;

      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = '#C21A2C';
      ctx.lineWidth = 2;
      //x.getPixelForTick(0) means first point in the x axis
      ctx.moveTo(x.getPixelForTick(0), y.getPixelForValue(0));
      ctx.lineTo(right, y.getPixelForValue(0));
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    },
  };
  setCorrosionHighRiskLine(corrosionHighRiskLine);
  const corrosionLowRiskLine = {
    id: 'corrosionLowRiskLine',
    afterDatasetsDraw(chart: ChartJS, args: any, pluginOptions: any) {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = '#02A783';
      ctx.lineWidth = 2;
      ctx.moveTo(x.getPixelForTick(0), y.getPixelForValue(25));
      ctx.lineTo(right, y.getPixelForValue(25));
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    },
  };
  setCorrosionLowRiskLine(corrosionLowRiskLine);
  if(setShowTowerTopDiffGraph) {
    setShowTowerTopDiffGraph(true);
  }
  const towerTopDiffOptionData: any = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Dates (' + year + ' ,90 days)',
        },
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          maxTicksLimit: days && days.length && days.length / 9,
        },
        min: minDate,
        max: maxDate,
      },
      y: {
        title: {
          display: true,
          text: getYAsixTitle(defaultUOM, 'Temperature Difference'),
        },
        min: -50,
        max: +200,
      },
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 50,
        top: 30,
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
        position: 'bottom',
      },
    },
  };
  setTowerTopDiffOption(towerTopDiffOptionData);
};


export const setDewPointGraphConfig = (defaultUOM :DefaultDOM, setDewPointOption : any) => {
  const now = new Date();
  const year = now.getFullYear();
  const length = 90;
  const days = Array.from({ length }, (_, days) => {
    let day = new Date(now); // clone "now"
    day.setDate(now.getDate() - days); // change the date
    return day;
  });
  const days2 = Array.from({ length }, (_, days2) => {
    let day2 = new Date(now); // clone "now"
    day2.setDate(now.getDate() + days2); // change the date
    return day2;
  });
  let minDate = days && days.length && days[89].toISOString().split('T')[0];
  let maxDate = days2 && days2.length && days2[1].toISOString().split('T')[0];
  const dewPointOptionData: any = {
    scales: {
      x: {
        title: {
          display: true,
          text: 'Dates (' + year + ' ,90 days)',
        },
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          maxTicksLimit: days && days.length && days.length / 9,
        },
        min: minDate,
        max: maxDate,
      },
      y: {
        title: {
          display: true,
          text: getYAsixTitle(defaultUOM, 'Temperature'),
        },
        min: 0,
        max: 350,
      },
    },
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 50,
        top: 30,
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
        position: 'bottom',
      },
    },
  };
  setDewPointOption(dewPointOptionData);
};


  export const setDewPointDiffGraphConfig = (setCorrosionHighRiskLineDewPoint :any, setCorrosionLowRiskLineDewPoint :any, defaultUOM :DefaultDOM, setDewPointDiffOption :any, setShowDewPointDiffGraph :any) => {
    const now = new Date();
    const year = now.getFullYear();
    const length = 90;
    const days = Array.from({ length }, (_, days) => {
      let day = new Date(now); // clone "now"
      day.setDate(now.getDate() - days); // change the date
      return day;
    });
    const days2 = Array.from({ length }, (_, days2) => {
      let day2 = new Date(now); // clone "now"
      day2.setDate(now.getDate() + days2); // change the date
      return day2;
    });
    let minDate = days && days.length && days[89].toISOString().split('T')[0];
    let maxDate = days2 && days2.length && days2[1].toISOString().split('T')[0];

    const corrosionHighRiskLine = {
      id: 'corrosionHighRiskLineDewPoint',
      afterDatasetsDraw(chart: ChartJS, args: any, pluginOptions: any) {
        const {
          ctx,
          chartArea: { top, bottom, left, right, width, height },
          scales: { x, y },
        } = chart;
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = '#C21A2C';
        ctx.lineWidth = 2;
        //x.getPixelForTick(0) means first point in the x axis
        ctx.moveTo(x.getPixelForTick(0), y.getPixelForValue(0));
        ctx.lineTo(right, y.getPixelForValue(0));
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
      },
    };
    setCorrosionHighRiskLineDewPoint(corrosionHighRiskLine);

    const corrosionLowRiskLine = {
      id: 'corrosionLowRiskLineDewPoint',
      beforeDatasetsDraw(chart: ChartJS, args: any, pluginOptions: any) {
        const {
          ctx,
          chartArea: { top, bottom, left, right, width, height },
          scales: { x, y },
        } = chart;
        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = '#02A783';
        ctx.lineWidth = 2;
        ctx.moveTo(x.getPixelForTick(0), y.getPixelForValue(10));
        ctx.lineTo(right, y.getPixelForValue(10));
        ctx.stroke();
        ctx.closePath();
        ctx.restore();
      },
    };
    setCorrosionLowRiskLineDewPoint(corrosionLowRiskLine);
    if(setShowDewPointDiffGraph) {
      setShowDewPointDiffGraph(true);
    }


    const dewPointDiffOptionData: any = {
      scales: {
        x: {
          title: {
            display: true,
            text: 'Dates (' + year + ' ,90 days)',
          },
          type: 'time',
          time: {
            unit: 'day',
          },
          ticks: {
            maxTicksLimit: days && days.length && days.length / 9,
          },
          min: minDate,
          max: maxDate,
        },
        y: {
          title: {
            display: true,
            text: getYAsixTitle(defaultUOM, 'Temperature Difference'),
          },
          min: -50,
          max: +200,
        },
      },
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 50,
          top: 30,
        },
      },
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
          position: 'bottom',
        },
      },
    };
    setDewPointDiffOption(dewPointDiffOptionData);
  };

  export  const removeNeutralizerSalt = (listOfData :any)=> {
    let removedItem =  listOfData.datasets.filter((item :any)=> item.label !== 'Neutralizer Salt');
    return removedItem;
   }


   export  const mapSaltGraphData = (apiResult: any, isCokerOrFcc : any, setTowerTopData :any, setTowerTopDiffData :any, setDewPointData :any, setDewPointDiffData: any, isFromPDF : boolean) => {
    let towerTopAmmoniaSaltTemp: any = [];
    let towerTopAmmoniaSaltTempDiff: any = [];
    let towerTopNeutralizerSaltTemp: any = [];
    let towerTopNeutralizerSaltTempDiff: any = [];
    let towerTopSteamAmineSaltTemp: any = [];
    let towerTopSteamAmineSaltTempDiff: any = [];
    let towerTopTrampAmineSaltTemp: any = [];
    let towerTopTrampAmineSaltTempDiff: any = [];
    let towerTopOverheadTITemp: any = [];
    let dewPointAmmoniaSaltTemp: any = [];
    let dewPointAmmoniaSaltTempDiff: any = [];
    let dewPointNeutralizerSaltTemp: any = [];
    let dewPointNeutralizerSaltTempDiff: any = [];
    let dewPointSteamAmineSaltTemp: any = [];
    let dewPointSteamAmineSaltTempDiff: any = [];
    let dewPointTrampAmineSaltTemp: any = [];
    let dewPointTrampAmineSaltTempDiff: any = [];
    let waterDewPointTemp: any = [];
    if (apiResult && apiResult.length) {
      apiResult.forEach((resultItem: any) => { 
        let formattedDate: any = isFromPDF ?  format(new Date(resultItem.inputDate), 'yyyy-MM-dd') : new Date(resultItem.inputDate);
        //tower top section
        towerTopAmmoniaSaltTemp.push({
          x: formattedDate,
          y: resultItem.towerTop?.ammoniaSaltTemp,
        });
        towerTopAmmoniaSaltTempDiff.push({
          x: formattedDate,
          y: resultItem.towerTop?.ammoniaSaltTempDiff,
        });
        towerTopNeutralizerSaltTemp.push({
          x: formattedDate,
          y: resultItem.towerTop?.neutralizerSaltTemp,
        });
        towerTopNeutralizerSaltTempDiff.push({
          x: formattedDate,
          y: resultItem.towerTop?.neutralizerSaltTempDiff,
        });
        towerTopSteamAmineSaltTemp.push({
          x: formattedDate,
          y: resultItem.towerTop?.steamAmineSaltTemp,
        });
        towerTopSteamAmineSaltTempDiff.push({
          x: formattedDate,
          y: resultItem.towerTop?.steamAmineSaltTempDiff,
        });
        towerTopTrampAmineSaltTemp.push({
          x: formattedDate,
          y: resultItem.towerTop?.trampAmineSaltTemp,
        });
        towerTopTrampAmineSaltTempDiff.push({
          x: formattedDate,
          y: resultItem.towerTop?.trampAmineSaltTempDiff,
        });
        towerTopOverheadTITemp.push({
          x: formattedDate,
          y: resultItem?.overheadTITemp,
        });
        //water dew point section
        dewPointAmmoniaSaltTemp.push({
          x: formattedDate,
          y: resultItem.dewPoint?.ammoniaSaltTempDP,
        });
        dewPointAmmoniaSaltTempDiff.push({
          x: formattedDate,
          y: resultItem.dewPoint?.ammoniaSaltTempDiffDP,
        });
        dewPointNeutralizerSaltTemp.push({
          x: formattedDate,
          y: resultItem.dewPoint?.neutralizerSaltTempDP,
        });
        dewPointNeutralizerSaltTempDiff.push({
          x: formattedDate,
          y: resultItem.dewPoint?.neutralizerSaltTempDiffDP,
        });
        dewPointSteamAmineSaltTemp.push({
          x: formattedDate,
          y: resultItem.dewPoint?.steamAmineSaltTempDP,
        });
        dewPointSteamAmineSaltTempDiff.push({
          x: formattedDate,
          y: resultItem.dewPoint?.steamAmineSaltTempDiffDP,
        });
        dewPointTrampAmineSaltTemp.push({
          x: formattedDate,
          y: resultItem.dewPoint?.trampAmineSaltTempDP,
        });
        dewPointTrampAmineSaltTempDiff.push({
          x: formattedDate,
          y: resultItem.dewPoint?.trampAmineSaltTempDiffDP,
        });
        waterDewPointTemp.push({
          x: formattedDate,
          y: resultItem?.waterDewPointTemp,
        });
      });
    }
    //set tower top data
    let towerTopGraphData: any = {
      datasets: [
        {
          label: 'Ammonia Salt',
          data: towerTopAmmoniaSaltTemp,
          pointStyle: 'triangle',
          pointRadius: 5,
          backgroundColor: '#AF74B9',
        },
        {
          label: 'Neutralizer Salt',
          data: towerTopNeutralizerSaltTemp,
          pointStyle: 'star',
          pointRadius: 5,
          borderColor: '#F0373A',
          backgroundColor: '#F0373A',
        },
        {
          label: 'Tramp Amine Salt',
          data: towerTopTrampAmineSaltTemp,
          pointStyle: 'rectRot',
          pointRadius: 5,
          backgroundColor: '#B0CD5D',
        },
        {
          label: 'Steam Amine Salt',
          data: towerTopSteamAmineSaltTemp,
          pointStyle: 'rect',
          pointRadius: 5,
          backgroundColor: '#2CB0BC',
        },
        {
          label: 'Overhead TI Temperature',
          data: towerTopOverheadTITemp,
          pointStyle: 'line',
          pointRadius: 5,
          borderColor: '#5D37A7',
          backgroundColor: '#5D37A7',
        },
      ],
    };
    if(isCokerOrFcc) {
      towerTopGraphData.datasets = removeNeutralizerSalt(towerTopGraphData);
    }
    setTowerTopData(towerTopGraphData);
    //set tower top diff data
    let towerTopDiffGraphData: any = {
      datasets: [
        {
          label: 'Ammonia Salt',
          data: towerTopAmmoniaSaltTempDiff,
          pointStyle: 'triangle',
          pointRadius: 5,
          backgroundColor: '#AF74B9',
        },
        {
          label: 'Neutralizer Salt',
          data: towerTopNeutralizerSaltTempDiff,
          pointStyle: 'star',
          pointRadius: 5,
          borderColor: '#F0373A',
          backgroundColor: '#F0373A',
        },
        {
          label: 'Tramp Amine Salt',
          data: towerTopTrampAmineSaltTempDiff,
          pointStyle: 'rectRot',
          pointRadius: 5,
          backgroundColor: '#B0CD5D',
        },
        {
          label: 'Steam Amine Salt',
          data: towerTopSteamAmineSaltTempDiff,
          pointStyle: 'rect',
          pointRadius: 5,
          backgroundColor: '#2CB0BC',
        },
        {
          label: 'Corrosion Risk - High (0 °F)',
          data: 25,
          pointStyle: 'line',
          pointRadius: 5,
          borderColor: '#C21A2C',
        },
        {
          label: 'Corrosion Risk - Low (25 °F)',
          data: 10,
          pointStyle: 'line',
          pointRadius: 5,
          borderColor: '#02A783',
        },
      ],
    };
    if(isCokerOrFcc) {
      towerTopDiffGraphData.datasets = removeNeutralizerSalt(towerTopDiffGraphData);
    }
    setTowerTopDiffData(towerTopDiffGraphData);
    //set water dew point data
    let dewPointGraphData: any = {
      datasets: [
        {
          label: 'Ammonia Salt',
          data: dewPointAmmoniaSaltTemp,
          pointStyle: 'triangle',
          pointRadius: 5,
          backgroundColor: '#AF74B9',
        },
        {
          label: 'Neutralizer Salt',
          data: dewPointNeutralizerSaltTemp,
          pointStyle: 'star',
          pointRadius: 5,
          borderColor: '#F0373A',
          backgroundColor: '#F0373A',
        },
        {
          label: 'Tramp Amine Salt',
          data: dewPointTrampAmineSaltTemp,
          pointStyle: 'rectRot',
          pointRadius: 5,
          backgroundColor: '#B0CD5D',
        },
        {
          label: 'Steam Amine Salt',
          data: dewPointSteamAmineSaltTemp,
          pointStyle: 'rect',
          pointRadius: 5,
          backgroundColor: '#2CB0BC',
        },
        {
          label: 'Water Dew Point Temperature',
          data: waterDewPointTemp,
          pointStyle: 'line',
          pointRadius: 5,
          borderColor: '#5D37A7',
          backgroundColor: '#5D37A7',
        },
      ],
    };
    if(isCokerOrFcc) {
      dewPointGraphData.datasets = removeNeutralizerSalt(dewPointGraphData);
    }
    setDewPointData(dewPointGraphData);
    //set water dew point diff data
    let dewPointDiffGraphData: any = {
      datasets: [
        {
          label: 'Ammonia Salt',
          data: dewPointAmmoniaSaltTempDiff,
          pointStyle: 'triangle',
          pointRadius: 5,
          backgroundColor: '#AF74B9',
        },
        {
          label: 'Neutralizer Salt',
          data: dewPointNeutralizerSaltTempDiff,
          pointStyle: 'star',
          pointRadius: 5,
          borderColor: '#F0373A',
          backgroundColor: '#F0373A',
        },
        {
          label: 'Tramp Amine Salt',
          data: dewPointTrampAmineSaltTempDiff,
          pointStyle: 'rectRot',
          pointRadius: 5,
          backgroundColor: '#B0CD5D',
        },
        {
          label: 'Steam Amine Salt',
          data: dewPointSteamAmineSaltTempDiff,
          pointStyle: 'rect',
          pointRadius: 5,
          backgroundColor: '#2CB0BC',
        },
        {
          label: 'Corrosion Risk - High (0 °F)',
          data: 25,
          pointStyle: 'line',
          pointRadius: 5,
          borderColor: '#C21A2C',
        },
        {
          label: 'Corrosion Risk - Low (10 °F)',
          data: 10,
          pointStyle: 'line',
          pointRadius: 5,
          borderColor: '#02A783',
        },
      ],
    };
    if(isCokerOrFcc) {
      dewPointDiffGraphData.datasets = removeNeutralizerSalt(dewPointDiffGraphData);
    }
    setDewPointDiffData(dewPointDiffGraphData);
  };