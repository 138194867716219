import { APIEnum } from "../../constants/api-enum";
import apiRequest from "../../services/api-helper";
import { sortWithKey } from '../../utils/utils';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

declare const window: any;
const FORTH_SIDE = 'Steam 4° SS';
const TOTAL_SIDE = 'Steam 4° SS';
export const subCategoryInputMapping = (uomSubCategories: any, categoryType: any) => {
    let inputMapping: any = {};
    uomSubCategories.forEach((uomSubCategory: any) => {
      let mappingArray: any = [];
      switch (uomSubCategory.subCategoryName) {
        case 'Crude Charge Rate':
        case 'Unit Charge Rate':
          mappingArray.push({ input: 'Crude Charge', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Crude_Charge_Rate'] = mappingArray;
          break;
        case 'Overhead Liquid Flow':
          mappingArray.push({ input: 'Reflux', unitSymbol: uomSubCategory.unitSymbol });
          mappingArray.push({ input: 'Combined_Reflux', unitSymbol: uomSubCategory.unitSymbol });
          mappingArray.push({ input: 'Product', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Overhead_Liquid_Flow'] = mappingArray;
          break;
        case 'Overhead Neutralizer Rate':
          mappingArray.push({ input: 'Neutralizer Rate', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Overhead_Neutralizer_Rate'] = mappingArray;
          break;
        case 'Overhead Water Wash Rate':
          mappingArray.push({ input: 'Wash Rate', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Overhead_Water_Wash_Rate'] = mappingArray;
          break;
        case 'Overhead Gas Flow':
          mappingArray.push({ input: 'Off Gas', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Overhead_Gas_Flow'] = mappingArray;
          break;
        case '1st Side Stripper Steam':
        case 'Total SS Steam':
          mappingArray.push({ input: 'Steam 1° SS', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['First_Side_Stripper_Steam'] = mappingArray;
          break;
        case '2nd Side Stripper Steam':
        case 'Total Furnace Steam':
          mappingArray.push({ input: 'Steam 2° SS', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Second_Side_Stripper_Steam'] = mappingArray;
          break;
        case '3rd Side Stripper Steam':
        case 'Total Other Steam':
          mappingArray.push({ input: 'Steam 3° SS', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Third_Side_Stripper_Steam'] = mappingArray;
          break;
        case '4th Side Stripper Steam':
          mappingArray.push({ input: FORTH_SIDE, unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Fourth_Side_Stripper_Steam'] = mappingArray;
          break;
        case 'Total Tower Steam':
          mappingArray.push({ input: TOTAL_SIDE, unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Stripping_Steam_to_Tower'] = mappingArray;
          break;
        case '5th Side Stripper Steam':
          mappingArray.push({ input: 'Steam 5° SS', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Fifth_Side_Stripper_Steam'] = mappingArray;
          break;
        case '6th Side Stripper Steam':
          mappingArray.push({ input: 'Steam 6° SS', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Sixth_Side_Stripper_Steam'] = mappingArray;
          break;
        case 'Stripping Steam to Tower':
          mappingArray.push({ input: 'Steam Tower', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Stripping_Steam_to_Tower'] = mappingArray;
          break;
        case 'Atm Tower Top Pressure':
          mappingArray.push({ input: 'OH Press', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Atm_Tower_Top_Pressure'] = mappingArray;
          break;
        case 'Hot Drum Pressure':
          mappingArray.push({ input: 'Drum Press', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Hot_Drum_Pressure'] = mappingArray;
          break;
        case 'Pressure at Velocity Calc':
          mappingArray.push({ input: 'Velocity Press', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Pressure_at_Velocity_Calc'] = mappingArray;
          break;
        case 'Pressure before Water Wash':
          mappingArray.push({ input: 'Wash Process Press', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Pressure_before_Water_Wash'] = mappingArray;
          break;
        case 'Temperature':
          mappingArray.push({ input: 'Brine Sample Temperature', unitSymbol: uomSubCategory.unitSymbol });
          mappingArray.push({ input: 'Desalter Temperature', unitSymbol: uomSubCategory.unitSymbol });
          mappingArray.push({ input: 'OH Temp', unitSymbol: uomSubCategory.unitSymbol });
          mappingArray.push({ input: 'Wash Temp', unitSymbol: uomSubCategory.unitSymbol });
          mappingArray.push({ input: 'Wash Process Temp', unitSymbol: uomSubCategory.unitSymbol });
          mappingArray.push({ input: 'Velocity Temp', unitSymbol: uomSubCategory.unitSymbol });
          mappingArray.push({ input: 'Drum Temp', unitSymbol: uomSubCategory.unitSymbol });
          if (categoryType === '1A' || categoryType === '1B') {
            mappingArray.push({ input: 'Distillation Temp0', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp5', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp10', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp20', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp30', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp50', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp70', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp80', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp90', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp95', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp100', unitSymbol: uomSubCategory.unitSymbol });
          } else if (categoryType === '2A' || categoryType === '2B') {
            mappingArray.push({ input: 'Distillation Temp_0', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp_5', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp_10', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp_20', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp_30', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp_50', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp_70', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp_80', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp_90', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp_95', unitSymbol: uomSubCategory.unitSymbol });
            mappingArray.push({ input: 'Distillation Temp_100', unitSymbol: uomSubCategory.unitSymbol });
          }
          inputMapping['Temperature'] = mappingArray;
          break;
        case 'Velocity':
          mappingArray.push({ input: 'Velocity', unitSymbol: uomSubCategory.unitSymbol });
          inputMapping['Velocity'] = mappingArray;
          break;
      }
    });
    localStorage.setItem('categoryInputMapping', JSON.stringify(inputMapping));
  };

  export const FAVORITE_COUNT  = 30;


  export const getDefaultUom = (subUnitId: any, configType: any) => {
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/defaultuoms/${subUnitId}`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((response) => {
        if (Object.keys(response).length && Object.keys(response.responseStatus).length && response.responseStatus.status === 'SUCCESS') {
          if (Object.keys(response.data).length) {
            if (Object.keys(response.data.uomSubCategories).length && response.data.uomSubCategories.length) {
              subCategoryInputMapping(response.data.uomSubCategories, configType);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //set input field and uom class mapping object in local storage
  };

  
  export function searchTreeFavorite(items: any[], term: string, store :any, setFilteredData :any): any[] {
    let result;
    //if search box is not empty
    if (term) {
      result =
        items &&
        items.length &&
        items.reduce((matchedItems, item) => {
          if (item.name.toLowerCase().includes(term.trim().toLowerCase())) {
            matchedItems.push({ expanded: true, customerID : item.customerID,refineryID : item.refineryID, unitID:  item.unitID,  name: item.name, key: item.key, children: item.children });
          } else if (item.children && item.children.length > 0) {
            let newItems = searchTreeFavorite(item.children, term.trim(),store, setFilteredData);

            if (newItems.length > 0) {
              matchedItems.push({ expanded: true,  customerID : item.customerID,refineryID : item.refineryID, unitID :item.unitID,  name: item.name, key: item.key, children: newItems });
            }
          }
          return matchedItems;
        }, []);
        let sortData = sortOrder(result, 'name', 'asc');
        setFilteredData(sortData)
    } else {
      //when search box is empty
      let sortData = sortOrder(store, 'name', 'asc');
      setFilteredData(sortData)
    }
    return result;
  }


  export const sortOrder = (list: any, key: string, order: string) => {
    let sortedRefinery = sortWithKey(list, key, order);
    if (Array.isArray(sortedRefinery) && sortedRefinery.length > 0) {
      sortedRefinery.forEach((item: RefineriesData) => {
        item.children = sortWithKey(item.children, key, order);
      });
    }
    return sortedRefinery;
  };

  export interface RefineriesData {
    children: (string | number)[];
    expanded: boolean;
    key: string;
    name: string;
  }
  

  export const commonConditionCheck =(itemWhichSelected :any, selectedItem :any)=>{
    let indexValue = itemWhichSelected.findIndex(
      (item: any) =>
        item.name === selectedItem.name &&
        item.key === selectedItem.key &&
        item.refineryID === selectedItem.refineryID &&
        item.customerID === selectedItem.customerID
    );
    return indexValue;
  }

  export const fromFavoriteSetData = (response: any)=> {
    let subUnitId = response.subUnitDetails.subUnitId;
    let configType = response.subUnitDetails?.configType;
    const refineryName = response.selectedItemRefinery?.refinery?.refineryName;
    const unitName = response.unitDetails?.unitName;
    const subUnitName = response.subUnitDetails?.subUnitName;
    const subTitle: any = refineryName && unitName ? refineryName + ' | ' + unitName + ' | ' + subUnitName : '';
    localStorage.setItem('subtitle', subTitle);
    let configId = response.subUnitDetails?.configId;
    localStorage.setItem('configId', configId);
    localStorage.setItem('configType', response.unitDetails.unitType.trim().toLowerCase() === 'coker' 
    || response.unitDetails.unitType.trim().toLowerCase() === 'fcc' 
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrotreater-distillate'
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrotreater-gas oil'
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrotreater-naphtha'
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrotreater-naphtha renewable'
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrotreater-resid'
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrocracker'
     ? '1A' : configType);
    if(response.unitDetails.unitType.trim().toLowerCase() === 'coker' 
    || response.unitDetails.unitType.trim().toLowerCase() === 'fcc' 
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrotreater-distillate'
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrotreater-gas oil'
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrotreater-naphtha'
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrotreater-naphtha renewable'
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrotreater-resid'
    || response.unitDetails.unitType.trim().toLowerCase() === 'hydrocracker'){
      response.unitDetails.unitType.trim().toLowerCase() === 'coker' ? localStorage.setItem('cokerType', response.subUnitDetails?.cokerType): 
      (response.unitDetails.unitType.trim().toLowerCase() === 'fcc' ? localStorage.setItem('fccType', response.subUnitDetails?.fccType):
      (response.unitDetails.unitType.trim().toLowerCase() === 'hydrocracker' ? localStorage.setItem('hcuType', response.subUnitDetails?.hcuType):
      localStorage.setItem('hdtType', response.subUnitDetails?.hdtType)));
    } 
    else 
    {
       localStorage.setItem('cokerType','');
       localStorage.setItem('fccType','');
       localStorage.setItem('hdtType','');
       localStorage.setItem('hcuType','');
    }
    return { subUnitId : subUnitId, configType : configType }
  }

  export const dataSetForSearchAndFavoritePayload = (unitItem: any, subUnit: any, operatorItem: any) => {
    let refinery: any = [];
    let unit: any = [];
    unit.push({
      unitId: unitItem.unitId,
      name: unitItem.name,
      recordingEstDtim: unitItem.recordingEstDtim,
      status: unitItem.status,
      recordCustomer: unitItem.recordCustomer,
      recordOwner: unitItem.recordOwner,
      recordSource: unitItem.recordSource,
      recordSourceId: unitItem.recordSourceId,
      refinery: unitItem.refinery,
      preflashPrefractionator: unitItem.preflashPrefractionator,
      percentOvhdWaterDesalterWash: unitItem.percentOvhdWaterDesalterWash,
      percentOvhdWaterSWS: unitItem.percentOvhdWaterSWS,
      percentSSWDesalterWash: unitItem.percentSSWDesalterWash,
      uuid: unitItem.uuid,
      unitType: unitItem.unitType,
      subUnit: subUnit,
    });
    refinery = {
      refineryId: operatorItem.refinery.refineryId,
      refineryName: operatorItem.refinery.refineryName,
      refineryType: operatorItem.refinery.refineryType,
      customerName: operatorItem.refinery.customerName,
      customerId: operatorItem.refinery.customerId,
      recordSource: operatorItem.refinery.recordSource,
      recordSourceId: operatorItem.refinery.recordSourceId,
      country: operatorItem.refinery.country,
      status: operatorItem.refinery.status,
      capacity: operatorItem.refinery.capacity,
      capacityUom: operatorItem.refinery.capacityUom,
      city: operatorItem.refinery.city,
      recordOwner: operatorItem.refinery.recordOwner,
      recordingEstDtim: operatorItem.refinery.recordingEstDtim,
      uuid: operatorItem.refinery.uuid,
      unit: unit,
    };
    return { refinery: refinery };
  };

  
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}


  export const BootstrapDialogTitle=(props: DialogTitleProps)=> {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));