import { COLOR_MAX, TWO_STAGE_FROM_TECH, AFTER_WASH } from '../../constants/report-constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ErrorIcon from '@mui/icons-material/Error';
import { convertToFarenheit, convertVelocityToFeetPerSecond } from '../../utils/utils';

export const corrosionRiskTooltip = () => {
  return (
    <div className="tooltip-conatiner">
      <div className="primary-report-container">
        <CheckCircleIcon style={{ color: 'rgba(25, 148, 115, 1)' }} data-testid='check-circle-icon' />
        <p className="tool-tip-text ">Very low risk</p>
      </div>
      <div className="primary-report-container">
        <ArrowCircleRightIcon style={{ color: 'rgba(101, 214, 173, 1)', rotate: '90deg' }} data-testid='arrow-circle-righticon1' />
        <p className="tool-tip-text ">Low risk</p>
      </div>
      <div className="primary-report-container">
        <ArrowCircleRightIcon style={{ color: 'rgba(176, 205, 93, 1)', rotate: '90deg' }} data-testid='arrow-circle-righticon2' />
        <p className="tool-tip-text ">Lower risk</p>
      </div>
      <div className="primary-report-container">
        <ErrorIcon style={{ color: 'rgba(240, 181, 41, 1)' }} />
        <p className="tool-tip-text ">Medium risk</p>
      </div>
      <div className="primary-report-container">
        <ErrorIcon style={{ color: 'rgba(232, 117, 22, 1)' }} />
        <p className="tool-tip-text ">Higher risk</p>
      </div>
      <div className="primary-report-container">
        <ReportProblemIcon style={{ color: 'rgba(225, 110, 117, 1)' }} />
        <p className="tool-tip-text ">High risk</p>
      </div>
      <div className="primary-report-container">
        <ReportProblemIcon style={{ color: 'rgba(194, 26, 44, 1)' }} />
        <p className="tool-tip-text ">Very high risk</p>
      </div>
    </div>
  );
};

export const getRiskIndicatorIcons = (indicator: any) => {
  let riskIndicatorIcon: any = '';
  switch (indicator) {
    case 1:
      riskIndicatorIcon = <CheckCircleIcon style={{ color: 'rgba(25, 148, 115, 1)' }} data-testid='check-circle-icon'/>;
      break;
    case 2:
      riskIndicatorIcon = <ArrowCircleRightIcon style={{ color: 'rgba(101, 214, 173, 1)', rotate: '90deg' }} data-testid='arrow-circle-righticon1' />;
      break;
    case 3:
      riskIndicatorIcon = <ArrowCircleRightIcon style={{ color: 'rgba(176, 205, 93, 1)', rotate: '90deg' }} data-testid='arrow-circle-righticon2' />;
      break;
    case 4:
      riskIndicatorIcon = <ErrorIcon style={{ color: 'rgba(240, 181, 41, 1)' }} data-testid='error-icon1' />;
      break;
    case 5:
      riskIndicatorIcon = <ErrorIcon style={{ color: 'rgba(232, 117, 22, 1)' }} data-testid='error-icon2'  />;
      break;
    case 6:
      riskIndicatorIcon = <ReportProblemIcon style={{ color: 'rgba(225, 110, 117, 1)' }} data-testid='report-problem-icon1' />;
      break;
    case 7:
      riskIndicatorIcon = <ReportProblemIcon style={{ color: 'rgba(194, 26, 44, 1)' }} data-testid='report-problem-icon2' />;
      break;
  }
  return riskIndicatorIcon;
};

export const riskIndicatorTemp = (waterDewPoint: any, ammoniaSalt: any, neutralizerSalt: any, steamAmineSalt: any, trampAmineSalt: any) => {
  let risk = 1;
  if (waterDewPoint > 30 || ammoniaSalt > 30 || (neutralizerSalt && neutralizerSalt > 30) || steamAmineSalt > 30 || trampAmineSalt > 30) {
    risk = 1;
  }
  if (
    (waterDewPoint > 26 && waterDewPoint <= 30) ||
    (ammoniaSalt > 26 && ammoniaSalt <= 30) ||
    (neutralizerSalt && neutralizerSalt > 26 && neutralizerSalt <= 30) ||
    (steamAmineSalt > 26 && steamAmineSalt <= 30) ||
    (trampAmineSalt > 26 && trampAmineSalt <= 30)
  ) {
    risk = 2;
  }
  if (
    (waterDewPoint > 22 && waterDewPoint <= 26) ||
    (ammoniaSalt > 22 && ammoniaSalt <= 26) ||
    (neutralizerSalt && neutralizerSalt > 22 && neutralizerSalt <= 26) ||
    (steamAmineSalt > 22 && steamAmineSalt <= 26) ||
    (trampAmineSalt > 22 && trampAmineSalt <= 26)
  ) {
    risk = 3;
  }
  if (
    (waterDewPoint > 18 && waterDewPoint <= 22) ||
    (ammoniaSalt > 18 && ammoniaSalt <= 22) ||
    (neutralizerSalt && neutralizerSalt > 18 && neutralizerSalt <= 22) ||
    (steamAmineSalt > 18 && steamAmineSalt <= 22) ||
    (trampAmineSalt > 18 && trampAmineSalt <= 22)
  ) {
    risk = 4;
  }
  if (
    (waterDewPoint > 14 && waterDewPoint <= 18) ||
    (ammoniaSalt > 14 && ammoniaSalt <= 18) ||
    (neutralizerSalt && neutralizerSalt > 14 && neutralizerSalt <= 18) ||
    (steamAmineSalt > 14 && steamAmineSalt <= 18) ||
    (trampAmineSalt > 14 && trampAmineSalt <= 18)
  ) {
    risk = 5;
  }
  if (
    (waterDewPoint > 10 && waterDewPoint <= 14) ||
    (ammoniaSalt > 10 && ammoniaSalt <= 14) ||
    (neutralizerSalt && neutralizerSalt > 10 && neutralizerSalt <= 14) ||
    (steamAmineSalt > 10 && steamAmineSalt <= 14) ||
    (trampAmineSalt > 10 && trampAmineSalt <= 14)
  ) {
    risk = 6;
  }
  if (waterDewPoint <= 10 || ammoniaSalt <= 10 || (neutralizerSalt && neutralizerSalt <= 10) || steamAmineSalt <= 10 || trampAmineSalt <= 10) {
    risk = 7;
  }
  return risk;
};

export const riskExchangerHotDrum = (ammoniaSalt: any, neutralizerSalt: any, steamAmineSalt: any, trampAmineSalt: any, waterDewPoint?: any) => {
  let risk = 1;
  if (waterDewPoint > 10 || ammoniaSalt > 10 || (neutralizerSalt && neutralizerSalt > 10) || steamAmineSalt > 10 || trampAmineSalt > 10) {
    risk = 1;
  }
  if (
    (waterDewPoint > 8 && waterDewPoint <= 10) ||
    (ammoniaSalt > 8 && ammoniaSalt <= 10) ||
    (neutralizerSalt && neutralizerSalt > 8 && neutralizerSalt <= 10) ||
    (steamAmineSalt > 8 && steamAmineSalt <= 10) ||
    (trampAmineSalt > 8 && trampAmineSalt <= 10)
  ) {
    risk = 2;
  }
  if (
    (waterDewPoint > 6 && waterDewPoint <= 8) ||
    (ammoniaSalt > 6 && ammoniaSalt <= 8) ||
    (neutralizerSalt && neutralizerSalt > 6 && neutralizerSalt <= 8) ||
    (steamAmineSalt > 6 && steamAmineSalt <= 8) ||
    (trampAmineSalt > 6 && trampAmineSalt <= 8)
  ) {
    risk = 3;
  }
  if (
    (waterDewPoint > 4 && waterDewPoint <= 6) ||
    (ammoniaSalt > 4 && ammoniaSalt <= 6) ||
    (neutralizerSalt && neutralizerSalt > 4 && neutralizerSalt <= 6) ||
    (steamAmineSalt > 4 && steamAmineSalt <= 6) ||
    (trampAmineSalt > 4 && trampAmineSalt <= 6)
  ) {
    risk = 4;
  }
  if (
    (waterDewPoint > 2 && waterDewPoint <= 4) ||
    (ammoniaSalt > 2 && ammoniaSalt <= 4) ||
    (neutralizerSalt && neutralizerSalt > 2 && neutralizerSalt <= 4) ||
    (steamAmineSalt > 2 && steamAmineSalt <= 4) ||
    (trampAmineSalt > 2 && trampAmineSalt <= 4)
  ) {
    risk = 5;
  }
  if (
    (waterDewPoint > 0 && waterDewPoint <= 2) ||
    (ammoniaSalt > 0 && ammoniaSalt <= 2) ||
    (neutralizerSalt && neutralizerSalt > 0 && neutralizerSalt <= 2) ||
    (steamAmineSalt > 0 && steamAmineSalt <= 2) ||
    (trampAmineSalt > 0 && trampAmineSalt <= 2)
  ) {
    risk = 6;
  }
  if (waterDewPoint <= 0 || ammoniaSalt <= 0 || (neutralizerSalt && neutralizerSalt <= 0) || steamAmineSalt <= 0 || trampAmineSalt <= 0) {
    risk = 7;
  }
  return risk;
};

export const riskVelocityIndicator = (velocity: any) => {
  let risk = 1;
  if (velocity < 50) {
    risk = 1;
  }
  if (velocity >= 50 && velocity < 60) {
    risk = 2;
  }
  if (velocity >= 60 && velocity < 70) {
    risk = 3;
  }
  if (velocity >= 70 && velocity < 80) {
    risk = 4;
  }
  if (velocity >= 80 && velocity < 90) {
    risk = 5;
  }
  if (velocity >= 90 && velocity < 100) {
    risk = 6;
  }
  if (velocity >= 100) {
    risk = 7;
  }
  return risk;
};

export const riskVelocityIndicatorForCoker = (velocity: any, NH4HS: any) => {
  let risk = 1;
  if (velocity < 75 || NH4HS < 2.0) {
    risk = 1;
  }
  if ((velocity >= 75 && velocity < 85) || (NH4HS >= 2.0 && NH4HS < 2.4)) {
    risk = 2;
  }
  if (velocity >= 85 && velocity < 95 || (NH4HS >= 2.4 && NH4HS < 2.8)) {
    risk = 3;
  }
  if (velocity >= 95 && velocity < 105 || (NH4HS >= 2.8 && NH4HS < 3.2)) {
    risk = 4;
  }
  if (velocity >= 105 && velocity < 115 || (NH4HS >= 3.2 && NH4HS < 3.6)) {
    risk = 5;
  }
  if (velocity >= 115 && velocity < 125 || (NH4HS >= 3.6 && NH4HS < 4)) {
    risk = 6;
  }
  if (velocity >= 125 || (NH4HS >= 4.0)) {
    risk = 7;
  }
  return risk;
};

export const fillColor = (risk: any) => {
  let red = 0;
  let green = 0;
  let blue = 0;
  switch (true) {
    case risk < 0:
      risk = 0;
      green = Number(((255 - COLOR_MAX) / 50) * risk + COLOR_MAX);
      red = Number((255 / 50) * risk);
      break;
    case risk >= 100:
      risk = 100;
      green = Number((-255 / 50) * risk + 510);
      red = Number(((COLOR_MAX - 255) / 50) * risk + (510 - COLOR_MAX));
      break;
    case risk >= 0 && risk < 50:
      green = Number(((255 - COLOR_MAX) / 50) * risk + COLOR_MAX);
      red = Number((255 / 50) * risk);
      break;
    case risk >= 50 && risk < 100:
      green = Number((-255 / 50) * risk + 510);
      red = Number(((COLOR_MAX - 255) / 50) * risk + (510 - COLOR_MAX));
      break;
    default:
      green = 0;
      red = 0;
      break;
  }
  return { colorRed: red, colorGreen: green, colorBlue: blue };
};

export const getColorIndicators = (data: any, key: any, inputData: any, subunit: any, unit: any) => {
  let colorIndicators = {
    ovhd1: {},
    ovhd2: {},
    ovhd3: {},
    ovhd4: {},
    ovhd5: {},
    tower: {},
    towerTop: {},
    exchanger: {},
    refluxPipe: {},
    twoStage1: {},
    twoStage2: {},
    twoStage3: {},
    twoStage4: {},
    twoStage5: {},
    exchanger2: {},
    oneStage1: {},
    hotDrumLeft: {},
    hotDrumCenter: {},
    hotDrumRight: {},
  };

  if (subunit.configType === '1A' || subunit.configType === '1B' || subunit.configType === '1C') {
    colorIndicators.ovhd1 =
      colorIndicators.tower =
      colorIndicators.towerTop =
        fillColor(convertToFarenheit(unit, data[key].corrosionRisk.tower, false));
    colorIndicators.refluxPipe = inputData.liquidAssayStreams.isWetReflux ? fillColor(100) : fillColor(0);
    colorIndicators.ovhd2 = fillColor(convertToFarenheit(unit, data[key].corrosionRisk.beforeWash, false));
    colorIndicators.ovhd3 = colorIndicators.ovhd5 = fillColor(convertToFarenheit(unit, data[key].corrosionRisk.afterWash, false));
    colorIndicators.ovhd4 = fillColor(data[key].corrosionRisk.velocity);
    colorIndicators.exchanger = fillColor(convertToFarenheit(unit, data[key].corrosionRisk.exchanger, false));
  } else if (subunit.configType === '2A' || subunit.configType === '2B' || subunit.configType === '2C') {
    var wetReflux = inputData.liquidAssayStreams.isWetReflux ? 100 : 0;
    var riskValue = 0;
    colorIndicators.refluxPipe = fillColor(wetReflux);
    if (inputData.overheadWater.neutralizerName === 'TOPGUARD™ 1130') {
      // Calculate tower risk and pass as risk value
      if (
        convertToFarenheit(unit, data[key].corrosionRisk.ammoniaSalt, false) < 50 &&
        convertToFarenheit(unit, data[key].corrosionRisk.trampAmineSalt, false) < 50 &&
        convertToFarenheit(unit, data[key].corrosionRisk.steamAmineSalt, false) < 50 &&
        convertToFarenheit(unit, data[key].corrosionRisk.wetTower, false) < 50 &&
        convertToFarenheit(unit, data[key].corrosionRisk.neutralizerSalt, false) > 50 &&
        !inputData.isWetReflux
      ) {
        riskValue = 50;
      } else {
        riskValue = Math.max(
          convertToFarenheit(unit, data[key].corrosionRisk.ammoniaSalt, false),
          convertToFarenheit(unit, data[key].corrosionRisk.trampAmineSalt, false),
          convertToFarenheit(unit, data[key].corrosionRisk.steamAmineSalt, false),
          convertToFarenheit(unit, data[key].corrosionRisk.wetTower, false),
          wetReflux
        );
      }
      colorIndicators.ovhd1 = colorIndicators.tower = colorIndicators.towerTop = fillColor(riskValue);

      if (
        convertToFarenheit(unit, data[key].hotDrum.waterDewPointTempDiff, true) < -TWO_STAGE_FROM_TECH.TEXT_TUBE_SKIN ||
        (subunit.waterWash && subunit.stageOvhdWashStream === '1')
      ) {
        // 'Low Risk for Second Stage
        riskValue = 0;
        colorIndicators.twoStage1 =
          colorIndicators.twoStage2 =
          colorIndicators.twoStage3 =
          colorIndicators.twoStage4 =
          colorIndicators.twoStage5 =
          colorIndicators.exchanger2 =
          colorIndicators.oneStage1 =
          colorIndicators.hotDrumCenter =
          colorIndicators.hotDrumLeft =
          colorIndicators.hotDrumRight =
            fillColor(riskValue);
        riskValue = convertToFarenheit(unit, data[key].corrosionRisk.beforeWash, false);
        if (riskValue > 50) {
          riskValue = 50;
        }
        colorIndicators.ovhd2 = fillColor(riskValue);
        riskValue = convertToFarenheit(unit, data[key].corrosionRisk.afterWash, false);
        if (riskValue > 50) {
          riskValue = 50;
        }
        colorIndicators.ovhd3 = colorIndicators.ovhd5 = fillColor(riskValue);
        colorIndicators.ovhd4 = fillColor(data[key].corrosionRisk.velocity);

        riskValue = convertToFarenheit(unit, data[key].corrosionRisk.exchanger, false);
        if (riskValue > 50) {
          riskValue = 50;
        }
        colorIndicators.exchanger = fillColor(riskValue);
      } else {
        if (subunit.stageOvhdNeutralizerStream === '2') {
          colorIndicators.ovhd2 = colorIndicators.ovhd3 = colorIndicators.ovhd4 = colorIndicators.ovhd5 = fillColor(riskValue);
          riskValue = convertToFarenheit(unit, data[key].corrosionRisk.hotDrum, false);
          if (riskValue > 50) {
            riskValue = 50;
          }
          colorIndicators.exchanger =
            colorIndicators.oneStage1 =
            colorIndicators.hotDrumCenter =
            colorIndicators.hotDrumLeft =
            colorIndicators.hotDrumRight =
            colorIndicators.twoStage1 =
              fillColor(riskValue);
          if (subunit.injectedNeutralizerStream.toLowerCase() === AFTER_WASH) {
            colorIndicators.twoStage2 = fillColor(riskValue);
          } else {
            riskValue = convertToFarenheit(unit, data[key].corrosionRisk.beforeWash, false);
            colorIndicators.twoStage2 = fillColor(riskValue);
          }
        } else {
          riskValue = convertToFarenheit(unit, data[key].corrosionRisk.beforeWash, false);
          if (riskValue > 50) {
            riskValue = 50;
          }
          colorIndicators.ovhd2 = colorIndicators.ovhd3 = colorIndicators.ovhd4 = colorIndicators.ovhd5 = fillColor(riskValue);
          riskValue = convertToFarenheit(unit, data[key].corrosionRisk.hotDrum, false);
          if (riskValue > 50) {
            riskValue = 50;
          }
          colorIndicators.exchanger =
            colorIndicators.oneStage1 =
            colorIndicators.hotDrumCenter =
            colorIndicators.hotDrumLeft =
            colorIndicators.hotDrumRight =
            colorIndicators.twoStage1 =
            colorIndicators.twoStage2 =
              fillColor(riskValue);
        }
        colorIndicators.twoStage3 = fillColor(convertToFarenheit(unit, data[key].corrosionRisk.afterWash, false));

        colorIndicators.twoStage4 = fillColor(data[key].corrosionRisk.velocity);
        colorIndicators.exchanger2 = fillColor(convertToFarenheit(unit, data[key].corrosionRisk.exchanger, false));
        colorIndicators.twoStage5 = fillColor(0);
      }
    } else {
      colorIndicators.ovhd1 =
        colorIndicators.tower =
        colorIndicators.towerTop =
          fillColor(convertToFarenheit(unit, data[key].corrosionRisk.tower, false));
      if (
        convertToFarenheit(unit, data[key].hotDrum.waterDewPointTempDiff, true) < -TWO_STAGE_FROM_TECH.TEXT_TUBE_SKIN ||
        (subunit.waterWash && subunit.stageOvhdWashStream === '1')
      ) {
        // 'Low Risk for Second Stage
        riskValue = 0;
        colorIndicators.twoStage1 =
          colorIndicators.twoStage2 =
          colorIndicators.twoStage3 =
          colorIndicators.twoStage4 =
          colorIndicators.twoStage5 =
          colorIndicators.exchanger2 =
          colorIndicators.oneStage1 =
          colorIndicators.hotDrumCenter =
          colorIndicators.hotDrumLeft =
          colorIndicators.hotDrumRight =
            fillColor(riskValue);
        // Risk values assigned to First Stage
        colorIndicators.ovhd2 = fillColor(convertToFarenheit(unit, data[key].corrosionRisk.beforeWash, false));
        colorIndicators.ovhd3 = colorIndicators.ovhd5 = fillColor(convertToFarenheit(unit, data[key].corrosionRisk.afterWash, false));
        colorIndicators.ovhd4 = fillColor(data[key].corrosionRisk.velocity);
        colorIndicators.exchanger = fillColor(convertToFarenheit(unit, data[key].corrosionRisk.exchanger, false));
      } else {
        if (subunit.stageOvhdNeutralizerStream === '2') {
          colorIndicators.ovhd2 = colorIndicators.ovhd3 = colorIndicators.ovhd4 = colorIndicators.ovhd5 = fillColor(riskValue);
          riskValue = convertToFarenheit(unit, data[key].corrosionRisk.hotDrum, false);
          colorIndicators.exchanger =
            colorIndicators.oneStage1 =
            colorIndicators.hotDrumCenter =
            colorIndicators.hotDrumLeft =
            colorIndicators.hotDrumRight =
            colorIndicators.twoStage1 =
              fillColor(riskValue);

          if (subunit.injectedNeutralizerStream.toLowerCase() === AFTER_WASH) {
            colorIndicators.twoStage2 = fillColor(riskValue);
          } else {
            riskValue = convertToFarenheit(unit, data[key].corrosionRisk.beforeWash, false);
            colorIndicators.twoStage2 = fillColor(riskValue);
          }
        } else {
          colorIndicators.ovhd2 =
            colorIndicators.ovhd3 =
            colorIndicators.ovhd4 =
            colorIndicators.ovhd5 =
              fillColor(convertToFarenheit(unit, data[key].corrosionRisk.beforeWash, false));
          colorIndicators.exchanger =
            colorIndicators.oneStage1 =
            colorIndicators.hotDrumCenter =
            colorIndicators.hotDrumLeft =
            colorIndicators.hotDrumRight =
            colorIndicators.twoStage1 =
            colorIndicators.twoStage2 =
              fillColor(convertToFarenheit(unit, data[key].corrosionRisk.hotDrum, false));
        }

        colorIndicators.twoStage3 = fillColor(convertToFarenheit(unit, data[key].corrosionRisk.afterWash, false));
        colorIndicators.twoStage4 = fillColor(data[key].corrosionRisk.velocity);
        colorIndicators.exchanger2 = fillColor(convertToFarenheit(unit, data[key].corrosionRisk.exchanger, false));
        colorIndicators.twoStage5 = fillColor(0);
      }
    }
  }
  return colorIndicators;
};

export const getIconIndicators = (data: any, key: any, configType: any, unit: any, velocityUnit: any, unitType:any) => {
  let iconIndicators = {
    towerTopTempDiff: 1,
    hotDrumTempDiff: 1,
    exchangerTempDiff: 1,
    overheadLine: 1,
  };

  iconIndicators.towerTopTempDiff = riskIndicatorTemp(
    convertToFarenheit(unit, data[key].towerTop.waterDewPointTempDiff, true),
    convertToFarenheit(unit, data[key].towerTop.ammoniaSaltTempDiff, true),
    convertToFarenheit(unit, data[key].towerTop.neutralizerSaltTempDiff, true),
    convertToFarenheit(unit, data[key].towerTop.steamAmineSaltTempDiff, true),
    convertToFarenheit(unit, data[key].towerTop.trampAmineSaltTempDiff, true)
  );
  if (configType === '2A' || configType === '2B' || configType === '2C') {
    iconIndicators.hotDrumTempDiff = riskExchangerHotDrum(
      convertToFarenheit(unit, data[key].hotDrum.ammoniaSaltTempDiff, true),
      convertToFarenheit(unit, data[key].hotDrum.neutralizerSaltTempDiff, true),
      convertToFarenheit(unit, data[key].hotDrum.steamAmineSaltTempDiff, true),
      convertToFarenheit(unit, data[key].hotDrum.trampAmineSaltTempDiff, true),
      convertToFarenheit(unit, data[key].hotDrum.waterDewPointTempDiff, true)
    );
  }

  iconIndicators.exchangerTempDiff = riskExchangerHotDrum(
    convertToFarenheit(unit, data[key].dewPoint.ammoniaSaltTempDiffDP, true),
    convertToFarenheit(unit, data[key].dewPoint.neutralizerSaltTempDiffDP, true),
    convertToFarenheit(unit, data[key].dewPoint.steamAmineSaltTempDiffDP, true),
    convertToFarenheit(unit, data[key].dewPoint.trampAmineSaltTempDiffDP, true),
    convertToFarenheit(unit, data[key].towerTop.waterDewPointTempDiff, true)
  );
  iconIndicators.overheadLine = (unitType === 'coker' || unitType === 'fcc' || unitType === 'hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') ? 
  riskVelocityIndicatorForCoker(
    velocityUnit === 'ft/s' ? data[key].overHeadLine.velocity : convertVelocityToFeetPerSecond(data[key].overHeadLine.velocity),
    Math.round(data[key].overHeadLine.nh4HSAfterWashPerc * 100) / 100
  ) :
  riskVelocityIndicator(
    velocityUnit === 'ft/s' ? data[key].overHeadLine.velocity : convertVelocityToFeetPerSecond(data[key].overHeadLine.velocity)
  );
  return iconIndicators;
};
