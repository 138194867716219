/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import { BhFormMessage, BhTooltip } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import './SimulationData.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const TowerFor1cAnd2c = (props: any) => {
  const {
    today,
    errors,
    unitType,
    isWithDesalter,
    simulationData,
    handleDateChange,
    values,
    getTotalFurenceSteam,
    getTotalFurenceSteamOption,
    get5thSide,
    getCrudeCharge,
    getCrudeGravity,
    desCrudedesCharge,
    getSteam3s,
    getSteamOne,
    getSteamTower,
    getSteam6s,
    get4thSideSteam,
  } = props;

  return (
    <div className="input-wrapper">
      <div className="input-frame order-0">
        <div className="date-div-coker">
          <div>
            <label className="bh-text-input__label typography--label-small bh-text-input__required">Date</label>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={simulationData && simulationData.inputDate ? dayjs(new Date(simulationData.inputDate)) : dayjs(today)}
              onChange={(eventCoker: any) => handleDateChange(eventCoker, true)}
              className="date-pick"
            />
          </LocalizationProvider>
          <BhFormMessage
            data-testid="bh-error-coker"
            isError={errors && errors.date ? true : false}
            message={values.date ? '' : errors.date}
            id="date-input-error"
          ></BhFormMessage>
        </div>

        {getCrudeCharge()}
        {getCrudeGravity()}
      </div>
      <div className="input-frame order-1">
        <BhTooltip title="Crude BS&W" message="BS&W of desalted crude in volume % of desalted crude.">
          {desCrudedesCharge('Crude BS&W')}
        </BhTooltip>
        {getSteamTower()}
        {getSteamOne()}
      </div>
      <div className="input-frame order-2">
        {((unitType !== 'coker' && unitType !=='fcc' && unitType !=='hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') 
        || ((unitType === 'coker' || unitType ==='fcc' || unitType === 'hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && isWithDesalter)) && (
          <>
            <div>
              <BhTooltip
                title={(unitType === 'coker' || unitType ==='fcc' || unitType === 'hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && isWithDesalter ? 'Total Furnace Steam' : '2nd Side Stripper Steam'}
                message="Additional source of steam to the atmospheric tower (side stripper). These are optional as needed."
              >
                {getTotalFurenceSteam()}
              </BhTooltip>
              <BhFormMessage isError message={errors.steam2ss}></BhFormMessage>
            </div>
            <div>{getTotalFurenceSteamOption()}</div>
          </>
        )}

        {getSteam3s()}
        {((unitType !== 'coker' && unitType !=='fcc' && unitType !=='hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') 
        || ((unitType === 'coker' || unitType ==='fcc' || unitType === 'hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && !isWithDesalter)) && (
          <>{get4thSideSteam()}</>
        )}
      </div>
      <div className="input-frame order-3">
        {/* {(unitType === 'coker' || unitType ==='fcc' || unitType === 'hydrocracker') && !isWithDesalter && get5thSide()} */}
        {unitType !== 'coker' && unitType !=='fcc' && unitType !=='hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid' && get5thSide()}
        {((unitType !== 'coker' && unitType !=='fcc' && unitType !=='hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') 
        || ((unitType === 'coker' || unitType ==='fcc' || unitType === 'hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && !isWithDesalter)) && (
          <>{getSteam6s()}</>
        )}
      </div>
    </div>
  );
};

export default TowerFor1cAnd2c;
