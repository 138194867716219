/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import { BhAlertItem, BhAppShell, BhButton, BhContent, BhDivider, BhSpinner, BhIcon, BhDialog } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { useOktaAuth, LoginCallback } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import OktaAuth from '@okta/okta-auth-js';
import config from '../config';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import SimulationReport from '../components/Reports/SimulationReport';
import AdminCenter from '../components/Admin/AdminCenter';
import { RequiredAuth } from './../components/SecureRoutes/secureroute';
import SimulationData from '../components/Simulation/SimulationData';
import CorrosionRiskReport from '../components/Reports/CorrosionRiskReport';
import AllSimulations from '../components/Simulation/AllSimulation';
import LandingPage from '../components/LandingPage/LandingPage';
import Dashboard from '../components/Dashboard/Dashboard';
import FavoriteList from '../components/Dashboard/FavoriteList';
import NewSimulation from '../components/Simulation/NewSimulation';
import SvgIcon from '@mui/material/SvgIcon';
import { decryptData } from '../utils/utils';
import { Popover, ListItemIcon, ListItemText, MenuItem, Typography, Backdrop, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { createUserRoleAction } from '../store/userrole-slice';
import CloseIcon from '@mui/icons-material/Close';
import './layout.scss';

import apiRequest from '../services/api-helper';
import { APIEnum } from '../constants/api-enum';
import { createRefineryResponseAction } from '../store/refineryresponse-slice';
import { createNavigationAction } from '../store/navigation-slice';
import { createRefineryTreeAction } from '../store/refinerytree-slice';
import { createConfigAction } from '../store/config-slice';
import { getDefaultUom } from './Dashboard/helper';
import ChangeUnitModal from './Shared/Modal/ChangeUnitModal';
declare const window: any;

 export default function Layout () {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationUrlPath = useSelector((state: any) => state.createNavigation);
  const favoriteStoreData = useSelector((state: any) => state.createSummaryResponse);
  const oktaAuth = new OktaAuth(config.oidc);
  const { authState } = useOktaAuth();
  const [email, setEmail] = useState('');

  const [notificationMenu, setNotificationMenu] = useState<boolean>(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [helpMenu, setHelMenu] = useState<boolean>(false);

  const userRoleFromStore = useSelector((state: any) => state.createUserRole);
  const roleFromStore = useSelector((state: any) => state.createUserRole);
  const [isSandbox, setSandbox] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [isSandBoxAlertClose, setSandBoxAlertClose] = useState<boolean>(false);

  //sandbox mode changes
  const [loading, setLoading] = useState(false);
  const [isError, setErrorFlag] = useState(false);
  const [isOpen, setOpenFlag] = useState(false);
  const [isExitDialogueOpen, setExitDialogueOpen] = useState(false);
  const [isInitialLoad, setInitialLoadFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorHeader, setErrorHeader] = useState('');
  //edit config approval flow
  const [notificationCount, setNotificationCount] = useState<any>();
  const [notificationList, setNotificationList] = useState<any>([]);
  const [notificationIds, setNotificationIds] = useState<any>([]);
  const [isAdminOrSme, setAdminOrSme]=useState<boolean>();



  useEffect(() => {
    if (authState?.isAuthenticated === true) {
      const idTokenVal = localStorage.getItem('okta-token-storage');
      if (idTokenVal) {
        setEmail(JSON.parse(idTokenVal).idToken.claims.email);
        setFirstName(JSON.parse(idTokenVal).idToken.claims.firstName);
        setLastName(JSON.parse(idTokenVal).idToken.claims.lastname);
      }
    }
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  useEffect(() => {
    if(authState?.isAuthenticated){
      localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' ? setSandbox(true) : setSandbox(false);
    if(!localStorage.getItem('found')) {
      return
    }
      if (localStorage.getItem('found') && localStorage.getItem('found') === 'true') {
        if (localStorage.getItem('salt') && Object.keys(localStorage.getItem('salt')!).length) {
          if (decryptData(localStorage.getItem('salt')) && decryptData(localStorage.getItem('salt')).userActiveStatus) {
            if (decryptData(localStorage.getItem('salt')).userRole && decryptData(localStorage.getItem('salt')).userRole !== '')
            {
            if(decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'sme' ||
              decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'admin'){
              setAdminOrSme(true);
            }
            else setAdminOrSme(false);
            //notification api call
            getNotification(false);
            //if normal mode, then need to call user/refineries api
            if(!localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false'){
              getConfigData();
            }
               else{
                //if sandbox mode has configuration saved
                if(getConfigDataForSandBox()){
                  //set redux store and localstorage with that configuration
                }
                //if sandbox mode doesnt have configuration saved
                else{
                  getConfigData();
                }
               }
              }
            else {
              setErrorMessage('You do not have any role assigned to the application! Please contact Administrator.');
              setErrorFlag(true);
              setErrorHeader('Role required');
            }
          } else if (
            localStorage.getItem('salt') &&
            Object.keys(localStorage.getItem('salt')!).length &&
            decryptData(localStorage.getItem('salt')) &&
            !decryptData(localStorage.getItem('salt')).userActiveStatus
          ) {
            setErrorMessage('You do not have access to the application! Please contact Administrator.');
            setErrorFlag(true);
            setErrorHeader('Access required');
          } else {
            setErrorFlag(false);
          }
        }
      } else {
        setErrorMessage('You are not registered with the application! Please contact Administrator.');
        setErrorFlag(true);
        setErrorHeader('Access required');
      }
    }
  
  }, [localStorage.getItem('found'), roleFromStore.userFlag, authState?.isAuthenticated, localStorage.getItem('salt')]);



//sandbox
  useEffect(() => {
 
    if(authState?.isAuthenticated){
    
    setTimeout(() => {
      let first = document.querySelector('div>bh-app-shell')?.shadowRoot?.querySelector('bh-app-shell-menu');
      if (first) {
        let second = first?.getElementsByClassName('bh-app-shell-menu__settings-menu');
        if (second) {
          let secondElement:any=second[0];
          if(secondElement) secondElement.style.zIndex = '99999';
          let parentDiv: any = second[0]?.children;
          if (parentDiv) {
            let childDiv = parentDiv[0];
            if (childDiv) {
              childDiv.style.overflowY = 'hidden';
            }
          }
        }
      }
      let appShellWrapper:any=document.querySelector('div>bh-app-shell')?.shadowRoot?.querySelector('.bh-app-shell--wrapper');
      if (appShellWrapper) {
        appShellWrapper.style.cssText = 'overflow-y :unset';
        let notificationCountSpan = appShellWrapper.getElementsByClassName('bh-badge__label')[0];
        if(notificationCountSpan){
          notificationCountSpan.style.backgroundColor= '#CF2333';
          notificationCountSpan.style.border = '1px solid #CF2333';
        }
        //add custom icon for sandbox
        const div=appShellWrapper.getElementsByClassName('bh-header__icon-links')[0]?.children[0];
        //const sandoxSpan= appShellWrapper.getElementsByClassName('bh-header__icon-links')[0]?.children[0];
        if(div && appShellWrapper.getElementsByClassName('bh-header__icon-links')[0]?.children[0].children[0].id !== 'sndbox' && localStorage.getItem('isSandbox') === 'true') div.insertAdjacentHTML("beforebegin",`<span><div id='sndbox' class='bh-header__icon bh-header__icon--help_outline motion--fast' tabindex='0' style="background-color: rgb(20, 125, 100);"><img id='sandboxImg' class='typography--icon-medium sandbox-img' src='/assets/images/sandbox.svg' /></div></span>`);
        if(div && appShellWrapper.getElementsByClassName('bh-header__icon-links')[0]?.children[0].children[0].id !== 'sndbox' && localStorage.getItem('isSandbox') !== 'true') div.insertAdjacentHTML("beforebegin",`<span><div id='sndbox' class='bh-header__icon bh-header__icon--help_outline motion--fast' tabindex='0' ><img id='sandboxImg' class='typography--icon-medium sandbox-img' src='/assets/images/sandbox.svg' /></div></span>`);
        const sandoxSpan= appShellWrapper.getElementsByClassName('bh-header__icon-links')[0]?.children[0];
        if(sandoxSpan && sandoxSpan.children[0]) sandoxSpan.children[0].addEventListener('click', handleToggleChange);

      }
    }, 1000);

    }
                return () =>{
                  let appShellWrapper:any=document.querySelector('div>bh-app-shell')?.shadowRoot?.querySelector('.bh-app-shell--wrapper');
                  if (appShellWrapper) {
                    const sandoxSpan= appShellWrapper.getElementsByClassName('bh-header__icon-links')[0]?.children[0];
                    if(sandoxSpan && sandoxSpan.children[0]) sandoxSpan.children[0].removeEventListener('click', handleToggleChange);
                  
                  }
            }
  }, [roleFromStore.userFlag, authState?.isAuthenticated]);
 
  const getNotification= (fromAdminCenter :boolean) =>{
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/changerequest/notification`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
    .then((result) => {
       if(result && result.responseStatus && result.responseStatus.status && result.responseStatus.status === 'SUCCESS'){
         if(Object.keys(result.notificationList).length){
           setNotificationCount(result.notificationList.length);
           setNotificationList(result.notificationList);
           const reqIds = result.notificationList.map((requests:any) => requests.notificationId);
           setNotificationIds(reqIds);
           if(fromAdminCenter){
            setNotificationData();
           }
         }
       }
    });
  }
 
  // useEffect(() =>{
  // const timer = setTimeout(() => {
  //   //setAlertOpen(false);
  //   }, 10000);
  //   return () => clearTimeout(timer);
  // }, [isAlertOpen]);

  //sandbox icon click
  // const handleSandboxIconClick = () =>{
    
  // }

  //function to sort tree data
  const sortDataAndChildren =(items:any) =>{
    items.sort((a: any, b: any) => a.name.localeCompare(b.name));
    items.forEach((item:any) => {
      if(item.children){
        sortDataAndChildren(item.children);
      }
    });
    return items;
  };

  //function to build tree data
  const setRefineryTreeData = (data: any) => {
    let operator: any = [];
    if (data && data.length > 0) {
      data.map((operatorItem: any) => {
        let refinery: any = [];
        if (operatorItem.refinery) {
          let unit: any = [];
          let isAtmTowerOverheadPresent: boolean = false;
          let isAnyAtmTowerOverheadPresent: boolean = false;
          if (operatorItem.refinery.unit && operatorItem.refinery.unit.length > 0) {
            operatorItem.refinery.unit.map((unitItem: any) => {
              let subUnit: any = [];
              if (unitItem.subUnit && unitItem.subUnit.length > 0) {
                unitItem.subUnit.map((subUnitItem: any) => {
                  if (subUnitItem.typeSubunit.trim().toLowerCase() === 'atm tower overhead' || subUnitItem.typeSubunit.trim().toLowerCase() === 'prefractionator overhead' 
                  || subUnitItem.typeSubunit.trim().toLowerCase() === 'fractionator overhead'|| subUnitItem.typeSubunit.trim().toLowerCase() === 'main fractionator overhead'
                  || subUnitItem.typeSubunit.trim().toLowerCase() === 'stripper/stabilizer overhead') {
                    isAtmTowerOverheadPresent = true;
                    isAnyAtmTowerOverheadPresent = true;
                    subUnit.push({
                      name: subUnitItem.name,
                      key: subUnitItem.subUnitId + '_subunit',
                      expanded: false,
                      customerID : operatorItem?.refinery?.customerId,
                      refineryID : operatorItem?.refinery?.refineryId,
                      unitID : unitItem?.unitId
                    });
                  }
                });
              }
              isAtmTowerOverheadPresent &&
                unit.push({
                  name: unitItem.name,
                  key: unitItem.unitId + '_unit',
                  expanded: false,
                  children: subUnit,
                });
              isAtmTowerOverheadPresent = false;
            });
          }
          isAnyAtmTowerOverheadPresent &&
            refinery.push({
              name: operatorItem.refinery.refineryName,
              key: operatorItem.refinery.refineryId + '_refinery',
              expanded: false,
              children: unit,
            });
          if (isAnyAtmTowerOverheadPresent) {
            if (operator.length && operator.findIndex((m: any) => m.key === operatorItem.refinery.customerId + '_customer') !== -1) {
              operator
                .filter((m: any) => m.key === operatorItem.refinery.customerId + '_customer')[0]
                .children.push({
                  name: refinery[0].name,
                  key: refinery[0].key,
                  expanded: refinery[0].expanded,
                  children: refinery[0].children,
                });
            } else {
              operator.push({
                name: operatorItem.refinery.customerName,
                key: operatorItem.refinery.customerId + '_customer',
                expanded: false,
                children: refinery,
              });
            }
          }
        }
      });
    }
    //set tree hierarchy to store
    let sortedTreeData:any = sortDataAndChildren(operator);
    dispatch(createRefineryTreeAction.createRefineryTree(sortedTreeData));
    localStorage.setItem('refineryTreeData',JSON.stringify(sortedTreeData));
  };
    const checkForHydrotreater = (unitType:any) =>{
      if(unitType.trim().toLowerCase() === 'hydrotreater-distillate'
      || unitType.trim().toLowerCase() === 'hydrotreater-gas oil' 
      || unitType.trim().toLowerCase() === 'hydrotreater-naphtha'
      || unitType.trim().toLowerCase() === 'hydrotreater-naphtha renewable' 
      || unitType.trim().toLowerCase() === 'hydrotreater-resid') return true;
      else return false;
    }
    const getConfigData = (redirectOrNot = false) => {
      setLoading(true);
      //api to get refinery data and default configuration
      let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/user/refineries`;
      apiRequest(encodeURI(apiUrl), {}, APIEnum.GET, true, localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' ? 'SandBox':'Actual').then((data) => {
        if (data.responseStatus.status === 'SUCCESS') {
          setErrorFlag(false);
          setRefineryTreeData(data.data);
          //save response to store
          dispatch(createRefineryResponseAction.createRefineryResponse(data.data));
          localStorage.setItem('defaultConfig', JSON.stringify(data.default));
          localStorage.setItem('refineryDataBlock', JSON.stringify(data.data));
          //already logged in user
          if (data.default) {
            //set store
            dispatch(createConfigAction.createConfig(data.default));
            localStorage.setItem('defaultConfig', JSON.stringify(data.default));
            localStorage.setItem('refineryApiResult', JSON.stringify(data.default));
            setOpenFlag(false);
            setInitialLoadFlag(false);
            //api call to get the uom class and category mapping
            let subUnitId;
            let configType;
            if (data.default && data.default.refinery && data.default.refinery.unit && data.default.refinery.unit[0]) {
              subUnitId = data.default.refinery.unit[0].subUnit[0].subUnitId;
              configType = data.default.refinery.unit[0].subUnit[0].configType;
              const refineryName = data.default.refinery.refineryName;
              const unitName = data.default.refinery.unit[0].unitName;
             const subUnitName = 
             data.default.refinery.unit[0].subUnit &&
             data.default.refinery.unit[0].subUnit[0] &&
             data.default.refinery.unit[0].subUnit[0].subUnitName;
             const subTitle: any = refineryName && unitName ? refineryName + ' | ' + unitName + ' | ' + subUnitName : '';
              localStorage.setItem('subtitle', subTitle);
              let configId =
              data.default.refinery.unit[0].subUnit &&
              data.default.refinery.unit[0].subUnit[0] &&
              data.default.refinery.unit[0].subUnit[0].configId;
              localStorage.setItem('configId', configId);
              localStorage.setItem('configType', (data.default.refinery.unit[0].unitType.trim().toLowerCase() === 'coker' 
              || data.default.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc' 
              || data.default.refinery.unit[0].unitType.trim().toLowerCase() === 'hydrocracker' 
              || checkForHydrotreater(data.default.refinery.unit[0].unitType.trim().toLowerCase())) ? '1A': configType);
              if(data.default.refinery.unit[0].unitType.trim().toLowerCase() === 'coker' 
              || data.default.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc' 
              || checkForHydrotreater(data.default.refinery.unit[0].unitType.trim().toLowerCase())
              || data.default.refinery.unit[0].unitType.trim().toLowerCase() === 'hydrocracker' ){
                data.default.refinery.unit[0].unitType.trim().toLowerCase() === 'coker' ? localStorage.setItem('cokerType',data.default.refinery.unit[0].subUnit[0].cokerType): 
                (data.default.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc' ? localStorage.setItem('fccType', data.default.refinery.unit[0].subUnit[0].fccType):
                (data.default.refinery.unit[0].unitType.trim().toLowerCase() === 'hydrocracker' ? localStorage.setItem('hcuType', data.default.refinery.unit[0].subUnit[0].hcuType)
              :localStorage.setItem('hdtType', data.default.refinery.unit[0].subUnit[0].hdtType)));
              } 
              else 
              {
                 localStorage.setItem('cokerType','');
                 localStorage.setItem('fccType','');
                 localStorage.setItem('hdtType','');
                 localStorage.setItem('hcuType','');
              }
            }
            getDefaultUom(subUnitId, configType);
          }
          //case when user login for the first time
          else {
            //set isOpen flag to true in order to show the change unit pop up
            setTimeout(() => {
              setOpenFlag(true);
              setInitialLoadFlag(true);
            }, 500);
          }
        } else {
          if (data.responseStatus.status === 'ERROR') {
            if (
              data.responseStatus.errorResponse[0] &&
              data.responseStatus.errorResponse[0].errorCode &&
              data.responseStatus.errorResponse[0].errorCode === '204'
            ) {
              setErrorMessage(
                'You do not have permissions to access any facilities. Please contact the OFSE Digital Product Manager or OIC Downstream Technology for access.'
              );
            } else setErrorMessage(data.responseStatus.errorResponse[0].message);
            setErrorHeader('Permission required');
            setErrorFlag(true);
          }
        }
        setLoading(false);
      }).catch(()=> {
        setLoading(false);
      });
    }
    const getConfigDataForSandBox = () =>{
      setLoading(true);
      let subUnitId;
      let refineryObj: any = {};
      refineryObj = localStorage.getItem('defaultConfig');
      subUnitId= JSON.parse(refineryObj)?.refinery.unit[0].subUnit[0].subUnitId;
      //api to get refinery data and default configuration
      let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/config/${subUnitId}`;
      apiRequest(encodeURI(apiUrl), {}, APIEnum.GET, true, 'SandBox').then((data) => {
        if (data.responseStatus.status === 'SUCCESS' && data.defaultConfig && Object.keys( data.defaultConfig).length) {
        //need to update configurations in redux and in local storage
        let localStoreConfig = JSON.parse(refineryObj);
        if(localStoreConfig && localStoreConfig.refinery &&
          localStoreConfig.refinery.unit && localStoreConfig.refinery.unit[0] &&
          localStoreConfig.refinery.unit[0].subUnit && localStoreConfig.refinery.unit[0].subUnit[0]){
            localStoreConfig.refinery.unit[0].preflashPrefractionator=data.defaultConfig.preflashPrefractionator;
            localStoreConfig.refinery.unit[0].subUnit[0].numDesalterStages = data.defaultConfig.numDesalterStages;
            localStoreConfig.refinery.unit[0].subUnit[0].waterWash = data.defaultConfig.waterWash;
            localStoreConfig.refinery.unit[0].subUnit[0].numOvhdStages = data.defaultConfig.numOvhdStages;
            localStoreConfig.refinery.unit[0].subUnit[0].idOvhdStream = data.defaultConfig.idOvhdStream;
            localStoreConfig.refinery.unit[0].subUnit[0].idOvhdStreamUom = data.defaultConfig.idOvhdStreamUom;
            localStoreConfig.refinery.unit[0].subUnit[0].numBranchesOvhdStream = data.defaultConfig.numBranchesOvhdStream;
            localStoreConfig.refinery.unit[0].subUnit[0].stageOvhdWashStream = data.defaultConfig.stageOvhdWashStream;
            localStoreConfig.refinery.unit[0].subUnit[0].equipmentOvhdWashStream = data.defaultConfig.equipmentOvhdWashStream;
            localStoreConfig.refinery.unit[0].subUnit[0].stageOvhdNeutralizerStream = data.defaultConfig.stageOvhdNeutralizerStream;
            localStoreConfig.refinery.unit[0].subUnit[0].injectedNeutralizerStream = data.defaultConfig.injectedNeutralizerStream;
            localStoreConfig.refinery.unit[0].subUnit[0].washFrequency = data.defaultConfig.washFrequency;
            localStoreConfig.refinery.unit[0].subUnit[0].configId = data.defaultConfig.configId;
            localStoreConfig.refinery.unit[0].subUnit[0].configType = data.defaultConfig.configType;
            localStoreConfig.refinery.unit[0].subUnit[0].cokerType = data.defaultConfig.cokerType;
            localStoreConfig.refinery.unit[0].subUnit[0].fccType = data.defaultConfig.fccType;
            localStoreConfig.refinery.unit[0].subUnit[0].hdtType = data.defaultConfig.hdtType;
            localStoreConfig.refinery.unit[0].subUnit[0].hcuType = data.defaultConfig.hcuType;
            localStoreConfig.refinery.unit[0].subUnit[0].isOvhdWaterFlowMetersEnabled = data.defaultConfig.isOvhdWaterFlowMetersEnabled;
            localStoreConfig.refinery.unit[0].smeConfig = data.defaultConfig.smeConfig;
            localStoreConfig.refinery.unit[0].sprayModelConfig = data.defaultConfig.sprayModelConfig;
            localStorage.setItem('configId', data.defaultConfig.configId);
            localStorage.setItem('configType', (JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase() === 'coker' 
             || JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc'
             || JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase() === 'hydrocracker'
             || checkForHydrotreater(JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase())) ? '1A': data.defaultConfig.configType);
            if(JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase() === 'coker' 
             || JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc'
             || JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase() === 'hydrocracker'
             || checkForHydrotreater(JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase())){
              JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase() === 'coker' ? localStorage.setItem('cokerType',JSON.parse(refineryObj)?.refinery.unit[0].subUnit[0].cokerType): 
              (JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc' ? localStorage.setItem('fccType', JSON.parse(refineryObj)?.refinery.unit[0].subUnit[0].fccType)
              :(JSON.parse(refineryObj)?.refinery.unit[0].unitType.trim().toLowerCase() === 'hydrocracker' ? localStorage.setItem('hcuType', JSON.parse(refineryObj)?.refinery.unit[0].subUnit[0].hcuType):
              localStorage.setItem('hdtType', JSON.parse(refineryObj)?.refinery.unit[0].subUnit[0].hdtType)));
            } 
            else 
            {
               localStorage.setItem('cokerType','');
               localStorage.setItem('fccType','');
               localStorage.setItem('hdtType','');
               localStorage.setItem('hcuType','');
            }
        }
        dispatch(createConfigAction.createConfig(localStoreConfig));
        //set data block in store from localstorage
        dispatch(createRefineryResponseAction.createRefineryResponse(JSON.parse(localStorage.getItem('refineryDataBlock')!)));
        // set tree data in store from locastorage
        dispatch(createRefineryTreeAction.createRefineryTree(JSON.parse(localStorage.getItem('refineryTreeData')!)));
        localStorage.setItem('defaultConfig',JSON.stringify(localStoreConfig));
        setErrorFlag(false);
        setOpenFlag(false);
        setInitialLoadFlag(false);
        } 
        //set values fetched from user/refineris api
        else {
          localStorage.setItem('defaultConfig', localStorage.getItem('refineryApiResult')!);
        }
        setLoading(false);
      }).catch(()=> {
        setLoading(false);
      });
      return true;
    }

  const handleToggleChange = () =>{
    let div:any;
    localStorage.removeItem('simulationData');
    localStorage.removeItem('sandboxsimulation');
    let appShellWrapper:any=document.querySelector('div>bh-app-shell')?.shadowRoot?.querySelector('.bh-app-shell--wrapper');
      if (appShellWrapper) {
      div=appShellWrapper.getElementsByClassName('bh-header__icon-links')[0]?.children[0];    
    }
    //when user wants to exit sandbox mode, show confirmation dialogue
    if(localStorage.getItem('isSandbox') === 'true') {   
      if(div) div.children[0].style.backgroundColor= '#05322b';      
      setExitDialogueOpen(true);
      setSandBoxAlertClose(false);  
    }
    //if user is in sandbox mode need to check for sandbox configurations
    else {
      setSandbox(true);
      setAlertOpen(true);
      if(div) div.children[0].style.backgroundColor= '#147d64';
      getConfigDataForSandBox();
      setExitDialogueOpen(false);
      localStorage.setItem('isSandbox', localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' ? 'false': 'true');
      //localStorage.setItem('isSandbox','true');
     
    }
  }

  const handleNavigation = (event: any) => {
    if (!localStorage.getItem('found') || localStorage.getItem('found') === 'false') {
      return;
    }
    if (event.detail.type === 'iconLinks') {
      if (event.detail.icon.key === 'help-icon') {
        setHelMenu((prevCheck) => !prevCheck);
      }
      if (event.detail.icon.key === 'notification-icon') {
        setNotificationMenu((prevCheck) => !prevCheck);
      }
    }
    if (event.detail.type === 'navigation') {
   if (event.detail.keys.item === '/') {
    (localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true') && setAlertOpen(true);
     if (favoriteStoreData) {
       if (favoriteStoreData.summary && favoriteStoreData.summary.length > 0) {
         let checkNav = favoriteStoreData.summary.filter((value: any) => value.selectedSubUnit === false);
         if (checkNav.length === 0) {
          navigate('/dashboard');
          dispatch(createNavigationAction.createNavigation(event.detail.keys.item));
          return;
         }
       }
     }
   }
    //return
      dispatch(createNavigationAction.createNavigation(event.detail.keys.item));
      if (event.detail.keys.item === 'admin-center') {
        let url = event.detail.keys.item === '/' ? '/' : '/' + event.detail.keys.item;
        navigate(url, { state: { userRole: userRoleFromStore.user } });
      } else {
        let url = event.detail.keys.item === '/' ? '/' : '/' + event.detail.keys.item;
        navigate(url);
      }
      toggleSideBar();
    }
    if (event.detail.type === 'settings') {
      handleLogOut();
    }
  };

  const setNotificationData =()=> {
    const payLoad:any={'notificationIds' : notificationIds}
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/changerequest/notification`;
    apiRequest(encodeURI(apiUrl), payLoad, APIEnum.PUT)
    .then((result) => {
      if(result && result.responseStatus && result.responseStatus.status === 'SUCCESS'){
        setNotificationCount('');
        setNotificationList([]);
        setNotificationIds([]);
      }
    });
  }

  const handleLogOut = () => {
    dispatch(createUserRoleAction.createFlag(null));
    window.localStorage.clear();
    window.sessionStorage.clear();
    oktaAuth.revokeAccessToken().then(() => {
      oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin,
      });
    });
    // Will redirect to Okta to end the session then redirect back to the configured `oktaSignInPage`
  };

  const getMenuBasedOnUser = () => {
    let menuItems: any = [];
    if (
      localStorage.getItem('salt') &&
      Object.keys(localStorage.getItem('salt')!).length &&
      decryptData(localStorage.getItem('salt')) &&
      decryptData(localStorage.getItem('salt')).userRole &&
      (decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'admin' || 
      decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'sme') &&
      decryptData(localStorage.getItem('salt')).userActiveStatus
    ) {
      menuItems = [
        { label: 'Dashboard', icon: 'dashboard', key: '/' },
        { label: 'Home', icon: 'home', key: 'home' },
        { label: 'All Simulations', icon: 'list_alt', key: 'simulations' },
        { label: 'Admin Center', icon: 'admin_panel_settings', key: 'admin-center' },
      ];
    } else {
      menuItems = [
        { label: 'Dashboard', icon: 'dashboard', key: '/' },
        { label: 'Home', icon: 'home', key: 'home' },
        { label: 'All Simulations', icon: 'list_alt', key: 'simulations' },
        { label: 'Message Center', icon: 'mail', key: 'admin-center' },
      ];
    }
    return menuItems;
  };

  const getSelectedURL = ()=> {
    if(navigationUrlPath.navigatedUrl){
      return { "item": navigationUrlPath.navigatedUrl }
    } 
    else {
      return { "item": '/' }
    }
  }

  const handleHelpMenuClose = () => {
    setHelMenu(false);
  };

  const closeNotification = () => {
    setNotificationMenu(false);
    //api call to reset notifications for account manager
    if(!isAdminOrSme){
      setNotificationData();
    }
  };

  const handleNotificationClose = () => {
    setNotificationMenu(false);
    //api call to reset notifications for account manager
    if(!isAdminOrSme){
      setNotificationData();
    }
  };

  const NavigateToAdmin = (isPending :boolean) => {
    setNotificationData();
    setNotificationMenu(false);
    navigate('/admin-center', { state: { changeRequestTab: 'change-request', subTab: isPending ? 'Pending': 'ExpiringSoon' } });
    dispatch(createNavigationAction.createNavigation('admin-center'));
  };

  function SharepointIcon(props: any) {
    return (
      <SvgIcon {...props}>
        <path d="M22 13.25q0 1.04-.4 1.95q-.39.9-1.07 1.58t-1.59 1.08q-.91.39-1.94.39q-.64 0-1.27-.16q-.09.83-.46 1.54q-.38.72-.97 1.25q-.58.53-1.33.82q-.76.3-1.59.3q-.91 0-1.71-.35q-.79-.34-1.39-.93q-.59-.59-.93-1.39q-.35-.8-.35-1.7v-.32q.03-.15.05-.31H2.83q-.33 0-.59-.24Q2 16.5 2 16.17V7.83q0-.33.24-.59Q2.5 7 2.83 7h2.95q.12-1.06.61-2q.48-.89 1.24-1.56q.75-.68 1.71-1.06T11.38 2q1.16 0 2.18.44q1.03.45 1.79 1.21t1.21 1.79Q17 6.46 17 7.63v.31q0 .15-.04.31q1.04 0 1.95.39q.92.39 1.59 1.07q.71.67 1.1 1.58q.4.92.4 1.96m-10.63-10q-.81 0-1.54.29q-.74.28-1.33.78q-.56.5-.95 1.18q-.38.7-.5 1.5h4.12q.33 0 .59.25q.24.25.24.58v4.12l.18-.03q.17-.62.5-1.18q.32-.57.79-1.02q.45-.45 1.03-.79q.54-.33 1.17-.5q.08-.43.08-.8q0-.91-.35-1.71q-.34-.79-.94-1.38q-.59-.59-1.38-.94q-.8-.35-1.71-.35m-4.27 12q.45 0 .9-.09q.42-.09.76-.31q.34-.21.55-.56q.19-.36.19-.88q0-.53-.2-.88q-.22-.35-.55-.59q-.33-.23-.71-.38l-.72-.29Q7 11.14 6.77 11q-.22-.15-.22-.38q0-.16.12-.27q.12-.1.28-.15q.16-.07.33-.09q.18-.01.3-.01q.49 0 .88.12q.38.13.79.38V9.12Q9 9.05 8.81 9q-.2-.05-.39-.08q-.2-.03-.42-.05q-.2-.01-.45-.01q-.43 0-.88.09q-.45.1-.82.32q-.35.23-.59.56q-.23.35-.23.86q0 .5.22.81q.22.35.55.59q.33.23.7.41l.72.29q.34.14.56.29q.22.16.22.38q0 .19-.11.3q-.1.11-.26.16q-.13.08-.32.08H7q-.59 0-1.04-.18q-.46-.2-.92-.52v1.56q.99.39 2.06.39m4.27 5.5q.63 0 1.21-.25q.57-.24 1-.66q.42-.43.67-1q.25-.57.25-1.21q0-.56-.19-1.07q-.18-.51-.51-.91q-.34-.41-.8-.69q-.45-.28-1-.4v1.61q0 .33-.24.59q-.26.24-.59.24H8.31q-.06.31-.06.63q0 .64.25 1.21q.24.57.66 1q.43.42 1 .66q.57.25 1.21.25M17 17q.77 0 1.45-.29q.69-.29 1.2-.81q.51-.51.81-1.19q.29-.68.29-1.46q0-.75-.29-1.45q-.3-.69-.81-1.2t-1.2-.81Q17.77 9.5 17 9.5q-.77 0-1.45.3q-.68.3-1.19.81q-.51.51-.81 1.19q-.3.7-.3 1.45v.21l.02.22q.46.22.85.54q.38.32.7.71q.3.4.52.86q.21.46.32.95q.66.26 1.34.26Z" />
      </SvgIcon>
    );
  }

  const handleMenuListItemClick = (url: any) => {
    window.open(url, '_blank');
  };

  const toggleSideBar = () => {
    let forHum = document.querySelector('div>bh-app-shell')?.shadowRoot?.querySelector('bh-header');
    let forHumValue: any = forHum?.getElementsByClassName('bh-header__hamburger motion--fast bh-header__hamburger--open');
    if (forHumValue?.length) {
      let event = new Event('click');
      forHumValue[0].dispatchEvent(event);
    }
  };

  const handleClose = () => {
    setLoading(false);
  };

   const createNotificationRefineryData = (item: any, isPending: boolean) =>{
       return (
         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
           <div style={{ paddingLeft: '23px' }}>
             <Typography style={{ fontSize: '0.9rem', fontFamily: 'Poppins, sans-serif' }}>{item.customerName}</Typography>
             <Typography style={{ fontSize: '0.9rem', fontFamily: 'Poppins, sans-serif' }}>{item.refineryName}</Typography>
             <span className="typography--decorative-small typography--color-secondary" style={{ fontFamily: 'Poppins, sans-serif' }}>{item.requestedOn}</span>
           </div>
           <div>
             <BhButton onClick={() => NavigateToAdmin(isPending)} isSmall={true} label="View" type="primary"></BhButton>
           </div>
         </div>
       );
   }


    const getNotificationMessage =(item :any, message: string)=> {
      return (
        <div style={{paddingLeft:'23px', textWrap: 'pretty' }}>
            <Typography style={{ fontSize: '0.9rem', fontFamily: 'Poppins, sans-serif' }}>{item.approvedBy} {message} {item.customerName}, {item.refineryName}</Typography>
            <span className="typography--decorative-small typography--color-secondary" style={{ fontFamily: 'Poppins, sans-serif' }}>{item.requestedOn}</span>
        </div>
      )
    }



   const createNotificationDetails = (item: any) => {
    switch(item.notificationType) {
      case 'CR Approved':
        return getNotificationMessage(item, 'has Approved your change request for');
      case 'CR Partially Approved':
        return getNotificationMessage(item, "has Partially Approved your change request for");
      case 'Renewal Approved':
         return getNotificationMessage(item, 'has Approved your change request for');
      case 'CR Rejected':
      case 'Renewal Rejected':
        return getNotificationMessage(item, 'has Rejected your change request for');
      case 'Expired':
        return (
            <div style={{paddingLeft:'23px', textWrap: 'pretty'}}>
            <Typography style={{ fontSize: '0.9rem', fontFamily: 'Poppins, sans-serif' }}>Your change request for {item.customerName}, {item.refineryName} has been expired!</Typography>
            <span className="typography--decorative-small typography--color-secondary" style={{ fontFamily: 'Poppins, sans-serif' }}>{item.expiredOn}</span>
            </div>
        )
    }
   }

   const getCreateNotificationHeader =(item :any, message :string)=> {
    return (
     <div style={{ display: 'inline-flex' }}>
      <BhIcon icon='info' color='gold' size='small'></BhIcon>
      <Typography style={{
        fontSize: '0.9rem', fontWeight: 'bold', marginBottom: '10px', fontFamily: 'Poppins, sans-serif', marginLeft: '5px',
        marginTop: '-2px' , textWrap: 'pretty'
      }}>{message} <br/>{item.expiringOn}</Typography>
    </div>
    )
  }

  const handleDialogueCtaClick = (event: any) => {

    let div:any;  
    let appShellWrapper:any=document.querySelector('div>bh-app-shell')?.shadowRoot?.querySelector('.bh-app-shell--wrapper');
      if (appShellWrapper) {
      div=appShellWrapper.getElementsByClassName('bh-header__icon-links')[0]?.children[0];    
    }
    
    //when user changes to normal mode fetching the data stored in localstorage
    if (event.detail === 'btnExit') {
      let defaultRefinery = JSON.parse(localStorage.getItem('refineryApiResult')!).refinery;
      localStorage.setItem('defaultConfig', localStorage.getItem('refineryApiResult')!);
      const configType = defaultRefinery.unit[0].subUnit[0].configType;
      const configId =
      defaultRefinery.unit[0].subUnit &&
      defaultRefinery.unit[0].subUnit[0] &&
      defaultRefinery.unit[0].subUnit[0].configId;
      localStorage.setItem('configId', configId);
      localStorage.setItem('configType', (defaultRefinery.unit[0].unitType.trim().toLowerCase() === 'coker' 
       || defaultRefinery.unit[0].unitType.trim().toLowerCase() === 'fcc'
       || defaultRefinery.unit[0].unitType.trim().toLowerCase() === 'hydrocracker'
       || checkForHydrotreater(defaultRefinery.unit[0].unitType.trim().toLowerCase())) ? '1A': configType);
      if(defaultRefinery.unit[0].unitType.trim().toLowerCase() === 'coker' 
       || defaultRefinery.unit[0].unitType.trim().toLowerCase() === 'fcc'
       || defaultRefinery.unit[0].unitType.trim().toLowerCase() === 'hydrocracker'
       || checkForHydrotreater(defaultRefinery.unit[0].unitType.trim().toLowerCase())){
        defaultRefinery.unit[0].unitType.trim().toLowerCase() === 'coker' ? localStorage.setItem('cokerType',defaultRefinery.unit[0].subUnit[0].cokerType): 
          (defaultRefinery.unit[0].unitType.trim().toLowerCase() === 'fcc' ? localStorage.setItem('fccType', defaultRefinery.unit[0].subUnit[0].fccType):
          (defaultRefinery.unit[0].unitType.trim().toLowerCase() === 'hydrocracker' ? localStorage.setItem('hcuType', defaultRefinery.unit[0].subUnit[0].hcuType):
          localStorage.setItem('hdtType', defaultRefinery.unit[0].subUnit[0].hdtType)));
        } 
        else 
        {
           localStorage.setItem('cokerType','');
           localStorage.setItem('fccType','');
           localStorage.setItem('hdtType','');
           localStorage.setItem('hcuType','');
        }
        setSandbox(false);
        setAlertOpen(false);
        localStorage.setItem('isSandbox','false');
        
    }else{
      if(div) div.children[0].style.backgroundColor= '#147d64'; 
    }
   setExitDialogueOpen(false);
  };

  const handleSandBoxAlertClose =()=>{
    setAlertOpen(false);
   setSandBoxAlertClose(true);
  }

  

  const handleCloseExitDialogue = () => {
      let div:any;  
    let appShellWrapper:any=document.querySelector('div>bh-app-shell')?.shadowRoot?.querySelector('.bh-app-shell--wrapper');
      if (appShellWrapper) {
      div=appShellWrapper.getElementsByClassName('bh-header__icon-links')[0]?.children[0];    
    }
    if(localStorage.getItem('isSandbox') === 'true') {  
      if(div) div.children[0].style.backgroundColor= '#147d64'; 
   
    }  else{
      if(div) div.children[0].style.backgroundColor= '#05322b';
      }

    setExitDialogueOpen(false);
  };

   const createNotificationHeader = (item: any) => {
     switch (item.notificationType) {
       case 'Expiring Soon':
         return getCreateNotificationHeader(item, 'Change request expiring soon on');
       case 'Renewal Requested':
         if (isAdminOrSme) {
           return getCreateNotificationHeader(item, 'New renewal request received');
         }
         break;
       case 'Approval Requested':
         if (isAdminOrSme) {
           return getCreateNotificationHeader(item, 'New change request received');
         }
         break;
       case 'CR Partially Approved':
         if (!isAdminOrSme) return getCreateNotificationHeader(item, 'Your change request has been partially approved!');
         break;
       case 'CR Approved':
       case 'Renewal Approved':
         if (!isAdminOrSme) return getCreateNotificationHeader(item, 'Your change request has been approved!');
         break;
       case 'CR Rejected':
       case 'Renewal Rejected':
         if (!isAdminOrSme) return getCreateNotificationHeader(item, 'Your change request has been rejected!');
         break;
       case 'Expired':
         if (!isAdminOrSme) return getCreateNotificationHeader(item, 'Your change request has been expired!');
         break;
     }
   };

   const closeModal = () => {
    setOpenFlag(false);
  };

  return (
    <div>
      <Popover
        onClose={() => handleHelpMenuClose()}
        style={{ position: 'absolute', top: '50px', zIndex: 99999, left: '-77px', width: '100%' }}
        id="help-icon"
        open={helpMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ height: '90px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <MenuItem
            id="btnSharepointSite"
            onClick={() =>
              handleMenuListItemClick(
                'https://bakerhughes.sharepoint.com/sites/OilfieldIndustrialChemicalsProductLineHomepage/SitePages/TOPGUARD-Corrosion-Risk-Monitor-Application-Support.aspx'
              )
            }
          >
            <ListItemIcon>
              <SharepointIcon color="#858585" fontSize="small" />
            </ListItemIcon>
            <ListItemText>CRM SharePoint Site</ListItemText>
          </MenuItem>
          <MenuItem id="btnFeedbackPortal" onClick={() => handleMenuListItemClick('https://psdigital.ideas.aha.io/')}>
            <ListItemIcon>
              <MessageOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Feedback Portal</ListItemText>
          </MenuItem>
        </div>
      </Popover>
      <Popover
        onClose={(event: any) => handleNotificationClose()}
        style={{ position: 'absolute', top: '50px', zIndex: 99999, left: '-110px', width: '100%' }}
        id="notification-icon"
        open={notificationMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {parseInt(notificationCount) > 0 && notificationList.length ? (
        <div style={{ width: '385px', height: '290px', display: 'flex', flexDirection: 'column', padding: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px', paddingLeft: '10px' }}>
            <div>
              <Typography style={{ fontSize: '0.9rem', fontWeight: 'bold', fontFamily:'Poppins, sans-serif' }}>Notifications</Typography>
            </div>
            <div onClick={() => closeNotification()}>
              <CloseIcon style={{ cursor: 'pointer', fontSize: '1rem' }} />
            </div>
          </div>
          <div>
            <BhDivider></BhDivider>
          </div>        
          {notificationList.map((item: any) => (
            <div style={{ padding: '4px', border: 'solid 1px', borderColor: "#ced7d4", marginBottom: '1px' }}>
              {createNotificationHeader(item)}
              {(item.notificationType === 'Expiring Soon' || ((item.notificationType === 'Renewal Requested' || item.notificationType === 'Approval Requested') && isAdminOrSme)) &&
               createNotificationRefineryData(item, ((item.notificationType === 'Renewal Requested' || item.notificationType === 'Approval Requested') && isAdminOrSme) ? true : false)
              }
              {((item.notificationType === 'CR Approved' || item.notificationType === 'CR Rejected' || item.notificationType === 'Renewal Approved' || item.notificationType === 'Renewal Rejected' || item.notificationType === 'Expired' || item.notificationType === 'CR Partially Approved') && !isAdminOrSme) &&
                createNotificationDetails(item)
              } 
            </div>
            ))}
        </div>
        ):(
          <div className="empty-notification">
          <div className='empty-notification-sub-div'>
            <div>
              <Typography className="empty-notification-font">Notifications</Typography>
            </div>
            <div onClick={() => closeNotification()}>
              <CloseIcon className='empty-notification-icon' />
            </div>
          </div>
          <div>
            <BhDivider></BhDivider>
          </div>
          <div>
            <Typography style={{ fontSize: '0.9rem', fontWeight: 'bold', marginBottom: '10px', fontFamily:'Poppins, sans-serif' }}><center>Nothing to show</center></Typography>
          </div>
          </div>
        )}
      </Popover>

      <BhAppShell
        headerLimit={50}
        applogo={`${process.env.PUBLIC_URL}/assets/images/bakerhughes_logo.svg`}
        id="my-appshell"
        type="primary"
        onBhEventSelected={(e) => handleNavigation(e)}
        appname={'TOPGUARD™ Corrosion Risk Monitor'}
        iconLinks={[
          { name: 'notifications', key: 'notification-icon', badge:notificationCount },
          { name: 'help_outline', key: 'help-icon' },
        ]}
        userfirstname={firstName}
        userlastname={lastName}
        useremail={email}
        navigation={getMenuBasedOnUser()}
        navigationSelected={getSelectedURL()}
        settings={[{ label: 'Log Out', icon: 'power_settings_new', key: 'log-out' }]}
        settingsSelected={[]}
      >
         <BhContent slot={'bh-content'} backgroundColor={'primary'}>
        <BhAlertItem isOpened={isAlertOpen && !isSandBoxAlertClose} className='sandbox-alert'
  type="context"
  status="warning"
  message={location.pathname === '/' ? "Sandbox mode is active. Dashboard summary indicates actual simulation runs only and navigations to other units are turned off. To turn it off, use the Sandbox button at the top right corner of the screen.": "Sandbox mode is active. It won't affect actual configurations. To turn it off, use the Sandbox button at the top right corner of the screen."}
  dismissible
   dismiss-after ="30000"
   timeout 
   onBhEventClose={handleSandBoxAlertClose}
/>
<div id="banner" className={isSandbox ? 'banner-show' :'banner-hide'}>
    <div id="banner-content">
      <center>
    Sandbox mode is active
    </center>
    </div>
  </div>
<div>
        <Backdrop
          onClick={handleClose}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
          open={loading}
        >
          {loading ? <BhSpinner size="large"></BhSpinner> : null}
        </Backdrop>
      </div>
      <Dialog open={isError}>
        <DialogTitle>
          <h3 className="typography--subtitle-medium bh-dialog__header-copy--header typography--color-primary">{errorHeader}</h3>
        </DialogTitle>
        <DialogContent>
          <span className="typography--body-small bh-dialog__body-copy">{errorMessage}</span>
        </DialogContent>
      </Dialog>
      <ChangeUnitModal
        isOpen={isOpen}
        header="Select unit"
        isDismissible={false}
        getConfigData={getConfigData}
        closeModal={closeModal}
      ></ChangeUnitModal>
      <BhDialog
                    header="Exit sandbox mode"
                    message="Are you sure you want to proceed and exit the sandbox mode?"
                    ctas={[                   
                      {
                        type: 'ghost',
                        label: 'No,Continue',
                        key: 'btnCancel',
                      },
                      {
                        type: 'primary',
                        label: 'Yes,Exit',
                        key: 'btnExit',
                      },
                    ]}
                    isOpen={isExitDialogueOpen}
                    onBhEventCtaClick={(event: any) => handleDialogueCtaClick(event)}
                    onBhEventClose={handleCloseExitDialogue}
                  ></BhDialog>
</BhContent>
        <BhContent slot={'bh-content'} backgroundColor={'primary'}>
          <Routes>
            <Route path="/" element={<RequiredAuth />}>
              <Route path="" element={<FavoriteList />} />
            </Route>
            <Route path="/dashboard" element={<RequiredAuth />}>
              <Route path="" element={<Dashboard />} />
            </Route>
            <Route path="/home" element={<RequiredAuth />}>
              <Route path="" element={<LandingPage getConfigData={getConfigData} getConfigDataForSandBox={getConfigDataForSandBox} getNotification={getNotification} />} />
            </Route>
            <Route path="/new-simulation" element={<RequiredAuth />}>
              <Route path="" element={<NewSimulation />} />
            </Route>
            <Route path="/simulation-data" element={<RequiredAuth />}>
              <Route path="" element={<SimulationData />} />
            </Route>
            <Route path="/simulations" element={<RequiredAuth />}>
              <Route path="" element={<AllSimulations />} />
            </Route>
            <Route path="/simulation-report" element={<RequiredAuth />}>
              <Route path="" element={<SimulationReport />} />
            </Route>
            <Route path="/primary-report" element={<RequiredAuth />}>
              <Route path="" element={<CorrosionRiskReport />} />
            </Route>
            <Route path="/admin-center" element={<RequiredAuth />}>
              <Route path="" element={<AdminCenter getConfigData={getConfigData} getNotification={getNotification} />} />
            </Route>
       
            <Route path="/login/callback/" element={<LoginCallback />} />
          </Routes>
        </BhContent>
      </BhAppShell>
    </div>
    
  );
}
