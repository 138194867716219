/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import {
  BhCard,
  BhDivider,
  BhDropdown,
  BhIncrementer,
  BhModal,
  BhRadioButton,
  BhSelectionGroup,
  BhTextInput,
  BhTitleWrapper,
  BhSpinner,
  BhCheckbox,
  BhDateRangePicker,
  BhTextArea,
  BhFormMessage,
  BhToggle,
} from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Alert, Autocomplete, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState, useCallback } from 'react';
import ConfigurationPreview from '../ConfigurationPreview/ConfigurationPreview';
import './EditConfigurationModal.scss';
import apiRequest from '../../../services/api-helper';
import { APIEnum } from '../../../constants/api-enum';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { changeToShortFormat, decryptData } from '../../../utils/utils';
import HistoryIcon from '@mui/icons-material/History';
declare const window: any;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface VALIDATE_ITEM {
  sprayPipe : boolean;
  sprayPipeLength: boolean;
  sprayNozzleVal : boolean;
  PipeLengthUnit : boolean;
}

interface SprayNozzleType {
  label : string;
  value: number;
}

const useStyles: any = makeStyles((theme: any) => ({
  MuiAccordionroot: {
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
    //  height: "20px"
  },
}));

export default function EditConfigurationModal(props: any) {
  const { isConfigOpen, closeConfigModal, getConfigData, getConfigDataForSandBox } = props;
    let configDataFromStore :any = {facility : {}}
    configDataFromStore.facility = JSON.parse(localStorage.getItem('defaultConfig')!)

  //general tab
  const [changeReflected, setChangeReflected] = useState<any>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [isCommentError,setCommentError] = useState(false);
  const [isInvalidStartDate, setInvalidStartDate] = useState(false);
  const [isInvalidEndDate, setInvalidEndDate] = useState(false);
  const [isEndDateBeyondYear, setEndDateBeyondYear] = useState(false);
  const [endDate, setEndDate] = useState<string>('');
  const [ctaData, setCtaData] = useState<any>([]);
  const [ctaDataForConfirmation, setCtaDataForConfirmation] = useState<any>([
    { label: 'Cancel', key: 'confirmation-cancel', type: 'ghost' },
    { label: 'Submit', key: 'submit-btn', type: 'primary', isDisabled: true },
  ]);
  const [oneStageConfig, setOneStageConfig] = useState<boolean>(false);
  const [oneStageDesalter, setOneStageDesalter] = useState<boolean>(false);
  const [preflashData, setPreflashData] = useState<any>([]);
  const [selectedPreflashConfig, setSelectedPreflashConfig] = useState<string>('');
  const [selectedPreflashConfigText, setSelectedPreflashConfigText] = useState<string>('');
  const [preflashDroDownValues, setPreflashDroDownValues] = useState<any[]>([]);
  const [pipeInsideDiameter, setPipeInsideDiameter] = useState('');
  const [pipeInsideDiameterUnits, setPipeInsideDiameterUnits] = useState<any>({});
  const [pipeInsideDiameterUnit, setPipeInsideDiameterUnit] = useState('inches');
  const [branchNumber, setbranchNumber] = useState<string>('');
  const [isWaterWashUsed, setIsWaterWashUsed] = useState<boolean>(false);
  const [washInjectionPoint, setwashInjectionPoint] = useState<boolean>(false);
  const [orientedConcurrent, setOrientedConcurrent] = useState<boolean>(false);
  const [orientedCounterCurrent, setOrientedCounterCurrent] = useState<boolean>(false);
  const [other, setOther] = useState<boolean>(false);
  const [continuousWashFrequency, setContinuousWashFrequency] = useState<boolean>(false);
  const [beforeWashInjection, setBeforeWashInjection] = useState<boolean>(false);
  const [waterWashOverHeadFlow, setWaterWashOverHeadFlow] = useState<boolean>(false);
  const [firstStageNeutralizer, setFirstStageNeutralizer] = useState<boolean>(false);
  const [processParamAcc, setProcessParamAcc] = useState<boolean>(true);
  const [velocityParamAcc, setVelocityParamAcc] = useState<boolean>(false);
  const [waterwashParamAcc, setWaterwashParamAcc] = useState<boolean>(false);
  const [neutralizerParamAcc, setNeutralizerParamAcc] = useState<boolean>(false);
  const [overHeadWaterFlowAcc, setOverHeadWaterFlowAcc] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const [isError, setErrorFlag] = useState(false);
  const [isSuccess, setSuccessFlag] = useState(false);
  const [configType, setConfigType] = useState<string>('');
  const [isGeneralTabOpen, setGeneralTabOpen] = useState<boolean>(true);
  const [isAdvancedTabOpen, setAdvancedTabOpen] = useState<boolean>(false);
  const [isSprayModelTabOpen, setSprayModelTabOpen] = useState<boolean>(false);
  const [confirmationBoxOpen, setConfirmationBoxOpen] = useState<boolean>(false);
  const classes = useStyles();

  //uom tab
  const [value, setValue] = React.useState(0);
  const [uomApiResponse, setUomApiResponse] = useState<any>({});
  const [uomCategoryNames, setUomCategoryNames] = useState<any>([]);
  const [uomSubCategories, setUomSubCategories] = useState<any>([]);

  //advanced tab
  const [isFlooding, setFlooding] = useState<boolean>(false);
  const [velocityRiskAdjust, setVelocityRiskAdjust] = useState<string>('1');
  const [ammoniaFactor, setAmmoniaFactor] = useState<string>('2.0');
  const [enableLowerPH, setLowerPH] = useState<boolean>(false);
  const [triggerPH, setTriggerPH] = useState<string>('1');
  const [tubeSkinApproach, setTubeSkinApproach] = useState<string>('0');
  const [refluxRecycleFrac, setRefluxRecycleFrac] = useState<string>('0');
  const [isCycleUpCalc, setCycleUpCalc] = useState<boolean>(true);
  const [tubeSkinApproachLabel, setTubeSkinApproachLabel] = useState<string>('');
  const [isAdvTabVisible, setAdvTabVisible] = useState<boolean>(false);
  const [mainTabValue, setMainTabValue] = React.useState(0);

  //sandbox
  const [isAdminOrSme, setAdminOrSme]=useState<boolean>();

  //coker
  const [unitType, setUnitType] = useState<string>('');

  // spray model
  const [isEnableSprayModel, setEnableSprayModel] = useState<boolean>(false);
  const [sprayNozzleList, setSprayNozzleList] = useState<any>([]);
  const [sprayNozzle, setSprayNozzle] = useState(0);
  const [sprayPipeInsideDiameter, setSprayPipeInsideDiameter] = useState('');
  const [sprayPipeInsideDiameterLength, setSprayPipeInsideDiameterLength] = useState<string>('');
  const [sprayPipeInsideDiameterUnit, setSprayPipeInsideDiameterUnit] = useState('inches');
  const [sprayPipeInsideDiameterLengthUnits, setSprayPipeInsideDiameterLengthUnits] = useState<any>([]);
  const [sprayPipeInsideDiameterLengthUnit, setSprayPipeInsideDiameterLengthUnit] = useState('ft');
  const [branchNumberForSpray, setBranchNumberForSpray] = useState<string>('');
  const [validateItems, setValidateItems] = useState<VALIDATE_ITEM>({sprayPipe : false, sprayPipeLength: false, sprayNozzleVal : false, PipeLengthUnit: false});
  


  const setRefineryObj = (isRequest : boolean) => {
    let operator: any = {};
    if (configDataFromStore && configDataFromStore.facility) {
      //operator.customerId = configDataFromStore.facility.customerId;
      //operator.customerName = configDataFromStore.facility.customerName;
      if (configDataFromStore.facility.refinery) {
        let refinery: any = {};
        refinery.refineryId = configDataFromStore.facility.refinery.refineryId;
        refinery.refineryName = configDataFromStore.facility.refinery.refineryName;
        refinery.refineryType = configDataFromStore.facility.refinery.refineryType;
        refinery.customerId = configDataFromStore.facility.refinery.customerId;
        refinery.customerName = configDataFromStore.facility.refinery.customerName;
        if (configDataFromStore.facility.refinery.unit && configDataFromStore.facility.refinery.unit[0]) {
          let unit: any[] = [{}];
          unit[0].unitId = configDataFromStore.facility.refinery.unit[0].unitId;
          unit[0].unitName = configDataFromStore.facility.refinery.unit[0].unitName;
          unit[0].unitType = configDataFromStore.facility.refinery.unit[0].unitType;
          unit[0].preflashPrefractionator = selectedPreflashConfig;
          if(isAdvTabVisible) {
            unit[0].sprayModelConfig = {
              enableSpraymodel : isEnableSprayModel,
              sprayNozzleId : sprayNozzle,
              pipeInsideDiameter : sprayPipeInsideDiameter,
              pipeInsideUnit : sprayPipeInsideDiameterUnit,
              numberOfbranches : branchNumberForSpray,
              pipeLength : sprayPipeInsideDiameterLength,
              pipelengthUnit: sprayPipeInsideDiameterLengthUnit
            };
          }
          if (configDataFromStore.facility.refinery.unit[0].subUnit && configDataFromStore.facility.refinery.unit[0].subUnit[0]) {
            let subUnit: any[] = [{}];
            subUnit[0].subUnitId = configDataFromStore.facility.refinery.unit[0].subUnit[0].subUnitId;
            subUnit[0].subUnitName = configDataFromStore.facility.refinery.unit[0].subUnit[0].subUnitName;
            subUnit[0].typeSubunit = configDataFromStore.facility.refinery.unit[0].subUnit[0].typeSubunit;
            if(configType !== '1C' && configType !== '2C') {
              subUnit[0].numDesalterStages = oneStageDesalter ? 1 : 2;
            }
            subUnit[0].waterWash = isWaterWashUsed;
            subUnit[0].numOvhdStages = oneStageConfig ? 1 : 2;
            subUnit[0].idOvhdStream = pipeInsideDiameter;
            subUnit[0].idOvhdStreamUom = pipeInsideDiameterUnit;
            subUnit[0].numBranchesOvhdStream = branchNumber;
            subUnit[0].stageOvhdWashStream = washInjectionPoint ? 1 : 2;
            if (orientedConcurrent) subUnit[0].equipmentOvhdWashStream = 'Spray nozzle oriented cocurrent';
            else if (orientedCounterCurrent) subUnit[0].equipmentOvhdWashStream = 'Spray nozzle oriented countercurrent';
            else if (other) subUnit[0].equipmentOvhdWashStream = 'Other';
            subUnit[0].stageOvhdNeutralizerStream = firstStageNeutralizer ? 1 : 2;
            subUnit[0].injectedNeutralizerStream = beforeWashInjection ? 'Before Wash Water' : 'With or After Wash Water';
            subUnit[0].washFrequency = continuousWashFrequency ? 'Continuous' : 'Intermittent';
            subUnit[0].isOvhdWaterFlowMetersEnabled = waterWashOverHeadFlow;
            subUnit[0].configId = configDataFromStore.facility.refinery.unit[0].subUnit[0].configId;
            unit[0].subUnit = subUnit;
            if (isAdvTabVisible) {
              //advanced tab data save in case of sme role
              let smeConfig: any = {};
              smeConfig.enableLowerPH = enableLowerPH;
              smeConfig.isCycleUpCalc = isCycleUpCalc;
              smeConfig.isFlooding = isFlooding;
              smeConfig.refluxRecycleFrac = parseFloat(refluxRecycleFrac).toFixed(1);
              smeConfig.subunitId = configDataFromStore.facility.refinery.unit[0].subUnit[0].subUnitId;
              smeConfig.triggerPH = parseFloat(triggerPH).toFixed(1);
              smeConfig.velocityRiskAdjust = parseFloat(velocityRiskAdjust).toFixed(1);
              smeConfig.ammoniaAdjustmentFactor = parseFloat(ammoniaFactor).toFixed(1);
              smeConfig.tubeSkinApproach = parseInt(tubeSkinApproach);
              unit[0].smeConfig = smeConfig;
            }
            //edit config change request deatils
            if(isRequest){
              let configChangeRequest: any = {};
              configChangeRequest.startDate = startDate;
              configChangeRequest.endDate = endDate;
              configChangeRequest.requestorComments = comments;
              unit[0].configChangeRequest = configChangeRequest;
            }
            refinery.unit = unit;
            operator.refinery = refinery;
          }
        }
      }
    }
    return operator;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleMainTabChange = (event: any, newValue: number) => {
    setMainTabValue(newValue);
    if (event.target.innerText.toLowerCase() === 'general') {
      setGeneralTabOpen(true);
      submitButtonChange((!localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false') && !isAdminOrSme && changeReflected.length < 0 ? true : (changeReflected.length > 0 || isAdminOrSme || (localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true') ? false : true), (!localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false') && !isAdminOrSme ? 'Submit change request':'Save changes');
    }
    //advanced tab
    else if (event.target.innerText.toLowerCase() === 'advanced') {
      setAdvancedTabOpen(true);
      setSprayModelTabOpen(false);
      setGeneralTabOpen(false);
      submitButtonChange(false,'Save changes');
    }
    else if (event.target.innerText.toLowerCase() === 'spray model') {
      setSprayModelTabOpen(true);
      setAdvancedTabOpen(false);
      setGeneralTabOpen(false);
      submitButtonChange(false,'Save changes');
    }
    //uom tab
    else {
      setSprayModelTabOpen(false);
      setGeneralTabOpen(false);
      setAdvancedTabOpen(false);
      getUomCategory();
      submitButtonChange(false,'Save changes');
    }
  };

  const validateSubmitSpray = ()=> {
    let checkItem : boolean = false;
    let valItem = {...validateItems};

    if(sprayNozzle) {
      valItem.sprayNozzleVal = false;
    }
    else {
      valItem.sprayNozzleVal = true;
    }
     
    if(sprayPipeInsideDiameter) {
      valItem.sprayPipe = false;
    }
    else {
      valItem.sprayPipe = true;
    }

    if(sprayPipeInsideDiameterLength) {
      valItem.sprayPipeLength = false;
    }
    else {
      valItem.sprayPipeLength = true;
    }

    if(sprayPipeInsideDiameterLengthUnit) {
      valItem.PipeLengthUnit = false;
    }
    else {
      valItem.PipeLengthUnit = true;
    }

     if(Object.values(valItem).includes(true)){
       checkItem = true;
     }

    setValidateItems(valItem);
    return checkItem;
  }

  const setFlags =(isSuccess: boolean, isSandBox:boolean, isChangeRequest:boolean, errorMessageResponse? :string) =>{
    if(isSuccess){
    setSuccessFlag(true);
    isChangeRequest ? setMessage('Your change request has been submitted successfully'): setMessage('Unit details saved successfully');
    setTimeout(() => {
      //refineries api call to get updated config
      isSandBox ? getConfigDataForSandBox() : getConfigData();
      localStorage.removeItem('sandboxsimulation');
      localStorage.removeItem('simulationData');
      localStorage.removeItem('manualInput');
      closeConfigModal();
    }, 1000);
  }
  else{
    setErrorFlag(true);
    errorMessageResponse
                ? setMessage(errorMessageResponse)
                : setMessage('Bad Request');
    setTimeout(() => {
                closeConfigModal();
              }, 3000);
  }
  }
  const saveData = (isRequest:boolean) => {
    if (isGeneralTabOpen || isAdvancedTabOpen || isSprayModelTabOpen) {
      if(isAdminOrSme) {
        if(isEnableSprayModel) {
          let validateSubmitForSpray = validateSubmitSpray();
          if(validateSubmitForSpray) {
            return;
          }
        }
      }
    
      let refineryObj = setRefineryObj(isRequest);
      let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/refineries/defaultconfig`;
      apiRequest(encodeURI(apiUrl), refineryObj, APIEnum.POST, true, localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' ? 'SandBox':'Actual')
        .then((result) => {
          if(!isRequest){
          //sandbox mode 
          if(localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true'){
          if (result &&  result.responseStatus && result.responseStatus.status && result.responseStatus.status === 'SUCCESS') {
            setFlags(true,true,false);
          } else {
            setFlags(false, true, false, result &&
              result.responseStatus &&
              result.responseStatus.errorResponse &&
              result.responseStatus.errorResponse[0] &&
              result.responseStatus.errorResponse[0].message);           
          }
          }
          //normal mode
          else{
            if(isAdminOrSme){
              if (result && result.responseStatus && result.responseStatus.status && result.responseStatus.status === 'SUCCESS')
                setFlags(true,false,false);
              else setFlags(false, false, false, result &&
                result.responseStatus &&
                result.responseStatus.errorResponse &&
                result.responseStatus.errorResponse[0] &&
                result.responseStatus.errorResponse[0].message);
            }
            else{
              if (result && result.responseStaus && result.responseStaus.status && result.responseStaus.status === 'SUCCESS')
                setFlags(true,false, false);
                else setFlags(false, false, false, result &&
                  result.responseStaus &&
                  result.responseStaus.errorResponse &&
                  result.responseStaus.errorResponse[0] &&
                  result.responseStaus.errorResponse[0].message);
            }
          }
         }
         else{
          if (result && result.responseStatus && result.responseStatus.status && result.responseStatus.status === 'SUCCESS') {
            setFlags(true,false, true);
          } else {
            setFlags(false, false, false, result &&
              result.responseStatus &&
              result.responseStatus.errorResponse &&
              result.responseStatus.errorResponse[0] &&
              result.responseStatus.errorResponse[0].message);
          }
         }
        })
        .catch((error) => {
          setErrorFlag(true);
          setMessage(error.message);
          setTimeout(() => {
            //getConfigData();
            closeConfigModal();
          }, 2500);
        });
    }
    //uom data save
    else if (!isGeneralTabOpen && !isAdvancedTabOpen && !isSprayModelTabOpen) {
      uomClassSaveApi();
    }
  };

  const handleCtaClick = (event: CustomEvent) => {
    if (event.detail === 'submit-btn') {
      setConfirmationBoxOpen(false);
      saveData(true);
    }
    if (event.detail === 'confirmation-cancel') {
      setConfirmationBoxOpen(false);
      setComments('');
      setCommentError(false);
      setInvalidStartDate(false);
      setInvalidEndDate(false);
      setEndDateBeyondYear(false);
    }
    if (event.detail === 'btnCancel') {
      closeConfigModal();
    } else if (event.detail === 'btnSave') {
      if(localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' || isAdminOrSme || !isGeneralTabOpen){
        saveData(false);
     }
     else{
      setConfirmationBoxOpen(true);
     }
    }
  };

  const createSubCategoryDropDowns = (subCategoryObj: any, categoryId: any) => {
    if (Object.keys(subCategoryObj).length) {
      let subCategoryUnits: any = [];
      let dropDownId = 'uom-' + (subCategoryObj.subCategoryName ? subCategoryObj.subCategoryName.replace(/ +/g, '').toLowerCase() : '');
      if (subCategoryObj.uomUnits && subCategoryObj.uomUnits.length) {
        subCategoryObj.uomUnits.forEach((unitItem: any) => {
          let labelName;
          if (subCategoryObj.subCategoryName.toLowerCase() === 'temperature') {
            labelName = '°' + unitItem.unitSymbol;
          } else {
            labelName = unitItem.unitSymbol;
          }
          subCategoryUnits.push({
            label: labelName,
            value: unitItem.uomUnitId,
            id: unitItem.uomUnitId,
            subId: subCategoryObj.subCategoryId,
            categId: categoryId,
          });
        });
      }
      return (
        <div className="uom-category">
          <BhDropdown
            label={subCategoryObj.subCategoryName}
            menuItems={JSON.stringify(subCategoryUnits)}
            value={
              subCategoryObj.subCategoryName.toLowerCase() === 'temperature' ? '°' + subCategoryObj.defaultUnit : subCategoryObj.defaultUnit
            }
            isRequired
            id={dropDownId}
            onBhEventChange={(event: any) => handleDropDownChange(event)}
          ></BhDropdown>
        </div>
      );
    }
  };

  const getUomCategory = () => {
    let subUnitId;
    if (
      configDataFromStore &&
      configDataFromStore.facility &&
      configDataFromStore.facility.refinery &&
      configDataFromStore.facility.refinery.unit &&
      configDataFromStore.facility.refinery.unit[0]
    ) {
      subUnitId = configDataFromStore.facility.refinery.unit[0].subUnit[0].subUnitId;
    }
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/uom/classes/${subUnitId}`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((response) => {
        setUomApiResponse(response);
        let categories: any = [];
        let uomSubCategoryMapping: any = [];
        if (Object.keys(response).length && Object.keys(response.uomCategory).length) {
          response.uomCategory.forEach((categoryItem: any) => {
            categories.push(categoryItem.categoryName);
            categoryItem.uomSubCategory.forEach((subCategoryItem: any) => {
              uomSubCategoryMapping.push({
                categoryId: categoryItem.categoryId,
                subCategoryId: subCategoryItem.subCategoryId,
                defaultUnitId: subCategoryItem.defaultUomUnitId,
              });
            });
          });
        }
        setUomCategoryNames(categories);
        setUomSubCategories(uomSubCategoryMapping);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const handleInputChange = (event: any) => {
    const validateItemsCopy  = {...validateItems}
    if(event.target.id === 'pipe-diameter-txt') {
      setPipeInsideDiameter(event.target.value);
    }
    if(event.target.id === 'spray-pipe') {
      if(!event.target.value) {
        validateItemsCopy.sprayPipe = true;
      }
      else {
        validateItemsCopy.sprayPipe = false;
      }
      setSprayPipeInsideDiameter(event.target.value);
    }
    if(event.target.id === 'spray-pipe-length') {
      if(!event.target.value) {
        validateItemsCopy.sprayPipeLength = true;
      }
      else {
        validateItemsCopy.sprayPipeLength = false;
      }
      setSprayPipeInsideDiameterLength(event.target.value);
    }

    if(event.target.id === 'velocity-risk') {
      setVelocityRiskAdjust(event.detail);
    }
    if(event.target.id === 'lower-ph') {
      setTriggerPH(event.detail);
    }
    if(event.target.id === 'reflux-fr') {
      setRefluxRecycleFrac(event.detail);
    }
    if(event.target.id === 'ammonia-factor') {
      setAmmoniaFactor(event.detail);
    }

    setValidateItems(validateItemsCopy);
  };

  const handleIncrementChange = (event: any) => {
    switch (event.target.id) {
      case 'inc-branch':
        setbranchNumber(event.detail);
        break;
      case 'lower-ph':
        setTriggerPH(event.detail);
        break;
      case 'tube-skin':
        setTubeSkinApproach(event.detail);
        break;
      case 'spray-branch':
        setBranchNumberForSpray(event.detail);
        break;
    }
  };

  const handleCheckboxChange = (event: any) => {
    if (event.target.id === 'overheadLiquidCheckbox') {
      setFlooding(event.detail);
    } else if (event.target.id === 'refluxCycleCheckbox') {
      setCycleUpCalc(event.detail);
    } else if (event.target.id === 'lowerPhCheckbox') {
      setLowerPH(event.detail);
    }
  };

  const handleDropDownChangeForSpray = (event: any) => {
    const dropDownValidation = {
        ...validateItems
    }
    if (event.target.id === 'spray-unit') {
        setSprayPipeInsideDiameterUnit(event.target.selectedValue);
    } else {
        if (!event.target.selectedValue) {
            dropDownValidation.PipeLengthUnit = true;
        } else {
            dropDownValidation.PipeLengthUnit = false;
        }
        setSprayPipeInsideDiameterLengthUnit(event.target.selectedValue);
    }
    setValidateItems(dropDownValidation);
  }
  
  const handleDropDownChange = (event: any) => {
    if (event.target.id === 'pipe-diameter-unit') {
      setPipeInsideDiameterUnit(event.target.selectedValue);
    } else if (event.target.id === 'preflash-config') {
      setSelectedPreflashConfig(event.target.selectedValue);
      preflashDroDownValues &&
        preflashDroDownValues.length &&
        setSelectedPreflashConfigText(preflashDroDownValues.filter((item: any) => item.value === event.target.selectedValue)[0].label);
    }
    //for uom class
    else {
      let subCategoryId = JSON.parse(event.target.menuItems).filter((m: any) => m.value === event.target.selectedValue)[0].subId;
      let categoryId = JSON.parse(event.target.menuItems).filter((m: any) => m.value === event.target.selectedValue)[0].categId;
      let apiResponse = { ...uomApiResponse };
      if (Object.keys(apiResponse).length) {
        let responseIndex = apiResponse.uomCategory.findIndex((m: any) => m.categoryId === categoryId);
        let subIndex = apiResponse.uomCategory[responseIndex].uomSubCategory.findIndex((m: any) => m.subCategoryId === subCategoryId);
        apiResponse.uomCategory[responseIndex].uomSubCategory[subIndex].defaultUnitId = event.target.selectedValue;
        apiResponse.uomCategory[responseIndex].uomSubCategory[subIndex].defaultUnit =
          event.target.value === '°F' || event.target.value === '°C' ? event.target.value.slice(1) : event.target.value;
        setUomApiResponse(apiResponse);
      }
      let uomSubCategoriesCopy;
      if (Object.keys(uomSubCategories).length && uomSubCategories.length) {
        uomSubCategoriesCopy = [...uomSubCategories];
        //already mapping available for category and subcategory
        let filteredIndex: number = uomSubCategoriesCopy.findIndex(
          (m: any) => m.categoryId === categoryId && m.subCategoryId === subCategoryId
        );
        if (filteredIndex !== -1) {
          uomSubCategoriesCopy[filteredIndex].defaultUnitId = event.target.selectedValue;
        }
        setUomSubCategories(uomSubCategoriesCopy);
      }
    }
  };

  const uomClassSaveApi = () => {
    let subUnitId;
    if (
      configDataFromStore &&
      configDataFromStore.facility &&
      configDataFromStore.facility.refinery &&
      configDataFromStore.facility.refinery.unit &&
      configDataFromStore.facility.refinery.unit[0]
    ) {
      subUnitId = configDataFromStore.facility.refinery.unit[0].subUnit[0].subUnitId;
    }
    let saveObj = { subUnitId: subUnitId, uomSubCategories: uomSubCategories };
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/defaultuoms`;
    apiRequest(encodeURI(apiUrl), saveObj, APIEnum.POST)
      .then((response) => {
        if (Object.keys(response).length && Object.keys(response.responseStatus).length && response.responseStatus.status === 'SUCCESS') {
          setSuccessFlag(true);
          setMessage('Units of measure saved successfully');
          setTimeout(() => {
            //sandbox mode 
            if(!localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false'){
              //refineries api call to get updated config
              getConfigData();
            }
            closeConfigModal();
          }, 1000);
        } else {
          if (
            response.responseStatus.status === 'ERROR' &&
            response.responseStatus.errorResponse &&
            response.responseStatus.errorResponse[0]
          ) {
            setErrorFlag(true);
            setMessage(response.responseStatus.errorResponse[0].message);
            setTimeout(() => {
              closeConfigModal();
            }, 3500);
          }
        }
      })
      .catch((error) => {
        setErrorFlag(true);
        setMessage(error.message);
        setTimeout(() => {
          closeConfigModal();
        }, 2500);
      });
  };

  useEffect(() =>{
    if (configDataFromStore && configDataFromStore.facility && configDataFromStore.facility.refinery?.unit[0]) {
      setUnitType(configDataFromStore.facility.refinery?.unit[0].unitType.trim().toLowerCase());
      if(configDataFromStore.facility.refinery?.unit[0].unitType.trim().toLowerCase() === 'coker' 
      || configDataFromStore.facility.refinery?.unit[0].unitType.trim().toLowerCase() === 'fcc'
      || configDataFromStore.facility.refinery?.unit[0].unitType.trim().toLowerCase() === 'hydrocracker'
      || configDataFromStore.facility.refinery?.unit[0].unitType.trim().toLowerCase() === 'hydrotreater-distillate'
      || configDataFromStore.facility.refinery?.unit[0].unitType.trim().toLowerCase() === 'hydrotreater-gas oil'
      || configDataFromStore.facility.refinery?.unit[0].unitType.trim().toLowerCase() === 'hydrotreater-naphtha'
      || configDataFromStore.facility.refinery?.unit[0].unitType.trim().toLowerCase() === 'hydrotreater-naphtha renewable'
      || configDataFromStore.facility.refinery?.unit[0].unitType.trim().toLowerCase() === 'hydrotreater-resid') 
        setVelocityParamAcc(true);
    }
  },[pipeInsideDiameter])

  const checkUserAdminOrSme = () => {
    if (
      localStorage.getItem('salt') &&
      Object.keys(localStorage.getItem('salt')!).length &&
      decryptData(localStorage.getItem('salt')) &&
      decryptData(localStorage.getItem('salt')).userRole &&
      (decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'sme' ||
        decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'admin') &&
      decryptData(localStorage.getItem('salt')).userActiveStatus
    ) {
      setAdminOrSme(true);
    } else setAdminOrSme(false);
  };

  useEffect(() => {
    if(isAdminOrSme) {
      getSprayNozzleList();
    }
  }, [isAdminOrSme]);

  const getSprayNozzleList =()=> {
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/sprayNozzle`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
    .then((response) => {
      if(response?.responseStatus?.status === 'SUCCESS') {
        if(response?.sprayNozzleLookUpNames?.length > 0) {
          setSprayNozzleList(response?.sprayNozzleLookUpNames)
        }
      }
      else {
        setSprayNozzleList([])
      }
    })
    .catch((error) => {
      console.log(error);
    });

  }

  useEffect(() => {

    checkUserAdminOrSme();
  
    if (configDataFromStore?.facility?.refinery?.unit[0] && (!localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false') && !isAdminOrSme) {
      let changes = [];

      let storeOneA = configDataFromStore.facility.refinery.unit[0].subUnit[0].numOvhdStages;
      let storeDesalter = configDataFromStore.facility.refinery.unit[0].subUnit[0].numDesalterStages;
      let preFlashStore = configDataFromStore.facility.refinery.unit[0].preflashPrefractionator;
      let storeValue = oneStageConfig ? 1 : 2;
      let storeValueDesalter = oneStageDesalter ? 1 : 2;

      // Tab 1
      if (storeOneA) {
        if (storeOneA !== storeValue) {
          changes.push({ tab: 'process-preflash' });
        }
      }
      if(configType !== '1C' && configType !== '2C') {
        if (storeDesalter) {
          if (storeValueDesalter !== storeDesalter) {
            changes.push({ tab: 'process-preflash' });
          }
        }
      }
      if (preFlashStore) {
        if (preFlashStore !== selectedPreflashConfigText) {
          changes.push({ tab: 'process-preflash' });
        }
      }

      // Tab 2
      let pipeInsideDiameterStore = configDataFromStore.facility.refinery.unit[0].subUnit[0].idOvhdStream;
      if (pipeInsideDiameterStore) {
        if (pipeInsideDiameterStore !== pipeInsideDiameter) {
          changes.push({ tab: 'panel-velocity' });
        }
      }

      let pipeInsideDiameterUnitStore = configDataFromStore.facility.refinery.unit[0].subUnit[0].idOvhdStreamUom;
      if (pipeInsideDiameterUnitStore) {
        if (pipeInsideDiameterUnitStore !== pipeInsideDiameterUnit) {
          changes.push({ tab: 'panel-velocity' });
        }
      }

      let branchNumberStore = configDataFromStore.facility.refinery.unit[0].subUnit[0].numBranchesOvhdStream;
      if (branchNumberStore) {
        if (branchNumberStore != branchNumber) {
          changes.push({ tab: 'panel-velocity' });
        }
      }

      // Tab 3
      let waterWashUsedStore = configDataFromStore.facility.refinery.unit[0].subUnit[0].waterWash;
      if (waterWashUsedStore !== null || waterWashUsedStore !== undefined) {
        if (waterWashUsedStore !== isWaterWashUsed) {
          changes.push({ tab: 'panel-waterwash' });
        }
      }

      let waterInjectionStore = configDataFromStore.facility.refinery.unit[0].subUnit[0].stageOvhdWashStream;
      let waterInjectionChangedStore = washInjectionPoint ? 1 : 2;

      if (waterInjectionStore) {
        if (waterInjectionStore != waterInjectionChangedStore) {
          changes.push({ tab: 'panel-waterwash' });
        }
      }

      let orientConcurrent = configDataFromStore.facility.refinery.unit[0].subUnit[0].equipmentOvhdWashStream;
      let orientConcurrentStore = orientConcurrent === 'Spray nozzle oriented cocurrent';
      if (orientConcurrent) {
        if (orientConcurrentStore !== orientedConcurrent) {
          changes.push({ tab: 'panel-waterwash' });
        }
      }

      let orientCounterConcurrent = configDataFromStore.facility.refinery.unit[0].subUnit[0].equipmentOvhdWashStream;
      let orientCounterConcurrentStore = orientCounterConcurrent === 'Spray nozzle oriented countercurrent';
      if (orientCounterConcurrent) {
        if (orientCounterConcurrentStore !== orientedCounterCurrent) {
          changes.push({ tab: 'panel-waterwash' });
        }
      }

      let orientCounterConcurrentOther = configDataFromStore.facility.refinery.unit[0].subUnit[0].equipmentOvhdWashStream;
      let orientCounterConcurrentOtherStore = orientCounterConcurrentOther === 'Other';
      if (orientCounterConcurrentOther) {
        if (orientCounterConcurrentOtherStore !== other) {
          changes.push({ tab: 'panel-waterwash' });
        }
      }

      let washFrequencyValue = configDataFromStore.facility.refinery.unit[0].subUnit[0].washFrequency;
      let washFrequencyValueStore = continuousWashFrequency === true ? 'Continuous' : 'Intermittent';
      if (washFrequencyValue) {
        if (washFrequencyValue !== washFrequencyValueStore) {
          changes.push({ tab: 'panel-waterwash' });
        }
      }

      // Tab 4

      let beforeWashInjectionStore = configDataFromStore.facility.refinery.unit[0].subUnit[0].injectedNeutralizerStream && configDataFromStore.facility.refinery.unit[0].subUnit[0].injectedNeutralizerStream.toLowerCase();
      let beforeWashInjectionStoreValue = beforeWashInjection === true ? 'before wash water' : 'with or after wash water';
      if (beforeWashInjectionStore) {
        if (beforeWashInjectionStore !== beforeWashInjectionStoreValue) {
          changes.push({ tab: 'panel-neutralizer' });
        }
      }

      let firstStage = configDataFromStore.facility.refinery.unit[0].subUnit[0].stageOvhdNeutralizerStream;
      let firstStageStore = firstStageNeutralizer ? 1 : 2;
      if (firstStage) {
        if (firstStage != firstStageStore) {
          changes.push({ tab: 'panel-neutralizer' });
        }
      }

      //Tab 5
      let waterFlowStore = configDataFromStore.facility.refinery.unit[0].subUnit[0].isOvhdWaterFlowMetersEnabled;
      if (waterFlowStore !== null || waterFlowStore !== undefined) {
        if (waterFlowStore !== waterWashOverHeadFlow) {
          changes.push({ tab: 'panel-over-head-flow' });
        }
      }

      changes.length > 0 ? submitButtonChange(false) : submitButtonChange(true);
      setChangeReflected(changes);
    }
    return () => {
      setChangeReflected([]);
    }
  }, [
    oneStageConfig,
    oneStageDesalter,
    selectedPreflashConfigText,
    pipeInsideDiameter,
    pipeInsideDiameterUnit,
    branchNumber,
    isWaterWashUsed,
    washInjectionPoint,
    orientedConcurrent,
    orientedCounterCurrent,
    other,
    continuousWashFrequency,
    beforeWashInjection,
    firstStageNeutralizer,
    waterWashOverHeadFlow
  ]);

  useEffect(() => {  
    if (confirmationBoxOpen) {
      (comments && startDate && endDate) ? changeSubmitButtonVisibility(false) : changeSubmitButtonVisibility(true);
    }
  }, [startDate, endDate, comments]);

  const changeSubmitButtonVisibility =(isDisabled:boolean)=>{
    let btnDisable = [...ctaDataForConfirmation];
    btnDisable.forEach((item) => {
      if (item.label === 'Submit') {
        item.isDisabled = isDisabled;
      }
    });
    setCtaDataForConfirmation(btnDisable);
  }

  const submitButtonChange = (status: boolean, label?:string) => {
    let btnDisable = [...ctaData];
    btnDisable.forEach((item) => {
      if(item.key === 'btnSave'){
        item.isDisabled = status;
        if(label) item.label=label;
      }
    });
    setCtaData(btnDisable);
  };

  const setRadioButtonValue = (event: any) => {
    if (!event.currentTarget.isDisabled) {
      switch (event.currentTarget.value) {
        case 'onestage-overhead':
          setOneStageConfig(true);
          setwashInjectionPoint(true);
          setFirstStageNeutralizer(true);
          break;
        case 'twostage-overhead':
          setOneStageConfig(false);
          break;
        case 'onestage-desalter':
          setOneStageDesalter(true);
          break;
        case 'twostage-desalter':
          setOneStageDesalter(false);
          break;
        case 'waterwash-yes':
          setIsWaterWashUsed(true);
          if (!oneStageConfig) {
            setFirstStageNeutralizer(washInjectionPoint);
          }
          break;
        case 'waterwash-no':
          setIsWaterWashUsed(false);
          break;
        case 'washinjection-first':
          setwashInjectionPoint(true);
          setFirstStageNeutralizer(true);
          break;
        case 'washinjection-second':
          setwashInjectionPoint(false);
          setFirstStageNeutralizer(false);
          break;
        case 'concurrent':
          setOrientedConcurrent(true);
          setOrientedCounterCurrent(false);
          setOther(false);
          break;
        case 'countercurrent':
          setOrientedCounterCurrent(true);
          setOrientedConcurrent(false);
          setOther(false);
          break;
        case 'other':
          setOther(true);
          setOrientedCounterCurrent(false);
          setOrientedConcurrent(false);
          break;
        case 'continuous':
          setContinuousWashFrequency(true);
          break;
        case 'intermittent':
          setContinuousWashFrequency(false);
          break;
        case 'beforewash':
          setBeforeWashInjection(true);
          if (washInjectionPoint) setFirstStageNeutralizer(true);
          break;
        case 'afterwash':
          setBeforeWashInjection(false);
          if (!washInjectionPoint) setFirstStageNeutralizer(false);
          break;
        case 'neutr-firststage':
          setFirstStageNeutralizer(true);
          break;
        case 'neutr-secondstage':
          setFirstStageNeutralizer(false);
          break;
        case 'desaltedCrudeBS':
          setWaterWashOverHeadFlow(false);
          break;
        case 'overheadWaterFlow':
          setWaterWashOverHeadFlow(true);
          break;
      }
    }
  };

  const bindPreflashDropdown = () => {
    const preflashConfig = [
      {
        value: 'No Preflash',
        label: 'No Preflash',
        id: 1,
      },
      {
        value: 'Preflash vapors feed atmospheric tower',
        label: 'Preflash vapors feed atmospheric tower',
        id: 2,
      },
      {
        value: 'Preflash vapors feed atmospheric tower overhead line',
        label: 'Preflash vapors feed atmospheric tower overhead line',
        id: 3,
      },
      {
        value: 'Preflash vapors leave crude unit',
        label: 'Preflash vapors leave crude unit',
        id: 4,
      },
      {
        value: 'Prefractionation tower',
        label: 'Prefractionation tower',
        id: 5,
      },
    ];
    setPreflashDroDownValues(preflashConfig);
    let preflashItems = [];
    preflashItems.push({ items: preflashConfig });
    let preflashItemGroups = { itemGroups: preflashItems };
    setPreflashData(preflashItemGroups);
  };

  const handleAccordionChange = (event: React.SyntheticEvent, expanded: boolean, panelId: string) => {
    if (panelId === 'process-preflash') {
      setProcessParamAcc((expanded) => !expanded);
      if (!expanded) {
        setVelocityParamAcc(false);
        setWaterwashParamAcc(false);
        setNeutralizerParamAcc(false);
      }
    }
    if (panelId === 'panel-velocity') {
      setVelocityParamAcc((expanded) => !expanded);
      if (!expanded) {
        setProcessParamAcc(false);
        setWaterwashParamAcc(false);
        setNeutralizerParamAcc(false);
        setOverHeadWaterFlowAcc(false);
      }
    }
    if (panelId === 'panel-waterwash') {
      setWaterwashParamAcc((expanded) => !expanded);
      if (!expanded) {
        setProcessParamAcc(false);
        setVelocityParamAcc(false);
        setNeutralizerParamAcc(false);
        setOverHeadWaterFlowAcc(false);
      }
    }
    if (panelId === 'panel-neutralizer') {
      setNeutralizerParamAcc((expanded) => !expanded);
      if (!expanded) {
        setProcessParamAcc(false);
        setVelocityParamAcc(false);
        setWaterwashParamAcc(false);
        setOverHeadWaterFlowAcc(false);
      }
    }
    if (panelId === 'panel-over-head-flow') {
      setOverHeadWaterFlowAcc((expanded) => !expanded);
      if (!expanded) {
        setProcessParamAcc(false);
        setVelocityParamAcc(false);
        setWaterwashParamAcc(false);
      }
    }
  };
  const bindSmeConfig = () => {
    if (
      configDataFromStore &&
      configDataFromStore.facility &&
      configDataFromStore.facility.refinery &&
      configDataFromStore.facility.refinery.unit &&
      configDataFromStore.facility.refinery.unit[0] &&
      configDataFromStore.facility.refinery.unit[0].smeConfig &&
      Object.keys(configDataFromStore.facility.refinery.unit[0].smeConfig).length
    ) {
      setFlooding(configDataFromStore.facility.refinery.unit[0].smeConfig.isFlooding);
      setVelocityRiskAdjust(configDataFromStore.facility.refinery.unit[0].smeConfig.velocityRiskAdjust);
      setAmmoniaFactor(configDataFromStore.facility.refinery.unit[0].smeConfig.ammoniaAdjustmentFactor);
      setLowerPH(configDataFromStore.facility.refinery.unit[0].smeConfig.enableLowerPH);
      setTriggerPH(configDataFromStore.facility.refinery.unit[0].smeConfig.triggerPH);
      setTubeSkinApproach(configDataFromStore.facility.refinery.unit[0].smeConfig.tubeSkinApproach);
      setRefluxRecycleFrac(configDataFromStore.facility.refinery.unit[0].smeConfig.refluxRecycleFrac);
      setCycleUpCalc(configDataFromStore.facility.refinery.unit[0].smeConfig.isCycleUpCalc);
      let categoryInputMapping = JSON.parse(localStorage.getItem('categoryInputMapping')!);
      //to show the temperature symbol in tube skin approach
      if (categoryInputMapping && Object.keys(categoryInputMapping).length) {
        if (Object.keys(categoryInputMapping.Temperature).length) {
          categoryInputMapping.Temperature.forEach((tempItem: any) => {
            if (tempItem.input === 'Desalter Temperature') {
              let label = 'Tube Skin Approach (°' + tempItem.unitSymbol + ')';
              setTubeSkinApproachLabel(label);
            }
          });
        }
      }
    }
  };
  const bindConfigData = () => {
    if (
      configDataFromStore &&
      configDataFromStore.facility &&
      configDataFromStore.facility.refinery &&
      configDataFromStore.facility.refinery.unit &&
      configDataFromStore.facility.refinery.unit[0]
    ) {
      //preflash config
      bindPreflashDropdown();
      setSelectedPreflashConfig(configDataFromStore.facility.refinery.unit[0].subUnit[0].typeSubunit.toLowerCase() === 'prefractionator overhead' || configDataFromStore.facility.refinery.unit[0].subUnit[0].typeSubunit.toLowerCase() === 'main fractionator overhead' ?
      'No Preflash' : configDataFromStore.facility.refinery.unit[0].preflashPrefractionator);
      preflashDroDownValues &&
        preflashDroDownValues.length > 0 &&
        setSelectedPreflashConfigText(configDataFromStore.facility.refinery.unit[0].subUnit[0].typeSubunit.toLowerCase() === 'prefractionator overhead' || configDataFromStore.facility.refinery.unit[0].subUnit[0].typeSubunit.toLowerCase() === 'main fractionator overhead' ?
        'No Preflash':
        preflashDroDownValues &&
        preflashDroDownValues.length > 0 && configDataFromStore.facility.refinery.unit[0].preflashPrefractionator &&
          preflashDroDownValues.filter(
            (item: any) => item.value.toLowerCase() === configDataFromStore.facility.refinery.unit[0].preflashPrefractionator.toLowerCase()
          )[0].label
        );

      if (configDataFromStore.facility.refinery.unit[0].subUnit && configDataFromStore.facility.refinery.unit[0].subUnit[0]) {
        //overhead configuration
        let overheadCondensingStages = configDataFromStore.facility.refinery.unit[0].subUnit[0].numOvhdStages;
        overheadCondensingStages && overheadCondensingStages === 1 ? setOneStageConfig(true) : setOneStageConfig(false);

        //desalter
        let desalter = configDataFromStore.facility.refinery.unit[0].subUnit[0].numDesalterStages;
        desalter && desalter === 1 ? setOneStageDesalter(true) : setOneStageDesalter(false);

        //pipe inside diameter
        setPipeInsideDiameter(configDataFromStore.facility.refinery.unit[0].subUnit[0].idOvhdStream);
        setPipeInsideDiameterUnit(configDataFromStore.facility.refinery.unit[0].subUnit[0].idOvhdStreamUom);

        //number of branches
        setbranchNumber(configDataFromStore.facility.refinery.unit[0].subUnit[0].numBranchesOvhdStream);

        //water wash used
        configDataFromStore.facility.refinery.unit[0].subUnit[0].waterWash ? setIsWaterWashUsed(true) : setIsWaterWashUsed(false);

        //wash injection point
        configDataFromStore.facility.refinery.unit[0].subUnit[0].stageOvhdWashStream === '1' ||
        configDataFromStore.facility.refinery.unit[0].subUnit[0].stageOvhdWashStream === 1
          ? setwashInjectionPoint(true)
          : setwashInjectionPoint(false);

        //wash injection equipment
        configDataFromStore.facility.refinery.unit[0].subUnit[0].equipmentOvhdWashStream === 'Spray nozzle oriented cocurrent'
          ? setOrientedConcurrent(true)
          : setOrientedConcurrent(false);
        configDataFromStore.facility.refinery.unit[0].subUnit[0].equipmentOvhdWashStream === 'Other' ? setOther(true) : setOther(false);
        configDataFromStore.facility.refinery.unit[0].subUnit[0].equipmentOvhdWashStream === 'Spray nozzle oriented countercurrent'
          ? setOrientedCounterCurrent(true)
          : setOrientedCounterCurrent(false);

        //wash frequency
        configDataFromStore.facility.refinery.unit[0].subUnit[0].washFrequency === 'Continuous'
          ? setContinuousWashFrequency(true)
          : setContinuousWashFrequency(false);

        //Neutralizer injection point
        configDataFromStore.facility.refinery.unit[0].subUnit[0].stageOvhdNeutralizerStream === 1 ||
        configDataFromStore.facility.refinery.unit[0].subUnit[0].stageOvhdNeutralizerStream === '1'
          ? setFirstStageNeutralizer(true)
          : setFirstStageNeutralizer(false);

        //Neutralizer injection stage
        configDataFromStore.facility.refinery.unit[0].subUnit[0].injectedNeutralizerStream && configDataFromStore.facility.refinery.unit[0].subUnit[0].injectedNeutralizerStream.toLowerCase() === 'with or after wash water'
          ? setBeforeWashInjection(false)
          : setBeforeWashInjection(true);

        //set config type
        setConfigType(configDataFromStore.facility.refinery.unit[0].subUnit[0].configType);

        //set overhead water flow
        setWaterWashOverHeadFlow(configDataFromStore.facility.refinery.unit[0].subUnit[0].isOvhdWaterFlowMetersEnabled);

        //spray model
        if(configDataFromStore.facility.refinery.unit[0].sprayModelConfig) {
          setEnableSprayModel(configDataFromStore.facility.refinery.unit[0].sprayModelConfig.enableSpraymodel)
          setSprayNozzle(configDataFromStore.facility.refinery.unit[0].sprayModelConfig.sprayNozzleId)
          setSprayPipeInsideDiameter(configDataFromStore.facility.refinery.unit[0].sprayModelConfig.pipeInsideDiameter ? configDataFromStore.facility.refinery.unit[0].sprayModelConfig.pipeInsideDiameter :
              configDataFromStore.facility.refinery.unit[0].subUnit[0].idOvhdStream);
          setSprayPipeInsideDiameterLength(configDataFromStore.facility.refinery.unit[0].sprayModelConfig.pipeLength);
          setSprayPipeInsideDiameterUnit(configDataFromStore.facility.refinery.unit[0].sprayModelConfig.pipeInsideUnit ? configDataFromStore.facility.refinery.unit[0].sprayModelConfig.pipeInsideUnit :
              configDataFromStore.facility.refinery.unit[0].subUnit[0].idOvhdStreamUom);
          setSprayPipeInsideDiameterLengthUnit(configDataFromStore.facility.refinery.unit[0].sprayModelConfig.pipelengthUnit);
          setBranchNumberForSpray(configDataFromStore.facility.refinery.unit[0].sprayModelConfig.numberOfbranches ? configDataFromStore.facility.refinery.unit[0].sprayModelConfig.numberOfbranches :
              configDataFromStore.facility.refinery.unit[0].subUnit[0].numBranchesOvhdStream);
        }
      }
    }
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const areaControlProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  const MainTabPanel = useCallback((props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} id={`main-tabpanel-${index}`} aria-labelledby={`main-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }, []);

  const tabAreaControlProps = (index: number) => {
    return {
      id: `main-tab-${index}`,
      'aria-controls': `main-tabpanel-${index}`,
    };
  };

  const handleEnableChange =(event :any)=> {
    setEnableSprayModel(event.detail);
  }

  useEffect(() => {
    setValidateItems({sprayPipe : false, sprayPipeLength: false, sprayNozzleVal : false, PipeLengthUnit: false})
    let firstDiv = document.getElementById('config-modal');
    if (firstDiv) {
      if (changeReflected.length > 0) {
        let forDisplay: any = document.getElementsByClassName('bh-modal__footer--cta');
        if (forDisplay.length) {
          forDisplay[0].style.display = 'block';
          forDisplay[0].style.width = '100%';
          forDisplay[0].style.pointerEvents = 'none';
        }
      } else {
        setTimeout(() => {
        let forHide: any = document.getElementsByClassName('bh-modal__footer--cta');
        if (forHide.length && forHide[0]) {
          forHide[0].style.display = 'none';
        }
      },1);
      }
    }
  }, [isConfigOpen, changeReflected]);

  useEffect(() => {
    checkUserAdminOrSme();
    let ctaArray = [];
    ctaArray.push(
      {
        type: 'ghost',
        label: '* You have made changes in the default values',
        key: 'changes',
      },
      {
        type: 'ghost',
        label: 'Cancel',
        key: 'btnCancel',
      },
      { type: 'primary', label: ((!localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false') && !isAdminOrSme && isGeneralTabOpen ? 'Submit change request':'Save changes'), key: 'btnSave'
      , isDisabled: ((!localStorage.getItem('isSandbox') || localStorage.getItem('isSandbox') === 'false') && !isAdminOrSme && isGeneralTabOpen) ? true : false}
    );
    setCtaData(ctaArray);
    (comments && startDate && endDate) ? changeSubmitButtonVisibility(false) : changeSubmitButtonVisibility(true);
    let diameterUnits = [
      {
        label: 'Inches',
        value: 'inches',
      },
      {
        label: 'Millimeters',
        value: 'mm',
      },
    ];
    let velocityUnit = [
      {
        label: 'ft',
        value: 'ft',
      },
      {
        label: 'm',
        value: 'm',
      },
    ];
    setSprayPipeInsideDiameterLengthUnits(velocityUnit);
    setPipeInsideDiameterUnits(diameterUnits);
    bindConfigData();
    if (
      localStorage.getItem('salt') &&
      Object.keys(localStorage.getItem('salt')!).length &&
      decryptData(localStorage.getItem('salt')) &&
      decryptData(localStorage.getItem('salt')).userRole &&
      (decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'sme' ||
        decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'admin') &&
      decryptData(localStorage.getItem('salt')).userActiveStatus
    ) {
      setAdvTabVisible(true);
      bindSmeConfig();
    } else {
      setAdvTabVisible(false);
    }
    //set error and success flags to false in component unmount
    return () => {
      setSuccessFlag(false);
      setErrorFlag(false);
      (unitType !=='coker' && unitType !=='fcc' && unitType !=='hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') && setVelocityParamAcc(false);
      setWaterwashParamAcc(false);
      setNeutralizerParamAcc(false);
      setOverHeadWaterFlowAcc(false);
      setGeneralTabOpen(true);
      setComments('');
      setMainTabValue(0);
    };
  }, [props]);

  const restoreData = () => {
    getConfigData(function() {
      bindConfigData();
    });
  };

  const closeConfirmation = (e: any) => {
    setConfirmationBoxOpen(false);
    setComments('');
    setCommentError(false);
    setInvalidStartDate(false);
    setInvalidEndDate(false);
    setEndDateBeyondYear(false);
  };

  const selectedDateClick = (event: CustomEvent) => {
    let startDate =
      event.detail && event.detail.start && event.detail.start !== '' && event.detail.start !== 'aN-undefined-NaN'
        ? changeToShortFormat(new Date(event.detail.start))
        : '';
    let endDate =
      event.detail && event.detail.end && event.detail.end !== '' && event.detail.end !== 'aN-undefined-NaN'
        ? changeToShortFormat(new Date(event.detail.end))
        : '';
    if (startDate && endDate && startDate !== '' && startDate !== 'aN-undefined-NaN' && endDate !== '' && endDate !== 'aN-undefined-NaN') {
      //date validations start date shouldnt be past date, end date cannot be today's date, end date can be maximum upto 1 year
      let today= new Date();
      let endDateFormat= new Date(endDate);
      let startDateFormat=new Date(startDate);
      let year = today.getFullYear();
      let month = today.getMonth();
      let day = today.getDate();
      let oneYearAfter = new Date(year + 1, month, day);
      if(startDateFormat.setHours(0,0,0,0) < today.setHours(0,0,0,0)){
       setInvalidStartDate(true);
       setInvalidEndDate(false);
       setEndDateBeyondYear(false);
      }
      else if((endDateFormat.setHours(0,0,0,0) <= today.setHours(0,0,0,0)) || (endDateFormat.setHours(0,0,0,0) === startDateFormat.setHours(0,0,0,0))){
        setInvalidStartDate(false);
        setInvalidEndDate(true);
        setEndDateBeyondYear(false);
      }
      else if(endDateFormat.setHours(0,0,0,0) > oneYearAfter.setHours(0,0,0,0)){
        setInvalidStartDate(false);
        setInvalidEndDate(false);
        setEndDateBeyondYear(true);
      }
      else
      {
        setInvalidStartDate(false);
        setInvalidEndDate(false);
        setEndDateBeyondYear(false);
        setStartDate(startDate);
        setEndDate(endDate);
      } 
    }
  };

  const commentsChange = (e: CustomEvent) => {
    if(e.detail) {
      setComments(e.detail);
      setCommentError(false);
    }
    else {
      setComments('');
      setCommentError(true);
    }
  };

  const checkDisable =()=> {
    if(isAdminOrSme && decryptData(localStorage.getItem('salt')).userRole.toLowerCase() === 'sme') {
      return true;
    }
    else {
      return false;
    }
  }

  const handleSelectedCustomer =(event :any)=> {
      const dropDownValidation = {
        ...validateItems
      }
        if (!event) {
            dropDownValidation.sprayNozzleVal = true;
        } else {
            dropDownValidation.sprayNozzleVal = false;
        }
        setSprayNozzle(event?.value);
        setValidateItems(dropDownValidation)
  }

  const getColor =()=> {
    const tabVal = {...validateItems}
    if(isAdminOrSme) {
      if(mainTabValue !== 3) {
        if(Object.values(tabVal).includes(true)) {
          return "#df8892";
        }
      }
    }
  }

  return (
    <React.Fragment>
      {isConfigOpen ? (
        <BhModal
          isOpen={isConfigOpen}
          header="Edit configuration"
          ctas={ctaData}
          onBhEventCtaClick={(event: any) => {
            handleCtaClick(event);
          }}
          onBhEventClose={(e) => closeConfigModal(e)}
          //isDismissible={false}
          width="large"
          id="config-modal"
          style={{zIndex:40000}}
        >
          <div>
            <Tabs value={mainTabValue} onChange={handleMainTabChange} aria-label="edit-config-tabs" id="edit-config-tab">
              <Tab label="General" key="General" value={0} {...tabAreaControlProps(0)} />
              <Tab label="Units of measure" key="uom" value={1} {...tabAreaControlProps(1)} />
              {isAdvTabVisible === false ? '' : <Tab label="Advanced" key="advanced" value={2} {...tabAreaControlProps(2)} />}
              {isAdvTabVisible === false ? '' : <Tab style={{color: getColor()}} label="Spray Model" key="spray-model" value={3} {...tabAreaControlProps(3)} />}
            </Tabs>
            <BhDivider marginTop="xxxsmall" marginBottom="xxsmall"></BhDivider>
          </div>
          <div>
            <MainTabPanel value={mainTabValue} index={0}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '50%', marginRight: '20px', height: '500px', overflowY: 'scroll', marginTop: '20px' }}>
                {changeReflected.length > 0 && (
                  <div
                    onKeyDown={() => restoreData()}
                    onClick={() => restoreData()}
                    className='restoreDiv'
                  >
                    <div className='historyIcon'>
                      <HistoryIcon />
                    </div>
                    <div className='restoreLabel'>Restore to previous value</div>
                  </div>
                )}
                {(unitType !== 'coker' && unitType !== 'fcc' && unitType !=='hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') && 
                <>
                  <Accordion
                    elevation={0}
                    classes={{
                      root: classes.MuiAccordionroot,
                    }}
                    id="process-preflash"
                    expanded={processParamAcc}
                    onChange={(event: any) => {
                      handleAccordionChange(event, processParamAcc, 'process-preflash');
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                      <Typography
                        className={
                          changeReflected.find((item: any) => item.tab === 'process-preflash') ? 'value-changed' : 'process-acc-header'
                        }
                      >
                        Process and preflash{changeReflected.find((item: any) => item.tab === 'process-preflash') ? '*' : ''}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <BhTitleWrapper className="textSize_Adj" header="Overhead configuration"></BhTitleWrapper>
                      <BhSelectionGroup layout="horizontal">
                        <BhRadioButton
                          label="One stage"
                          value="onestage-overhead"
                          name="group1"
                          isChecked={oneStageConfig}
                          onClick={(event: any) => setRadioButtonValue(event)}
                        ></BhRadioButton>
                        <BhRadioButton
                          label="Two stage"
                          value="twostage-overhead"
                          name="group1"
                          isChecked={!oneStageConfig}
                          onClick={(event: any) => setRadioButtonValue(event)}
                        ></BhRadioButton>
                      </BhSelectionGroup>
                      {configType !== '1C' && configType !== '2C' && (
                        <>
                          <BhTitleWrapper className="textSize_Adj" header="Desalter"></BhTitleWrapper>
                          <BhSelectionGroup layout="horizontal">
                            <BhRadioButton
                              label="One stage"
                              value="onestage-desalter"
                              name="group2"
                              isChecked={oneStageDesalter}
                              onClick={(event: any) => setRadioButtonValue(event)}
                            ></BhRadioButton>
                            <BhRadioButton
                              label="Two stage"
                              value="twostage-desalter"
                              name="group2"
                              isChecked={!oneStageDesalter}
                              onClick={(event: any) => setRadioButtonValue(event)}
                            ></BhRadioButton>
                          </BhSelectionGroup>
                        </>
                      )}
                      <BhTitleWrapper className="textSize_Adj" header="Preflash configuration">
                        <span className="preflash-asterisk">*</span>
                      </BhTitleWrapper>
                      <BhDropdown
                        class="wd-240"
                        selectedValue={true}
                        value={selectedPreflashConfigText}
                        menuItems={JSON.stringify(preflashData)}
                        placeholder="No Preflash"
                        isRequired
                        id="preflash-config"
                        isDisabled={configDataFromStore.facility.refinery.unit[0].subUnit[0].typeSubunit.toLowerCase() === 'prefractionator overhead' || configDataFromStore.facility.refinery.unit[0].subUnit[0].typeSubunit.toLowerCase() === 'main fractionator overhead' ? true:false}
                        onBhEventChange={(event: any) => handleDropDownChange(event)}
                      ></BhDropdown>
                    </AccordionDetails>
                  </Accordion>
                  <BhDivider marginTop="xxxsmall" marginBottom="xxsmall"></BhDivider>
                  </>
                  }
                  <Accordion
                    elevation={0}
                    classes={{
                      root: classes.MuiAccordionroot,
                    }}
                    id="panel-velocity"
                    expanded={velocityParamAcc}
                    onChange={(event: any) => {
                      handleAccordionChange(event, velocityParamAcc, 'panel-velocity');
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                      <Typography
                        className={
                          changeReflected.find((item: any) => item.tab === 'panel-velocity') ? 'value-changed' : 'process-acc-header'
                        }
                      >
                        Velocity parameters{changeReflected.find((item: any) => item.tab === 'panel-velocity') ? '*' : ''}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="velocity-config">
                        <p className="diameter-desc">
                          Enter the pipe diameter in inches and the number of parallel branches for the most constricting point. If there
                          are parallel branches of different pipe diameters call technology for assistance
                        </p>
                      </div>

                      <div className="pipe-diameter-config">
                        <BhTextInput
                          id="pipe-diameter-txt"
                          type="text"
                          label="Pipe inside diameter"
                          value={pipeInsideDiameter}
                          isSmallWidth
                          class="wd-140"
                          onBhEventChange={(event) => {
                            handleInputChange(event);
                          }}
                        ></BhTextInput>
                        <BhDropdown
                          class="velocity-dropdown-unit"
                          label="Unit"
                          menuItems={JSON.stringify(pipeInsideDiameterUnits)}
                          value={
                            pipeInsideDiameterUnit &&
                            pipeInsideDiameterUnits &&
                            pipeInsideDiameterUnits.length &&
                            pipeInsideDiameterUnits.filter((m: any) => m.value === pipeInsideDiameterUnit)[0].label
                          }
                          id="pipe-diameter-unit"
                          onBhEventChange={(event: any) => handleDropDownChange(event)}
                        ></BhDropdown>
                        <BhIncrementer
                          label="Number of branches"
                          value={branchNumber}
                          class="wd-240"
                          id="inc-branch"
                          onBhEventInput={(event: any) => {
                            handleIncrementChange(event);
                          }}
                        ></BhIncrementer>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <BhDivider marginTop="xxxsmall" marginBottom="xxsmall"></BhDivider>
                  <Accordion
                    elevation={0}
                    classes={{
                      root: classes.MuiAccordionroot,
                    }}
                    id="panel-waterwash"
                    expanded={waterwashParamAcc}
                    onChange={(event) => {
                      handleAccordionChange(event, waterwashParamAcc, 'panel-waterwash');
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                      <Typography
                        className={
                          changeReflected.find((item: any) => item.tab === 'panel-waterwash') ? 'value-changed' : 'process-acc-header'
                        }
                      >
                        Water wash{changeReflected.find((item: any) => item.tab === 'panel-waterwash') ? '*' : ''}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <BhTitleWrapper className="textSize_Adj" header="Is water wash used?"></BhTitleWrapper>
                      <BhSelectionGroup layout="horizontal">
                        <BhRadioButton
                          label="Yes"
                          value="waterwash-yes"
                          name="group4"
                          isChecked={isWaterWashUsed}
                          onClick={(event: any) => setRadioButtonValue(event)}
                        ></BhRadioButton>
                        <BhRadioButton
                          label="No"
                          value="waterwash-no"
                          name="group4"
                          isChecked={!isWaterWashUsed}
                          onClick={(event: any) => setRadioButtonValue(event)}
                        ></BhRadioButton>
                      </BhSelectionGroup>
                      <BhTitleWrapper className="textSize_Adj" header="Wash injection point:"></BhTitleWrapper>
                      <BhSelectionGroup layout="horizontal">
                        <BhRadioButton
                          label="First Stage"
                          value="washinjection-first"
                          name="group5"
                          isChecked={washInjectionPoint}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={oneStageConfig || !isWaterWashUsed}
                        ></BhRadioButton>
                        <BhRadioButton
                          label="Second Stage"
                          value="washinjection-second"
                          name="group5"
                          isChecked={!washInjectionPoint}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={oneStageConfig || !isWaterWashUsed}
                        ></BhRadioButton>
                      </BhSelectionGroup>
                      <BhTitleWrapper className="textSize_Adj" header="Injection equipment used (Spray nozzle) :"></BhTitleWrapper>
                      <BhSelectionGroup layout="horizontal">
                        <BhRadioButton
                          label="Oriented cocurrent"
                          value="concurrent"
                          name="group6"
                          isChecked={orientedConcurrent}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={!isWaterWashUsed}
                        ></BhRadioButton>
                        <BhRadioButton
                          label="Oriented countercurrent"
                          value="countercurrent"
                          name="group6"
                          isChecked={orientedCounterCurrent}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={!isWaterWashUsed}
                        ></BhRadioButton>
                        <BhRadioButton
                          label="Other"
                          value="other"
                          name="group6"
                          isChecked={other}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={!isWaterWashUsed}
                        ></BhRadioButton>
                      </BhSelectionGroup>
                      <BhTitleWrapper className="textSize_Adj" header="Wash frequency:"></BhTitleWrapper>
                      <BhSelectionGroup layout="horizontal">
                        <BhRadioButton
                          label="Continuous"
                          value="continuous"
                          name="group7"
                          isChecked={continuousWashFrequency}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={!isWaterWashUsed}
                        ></BhRadioButton>
                        <BhRadioButton
                          label="Intermittent"
                          value="intermittent"
                          name="group7"
                          isChecked={!continuousWashFrequency}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={!isWaterWashUsed}
                        ></BhRadioButton>
                      </BhSelectionGroup>
                    </AccordionDetails>
                  </Accordion>
                  <BhDivider marginTop="xxxsmall" marginBottom="xxsmall"></BhDivider>
                  {(unitType !== 'coker' && unitType !== 'fcc' && unitType !=='hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') && 
                 <>
                  <Accordion
                    elevation={0}
                    classes={{
                      root: classes.MuiAccordionroot,
                    }}
                    id="panel-neutralizer"
                    expanded={neutralizerParamAcc}
                    onChange={(event) => {
                      handleAccordionChange(event, neutralizerParamAcc, 'panel-neutralizer');
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4-content" id="panel4-header">
                      <Typography
                        className={
                          changeReflected.find((item: any) => item.tab === 'panel-neutralizer') ? 'value-changed' : 'process-acc-header'
                        }
                      >
                        Neutralizer{changeReflected.find((item: any) => item.tab === 'panel-neutralizer') ? '*' : ''}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <BhTitleWrapper className="textSize_Adj" header="Neutralizer injection point:"></BhTitleWrapper>
                      <BhSelectionGroup layout="horizontal">
                        <BhRadioButton
                          label="Before Water Wash"
                          value="beforewash"
                          name="group8"
                          isChecked={beforeWashInjection}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={!isWaterWashUsed}
                        ></BhRadioButton>
                        <BhRadioButton
                          label="In or After Water Wash"
                          value="afterwash"
                          name="group8"
                          isChecked={!beforeWashInjection}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={!isWaterWashUsed}
                        ></BhRadioButton>
                      </BhSelectionGroup>
                      <BhTitleWrapper className="textSize_Adj" header="Neutralizer injected at stage:"></BhTitleWrapper>
                      <BhSelectionGroup layout="horizontal" isDisabled={oneStageConfig}>
                        <BhRadioButton
                          label="First Stage"
                          value="neutr-firststage"
                          name="group9"
                          isChecked={firstStageNeutralizer}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={oneStageConfig || washInjectionPoint || (!oneStageConfig && isWaterWashUsed && !beforeWashInjection) || (!isWaterWashUsed && !washInjectionPoint)}
                        ></BhRadioButton>
                        <BhRadioButton
                          label="Second Stage"
                          value="neutr-secondstage"
                          name="group9"
                          isChecked={!firstStageNeutralizer}
                          onClick={(event: any) => setRadioButtonValue(event)}
                          isDisabled={oneStageConfig || washInjectionPoint || (!oneStageConfig && isWaterWashUsed && !beforeWashInjection) || (!isWaterWashUsed && !washInjectionPoint)}
                        ></BhRadioButton>
                      </BhSelectionGroup>
                    </AccordionDetails>
                  </Accordion>
                  <BhDivider marginTop="xxxsmall" marginBottom="xxxsmall"></BhDivider>
                  </>
                  }
        
                {(unitType !== 'coker' && unitType !== 'fcc' && unitType !=='hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') && 
                <>
                <BhDivider marginTop="xxxsmall" marginBottom="xxxsmall"></BhDivider>
                  <Accordion
                    elevation={0}
                    classes={{
                      root: classes.MuiAccordionroot,
                    }}
                    id="panel-over-head-flow"
                    expanded={overHeadWaterFlowAcc}
                    onChange={(event) => {
                      handleAccordionChange(event, overHeadWaterFlowAcc, 'panel-over-head-flow');
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5-content" id="panel5-header">
                      <Typography
                        className={
                          changeReflected.find((item: any) => item.tab === 'panel-over-head-flow') ? 'value-changed' : 'process-acc-header'
                        }
                      >
                        Water flow {changeReflected.find((item: any) => item.tab === 'panel-over-head-flow') ? '*' : ''}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <BhTitleWrapper className="textSize_Adj" header="How is overhead water flow specified?"></BhTitleWrapper>
                      <BhSelectionGroup layout="horizontal">
                        <BhRadioButton
                          label="Desalted Crude BS&W Flow + Total Steam Flow"
                          value="desaltedCrudeBS"
                          name="group10"
                          isChecked={!waterWashOverHeadFlow}
                          onClick={(event: any) => setRadioButtonValue(event)}
                        ></BhRadioButton>
                        <BhRadioButton
                          label="Overhead Water Flow Meters"
                          value="overheadWaterFlow"
                          name="group10"
                          isChecked={waterWashOverHeadFlow}
                          onClick={(event: any) => setRadioButtonValue(event)}
                        ></BhRadioButton>
                      </BhSelectionGroup>
                    </AccordionDetails>
                  </Accordion>
                  <BhDivider marginTop="xxxsmall" marginBottom="xxxsmall"></BhDivider>
                </>
                }
                </div>
                <div style={{ width: '50%', marginLeft: '20px', marginTop: '20px' }}>
                  <BhCard class="preview-frame">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '75px',
                      }}
                    >
                      <BhTitleWrapper type="label" header="Configuration preview"></BhTitleWrapper>
                      <ConfigurationPreview type={(unitType === 'coker' || unitType === 'fcc' || unitType ==='hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') ? '1A': configType} isFromConfig={true} idName="stage-previewEdit" unitType={unitType}></ConfigurationPreview>
                    </div>
                  </BhCard>
                </div>
              </div>
            </MainTabPanel>
            <MainTabPanel value={mainTabValue} index={1}>
              <div>
                <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 200 }}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    id="uom-subcategory-tab"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                  >
                    {uomCategoryNames.map((categoryName: any, index: any) => (
                      <Tab key={index} label={categoryName} {...areaControlProps(index)} />
                    ))}
                  </Tabs>
                  <div className="uom-tab-container">
                    {uomApiResponse &&
                    uomApiResponse.uomCategory &&
                    Object.keys(uomApiResponse).length &&
                    Object.keys(uomApiResponse.uomCategory).length &&
                    uomApiResponse.uomCategory.length ? (
                      uomApiResponse.uomCategory.map((categoryItem: any, index: any) => (
                        <TabPanel value={value} index={index} key={index}>
                          <div className="sub-cat-wrapper">
                            {categoryItem.uomSubCategory.map((subCategoryItem: any) =>
                              createSubCategoryDropDowns(subCategoryItem, categoryItem.categoryId)
                            )}
                          </div>
                        </TabPanel>
                      ))
                    ) : (
                      <div className="loader-div">
                        <BhSpinner size="medium"></BhSpinner>
                      </div>
                    )}
                  </div>
                </Box>
              </div>
            </MainTabPanel>
            <MainTabPanel value={mainTabValue} index={2}>
              <div className="advanced-wrapper">
                <div>
                  <BhTitleWrapper className="textSize_Adj" header="Tower Flooding"></BhTitleWrapper>
                  <BhCheckbox
                    label="Overhead Liquid Entrainment"
                    isChecked={isFlooding}
                    id="overheadLiquidCheckbox"
                    onBhEventChange={(event) => handleCheckboxChange(event)}
                  ></BhCheckbox>
                </div>
                {(unitType === 'coker' || unitType === 'fcc' || unitType ==='hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && 
                <div className='pd-1'>
                      <BhTextInput
                      label="Ammonia Adjustment Factor"
                      value={ammoniaFactor}
                      id="ammonia-factor"
                      onKeyPress={(e) => /^[+-]?[0-9]{7}\.[0-9]{2}$/.test(e.key) && e.preventDefault()}
                      onBhEventInput={(event: any) => {
                        handleInputChange(event);
                }}
                    ></BhTextInput>
                </div>}
                {(unitType !== 'coker' && unitType !== 'fcc' && unitType !=='hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') && (
                <div>
                  <BhTitleWrapper className="textSize_Adj" header="Velocity Risk Modification"></BhTitleWrapper>
                  <div className="advanced-velocityrisk">
                    <BhTextInput
                      label="Modification Factor"
                      value={velocityRiskAdjust}
                      id="velocity-risk"
                      onKeyPress={(e) => /^[+-]?[0-9]{7}\.[0-9]{2}$/.test(e.key) && e.preventDefault()}
                      onBhEventInput={(event: any) => {
                        handleInputChange(event);
                      }}
                      isDisabled={(unitType === 'coker' || unitType === 'fcc' || unitType ==='hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') ? true : false}
                    ></BhTextInput>
                    <div className="pd-3">
                      <BhCheckbox
                        label="Lower pH Trigger"
                        isChecked={enableLowerPH}
                        id="lowerPhCheckbox"
                        onBhEventChange={(event) => handleCheckboxChange(event)}
                        isDisabled={(unitType === 'coker' || unitType === 'fcc' || unitType ==='hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') ? true : false}
                      ></BhCheckbox>
                    </div>
                    <BhTextInput
                      value={triggerPH}
                      class="pd-2"
                      id="lower-ph"
                      onKeyPress={(e) => /^[+-]?[0-9]{7}\.[0-9]{2}$/.test(e.key) && e.preventDefault()}
                      isDisabled={!enableLowerPH || ((unitType === 'coker' || unitType === 'fcc' || unitType ==='hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') ? true : false)}
                      onBhEventInput={(event: any) => {
                        handleInputChange(event);
                      }}
                    ></BhTextInput>
                  </div>
                </div>
                )}
                {(unitType !== 'coker' && unitType !== 'fcc' && unitType !=='hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') && (
                <>
                <div>
                  <BhTitleWrapper className="textSize_Adj" header="Hot Drum Salt Approach Basis"></BhTitleWrapper>
                  <div className="pd-1">
                    <BhIncrementer
                      label={tubeSkinApproachLabel}
                      value={tubeSkinApproach}
                      id="tube-skin"
                      minValue={-1000}
                      onBhEventInput={(event: any) => {
                        handleIncrementChange(event);
                      }}
                      isDisabled={(unitType === 'coker' || unitType === 'fcc' || unitType ==='hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') ? true : false}
                    ></BhIncrementer>
                  </div>
                </div>
                <div>
                  <BhTitleWrapper className="textSize_Adj" header="Neutralizer Amine Reflux Recycle"></BhTitleWrapper>
                  <div className="pd-1">
                    <BhTextInput
                      label="Reflux Recycle Fraction"
                      value={refluxRecycleFrac}
                      id="reflux-fr"
                      onKeyPress={(e) => /^[+-]?[0-9]{7}\.[0-9]{2}$/.test(e.key) && e.preventDefault()}
                      onBhEventInput={(event: any) => {
                        handleInputChange(event);
                      }}
                      isDisabled={(unitType === 'coker' || unitType === 'fcc' || unitType ==='hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') ? true : false}
                    ></BhTextInput>
                  </div>
                </div>
                <div>
                  <BhCheckbox
                    label="Reflux Cycle up"
                    isChecked={isCycleUpCalc}
                    id="refluxCycleCheckbox"
                    onBhEventChange={(event) => handleCheckboxChange(event)}
                    isDisabled={(unitType === 'coker' || unitType === 'fcc' || unitType ==='hydrocracker' || unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') ? true : false}
                  ></BhCheckbox>
                </div>
                </>
                )}
              </div>
            </MainTabPanel>
            <MainTabPanel value={mainTabValue} index={3}>
              <div className='spray-model-wrapper'>
                <div>
                <BhTitleWrapper className="textSize_Adj" header="Enable spray model"></BhTitleWrapper>
                </div>

                <div>
                <BhToggle isDisabled={checkDisable()} onBhEventChange={(event:any)=>handleEnableChange(event)}  
                isChecked={isEnableSprayModel} label={isEnableSprayModel ? 'Yes' : 'No'} id='enable-spray' className='enable-spray-btn' ></BhToggle>
                </div>

              <div className='autoDiv'>
              <label className={ isEnableSprayModel ? 'bh-text-input__label typography--label-small bh-text-input__required' 
                : 'bh-text-input__label typography--label-small bh-text-input__required custom-required'}>Spray nozzle</label>
                <Autocomplete
                    disablePortal={false}
                    disabled={!isEnableSprayModel}
                    id="spray-box"
                    className="spray-nozle-unit"
                    options={sprayNozzleList}
                    defaultValue={sprayNozzleList?.find((item :SprayNozzleType)=> item?.value === sprayNozzle)?.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} placeholder="Select spray nozzle" />}
                    onChange={(event, newValue) => handleSelectedCustomer(newValue)}
                  />
                     <label className='autoCompleteInput'>{validateItems.sprayNozzleVal && "This field is mandatory"}</label>
                </div>
                <div className='div-for-pipe-diameter'>
                <div className='div-for-divide-250'>
                  <BhTextInput
                      isDisabled={!isEnableSprayModel}
                      isError={validateItems.sprayPipe}
                      isRequired
                      data-testid="spray-pipe-test"
                      type="text"
                      label='Pipe inside diameter'
                      value={sprayPipeInsideDiameter}
                      onBhEventChange={(event) => {
                        handleInputChange(event);
                      }}
                      id="spray-pipe"
                      class='spray-Pipe'
                      >
                  </BhTextInput>
                </div>
                <div className='div-for-divide'>
                  <BhDropdown
                  isDisabled={!isEnableSprayModel}
                  isRequired
                  class="spray-dropdown-unit"
                  label="Unit"
                  id="spray-unit"
                  onBhEventChange={(event: any) => handleDropDownChangeForSpray(event)}
                  menuItems={JSON.stringify(pipeInsideDiameterUnits)}
                  value={
                    pipeInsideDiameterUnits?.filter((m: any) => m.value === sprayPipeInsideDiameterUnit)[0]?.label
                  }
                  ></BhDropdown>
                </div>
                <div className='div-width-280'>
                <label className={ isEnableSprayModel ? 'bh-text-input__label typography--label-small bh-text-input__required' 
                : 'bh-text-input__label typography--label-small bh-text-input__required custom-required'}>Number of branches</label>
                 <BhIncrementer
                    isDisabled={!isEnableSprayModel}
                    value={branchNumberForSpray}
                    class="wd-240"
                    id="spray-branch"
                    onBhEventInput={(event: any) => {
                    handleIncrementChange(event);
                    }}
                 ></BhIncrementer>
                 
                </div>
                </div>
                <div className='div-for-pipe-length'>
                <div className='div-for-divide-250-length'>
                  <BhTextInput
                  isDisabled={!isEnableSprayModel}
                      isRequired
                      isError={validateItems.sprayPipeLength}
                      data-testid="spray-pipe-length-test"
                      type="text"
                      label='Pipe length'
                      value={sprayPipeInsideDiameterLength}
                      id="spray-pipe-length"
                      class='spray-Pipe-length'
                      onBhEventChange={(event) => {
                        handleInputChange(event);
                      }}
                      >
                  </BhTextInput>
                </div>
                <div className='div-for-divide'>
                  <BhDropdown
                  isDisabled={!isEnableSprayModel}
                   isError={validateItems.PipeLengthUnit}
                   isRequired
                   class="spray-length-dropdown-unit"
                   label="Unit"
                   value={
                    sprayPipeInsideDiameterLengthUnits?.filter((m: any) => m.value === sprayPipeInsideDiameterLengthUnit)[0]?.label
                   }
                   menuItems={JSON.stringify(sprayPipeInsideDiameterLengthUnits)}
                   id="spray-length-unit"
                   onBhEventChange={(event: any) => handleDropDownChangeForSpray(event)}
                  ></BhDropdown>
                </div>
                </div>
              </div>
            </MainTabPanel>
            {confirmationBoxOpen && (
              <BhModal
                onBhEventClose={(e) => closeConfirmation(e)}
                ctas={ctaDataForConfirmation}
                header="Confirmation"
                onBhEventCtaClick={() => handleCtaClick}
                subheader="Please choose start and end date for the changes"
                id="confirmation-modal"
                isOpen={confirmationBoxOpen}
                width='small'
              >
                <div className='confirmation-modal-wrapper'>
                <div>
                  <BhDateRangePicker width="small" onBhEventChange={selectedDateClick} id="confirmationDate" isRequired />
                  <BhFormMessage
                      data-testid="bh-error"
                      isError={isInvalidStartDate || isInvalidEndDate || isEndDateBeyondYear}
                      message={isInvalidStartDate ? 'Start date cannot be past date': (isInvalidEndDate ? 'End date should be future date'
                      : (isEndDateBeyondYear ? 'End date cannot go beyond one year': ''))}
                      id="date-input-error"
                    ></BhFormMessage>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '14px' }}>
                  <div>
                    Comments <span style={{ color: 'red', marginBottom: '5px' }}>*</span>
                  </div>
                  <BhTextArea
                    onChanged={(e) => commentsChange(e)}
                    id="comments"
                    placeholder="Write something..."
                    value={comments}
                    isError={isCommentError}
                  ></BhTextArea>
                  <BhFormMessage isError message={isCommentError ? 'This field is mandatory': ''} id="comment-error"></BhFormMessage>
                </div>
                </div>
              </BhModal>
            )}
          </div>
          <div style={{ marginTop: mainTabValue === 3 ? "-70px" : ""}}>
          {isSuccess ? (
            <Alert icon={<CheckCircleOutlineIcon />} severity="success" className="alert-box-success config-error" id="alert-message">
              {message}
            </Alert>
          ) : isError ? (
            <Alert icon={<SecurityIcon />} severity="error" className="alert-box-error config-error" id="alert-message">
              {message}
            </Alert>
          ) : (
            <Alert hidden id="nodisplay-alert" className="config-error"></Alert>
          )}
        </div>
        </BhModal>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}
