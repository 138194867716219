/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import './SimulationInput.scss';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BhDivider, BhList, BhTitleWrapper } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';

export default function SimulationInput(props: any) {
  const [configType, setConfigType] = useState('');
  const [date, setDate] = useState<string>('');
  const [deslaterTempUOM, setDeslaterTempUOM] = useState<string>('');
  const [isWithDesalter, setWithDesalter] = useState(false);

  useEffect(() => {
    setDate(
      new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).format(new Date(props.ionicInput.inputDate))
    );
    var defaultDataForInput: any;
    let desalterTempUnit =
      props.ionicInput &&
      props.ionicInput.desalter &&
      props.ionicInput.desalter.desalterTemperature_UOM &&
      props.ionicInput.desalter.desalterTemperature_UOM === 'F'
        ? '°F'
        : props.ionicInput.desalter.desalterTemperature_UOM === 'C'
        ? '°C'
        : '';
    setDeslaterTempUOM(desalterTempUnit);
    if (props.configData) {
      defaultDataForInput = props.configData;
    } else if (localStorage.getItem('pageName') === 'All Simulation') {
      defaultDataForInput = localStorage.getItem('configDetails') ? localStorage.getItem('configDetails') : '{}';
      defaultDataForInput = JSON.parse(defaultDataForInput);
    } else {
      defaultDataForInput = localStorage.getItem('defaultConfig') ? localStorage.getItem('defaultConfig') : '';
      defaultDataForInput = JSON.parse(defaultDataForInput);
    }
    setConfigType(defaultDataForInput.refinery.unit[0].subUnit[0].configType);
    if(defaultDataForInput.refinery.unit[0].unitType.trim().toLowerCase() === 'coker'){
      setWithDesalter(defaultDataForInput.refinery.unit[0].subUnit[0].cokerType === 'CD' ? true : false);
    }
    else if(defaultDataForInput.refinery.unit[0].unitType.trim().toLowerCase() === 'fcc')
      setWithDesalter(defaultDataForInput.refinery.unit[0].subUnit[0].fccType === 'FD' ? true : false);
    else setWithDesalter(false);
  });

  const htmlForBrineSampleTemp =() =>{
    return (<div className="input-block">
      <span className="typography--decorative-medium typography--color-secondary">Brine Sample Temperature</span>
      <span className="typography--label-medium typography--color-primary input-display">
        {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.brineSampleTemperature
          ? props.ionicInput.desalter.brineSampleTemperature.toFixed() + ' ' + deslaterTempUOM
          : ''}
      </span>
    </div>);
  }

  const htmlForOverheadWaterNH3 =() =>{
    return(
      <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">
                      {configType === '1A' || configType === '1B' || configType === '1C' ? 'Overhead Drum Water NH3' : 'Hot Drum Water NH3'}
                    </span>
                    <span className="typography--label-medium typography--color-primary input-display">
                      {props.ionicInput &&
                        props.ionicInput.overheadWater &&
                        props.ionicInput.overheadWater.NH3Data &&
                        props.ionicInput.overheadWater.NH3Data.NH3.toFixed()!}
                    </span>
                  </div>  
    );
  }

  const htmlForCombinerRefluxFlow =() =>{
    return ( <div className="input-block">
      <span className="typography--decorative-medium typography--color-secondary">Total Combined Reflux Flow</span>
      <span className="typography--label-medium typography--color-primary input-display">
      {props.ionicInput && props.ionicInput.overheadWater && props.ionicInput.overheadWater.combinedRefluxData &&
      props.ionicInput.overheadWater.combinedRefluxData.combinedRefluxFlow
          ? props.ionicInput.overheadWater.combinedRefluxData.combinedRefluxFlow.toFixed(1) + ' ' +
           props.ionicInput.overheadWater.combinedRefluxData.combinedRefluxFlow_UOM
          : '0'}
      </span>
    </div>);
  }

  const htmlForCombinerRefluxTemp =() =>{
    return (   <div className="input-block">
      <span className="typography--decorative-medium typography--color-secondary">Total Combined Reflux Temperature</span>
      <span className="typography--label-medium typography--color-primary input-display">
      {props.ionicInput.overheadWater.combinedRefluxData &&
      props.ionicInput.overheadWater.combinedRefluxData.combinedRefluxTemp 
        ? props.ionicInput.overheadWater.combinedRefluxData.combinedRefluxTemp.toFixed() + ' ' + deslaterTempUOM
        : '0'}
      </span>
    </div>);
  }

  const htmlForOverheadWaterFlow =() =>{
    return (<div className="input-block">
      <span className="typography--decorative-medium typography--color-secondary">
        {configType === '1A' || configType === '1B' || configType === '1C' ? 'Overhead Water Flow' : '1° Overhead Water Flow'}</span>
      <span className="typography--label-medium typography--color-primary input-display">
      {props.ionicInput.overheadWater.overHeadWaterFlowData &&
      props.ionicInput.overheadWater.overHeadWaterFlowData.overHeadWaterFlow 
        ? props.ionicInput.overheadWater.overHeadWaterFlowData.overHeadWaterFlow.toFixed() + ' ' + props.ionicInput.overheadWater.overHeadWaterFlowData.overHeadWaterFlowUOM
        : '0'}
      </span>
    </div>);
  }

  const htmlForOverheadWaterFlow1 =() =>{
    return (<div className="input-block">
      <span className="typography--decorative-medium typography--color-secondary">2° Overhead Water Flow</span>
      <span className="typography--label-medium typography--color-primary input-display">
      {props.ionicInput.overheadWater.overHeadWaterFlowData &&
      props.ionicInput.overheadWater.overHeadWaterFlowData.overHeadWaterFlow2 
        ? props.ionicInput.overheadWater.overHeadWaterFlowData.overHeadWaterFlow2.toFixed() + ' ' + props.ionicInput.overheadWater.overHeadWaterFlowData.overHeadWaterFlowUOM
        : '0'}
      </span>
    </div>);
  }

  const htmlForOverheadSteamAmine =() =>{
   return(
    <div className="input-block">
    <span className="typography--decorative-medium typography--color-secondary">Overhead Steam Amine</span>
    <span className="typography--label-medium typography--color-primary input-display">
      {props.ionicInput &&
        props.ionicInput.overheadWater &&
        props.ionicInput.overheadWater.steamAmineData &&
        props.ionicInput.overheadWater.steamAmineData.steamAmine!}
    </span>
  </div>
   );
  }

  const htmlForOverheadSteamAminePpm =() =>{
    return (
      <div className="input-block">
      <span className="typography--decorative-medium typography--color-secondary">Overhead Steam Amine ppm</span>
      <span className="typography--label-medium typography--color-primary input-display">
        {props.ionicInput &&
          props.ionicInput.overheadWater &&
          props.ionicInput.overheadWater.steamAmineData &&
          props.ionicInput.overheadWater.steamAmineData.steamAmineppm.toFixed()!}
      </span>
    </div>
    )
  }

  const hydrocarbonLiquidDistillationHasValue =() =>{
    let hasValue;
    hasValue = props.ionicInput &&
                      Object.keys(props.ionicInput).length > 0 &&
                      props.ionicInput.liquidAssayStreams &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation ?
                      true: false ;
   return hasValue;
  }

  const hydrocarbonLiquidColdDistillationHasValue =() =>{
    let hasValue;
    hasValue = props.ionicInput &&
    Object.keys(props.ionicInput).length > 0 &&
    props.ionicInput.liquidAssayStreams &&
    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold &&
    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation ?
                      true: false ;
   return hasValue;
  }

  const hydrocarbonLiquidHotDistillationHasValue =() =>{
    let hasValue;
    hasValue = props.ionicInput &&
    Object.keys(props.ionicInput).length > 0 &&
    props.ionicInput.liquidAssayStreams &&
    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot &&
    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation ?
                      true: false ;
   return hasValue;
  }

  const get1c2CForTowerData = () => {
    return (
      <>
        <div className="input-block">
          <span className="typography--decorative-medium typography--color-secondary">Date</span>
          <span className="typography--label-medium typography--color-primary input-display">{date}</span>
        </div>
        {(props.isCokerOrFcc === false || (props.isCokerOrFcc === true && isWithDesalter)) && (
          <div className="input-block">
            <span className="typography--decorative-medium typography--color-secondary">
              {props.isCokerOrFcc && isWithDesalter ? 'Unit Charge Rate' : 'Crude Charge Rate'}
            </span>
            <span className="typography--label-medium typography--color-primary input-display">
              {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.crudeCharge
                ? props.ionicInput.desalter.crudeCharge.toFixed(3) + ' ' + props.ionicInput.desalter.crudeCharge_UOM
                : '0'}
            </span>
          </div>
        )}

        <div className="input-block">
          <span className="typography--decorative-medium typography--color-secondary">Crude Gravity</span>
          <span className="typography--label-medium typography--color-primary input-display">
            {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.crudeGravity
              ? props.ionicInput.desalter.crudeGravity.toFixed(2)
              : '0'}
          </span>
        </div>

        <div className="input-block">
          <span className="typography--decorative-medium typography--color-secondary">
            {props.isCokerOrFcc && isWithDesalter ? 'Desalted Charge BS&W' : 'Crude BS&W'}
          </span>
          <span className="typography--label-medium typography--color-primary input-display">
            {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.desCrudeBSWData.desCrudeBSW
              ? props.ionicInput.desalter.desCrudeBSWData.desCrudeBSW.toFixed(1)
              : '0'}
          </span>
        </div>
      </>
    );
  };

  return (
    <div>
      <Accordion elevation={0} classes={{}} className="report-outline" sx={{ marginTop: 2 }} id="simulation-input">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
          <Typography className="primary-report-header">Simulation data</Typography>
        </AccordionSummary>
        <AccordionDetails>
         {configType !== '1C' && configType !== '2C' && (
          <>
             {(props.isCokerOrFcc === false || (props.isCokerOrFcc === true && isWithDesalter)) && (
              <div className="sutitle-left">
                <h5>Desalter</h5>
              </div>
             )}
              <div className="input-wrapper">
                <div className="input-frame order-0">
                {(props.isCokerOrFcc === false || (props.isCokerOrFcc === true && isWithDesalter)) && (
                  <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">Date</span>
                    <span className="typography--label-medium typography--color-primary input-display">{date}</span>
                  </div>
                )}
                  {(props.isCokerOrFcc === false || (props.isCokerOrFcc === true && isWithDesalter)) && (
                  <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">{props.isCokerOrFcc && isWithDesalter ? 'Unit Charge Rate' :'Crude Charge Rate'}</span>
                    <span className="typography--label-medium typography--color-primary input-display">
                      {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.crudeCharge
                        ? props.ionicInput.desalter.crudeCharge.toFixed(3) + ' ' + props.ionicInput.desalter.crudeCharge_UOM
                        : '0'}
                    </span>
                  </div>
                  )}
                  {props.isCokerOrFcc && isWithDesalter &&
                  <div className="input-block">
                      <span className="typography--decorative-medium typography--color-secondary">Charger gravity</span>
                      <span className="typography--label-medium typography--color-primary input-display">
                        {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.chargeGravity
                          ? props.ionicInput.desalter.chargeGravity.toFixed(1)
                          : '0'}
                      </span>
                    </div>
                   }
                  {props.isCokerOrFcc && isWithDesalter &&
                  <div className="input-block">
                      <span className="typography--decorative-medium typography--color-secondary">{props.isCokerOrFcc && isWithDesalter ? 'Desalted Charge BS&W' :'Desalted Crude BS&W'}</span>
                      <span className="typography--label-medium typography--color-primary input-display">
                        {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.desCrudeBSWData.desCrudeBSW
                          ? props.ionicInput.desalter.desCrudeBSWData.desCrudeBSW.toFixed(1)
                          : '0'}
                      </span>
                    </div>
                   }
                  {props.isCokerOrFcc === false && (
                  <>
                  <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">Crude Gravity</span>
                    <span className="typography--label-medium typography--color-primary input-display">
                      {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.crudeGravity
                        ? props.ionicInput.desalter.crudeGravity.toFixed(2)
                        : '0'}
                    </span>
                  </div>
                  <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">
                      {configType === '1A' || configType === '2A'
                        ? 'Desalter Wash Rate (% of Crude Charge)'
                        : '1st Stage Desalter Wash Rate (% of Crude)'}
                    </span>
                    <span className="typography--label-medium typography--color-primary input-display">
                      {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.washPercentageData.washPercentage
                        ? props.ionicInput.desalter.washPercentageData.washPercentage.toFixed(1)
                        : '0'}
                    </span>
                  </div>
                  </>
                  )}
                </div>
                {props.isCokerOrFcc === false &&
                <>
                <div className="input-frame order-1">
                  {(configType === '1B' || configType === '2B') && (
                    <div className="input-block">
                      <span className="typography--decorative-medium typography--color-secondary">
                        2nd Stage Desalter Wash Rate (% of Crude)
                      </span>
                      <span className="typography--label-medium typography--color-primary input-display">
                        {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.washPercentageData.washPercentage2
                          ? props.ionicInput.desalter.washPercentageData.washPercentage2.toFixed(1)
                          : '0'}
                      </span>
                    </div>
                  )}
                  <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">
                      % of Brine Recycled to Desalter Wash Water
                    </span>
                    <span className="typography--label-medium typography--color-primary input-display">
                      {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.brineRecyclePercentage
                        ? props.ionicInput.desalter.brineRecyclePercentage.toFixed(1)
                        : '0'}
                    </span>
                  </div>
                  <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">
                      % of Overhead Water to Desalter Wash Water
                    </span>
                    <span className="typography--label-medium typography--color-primary input-display">
                      {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.percentageAtmH2OtoDesalter
                        ? props.ionicInput.desalter.percentageAtmH2OtoDesalter.toFixed()
                        : '0'}
                    </span>
                  </div>
                  <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">% of Overhead Water to SWS</span>
                    <span className="typography--label-medium typography--color-primary input-display">
                    {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.percentageAtmH2OtoSWS
                      ? props.ionicInput.desalter.percentageAtmH2OtoSWS.toFixed() : '0'}
                    </span>
                  </div>
                  {(configType === '1A' || configType === '2A') && (
                    <div className="input-block">
                      <span className="typography--decorative-medium typography--color-secondary">
                        % of Total SSW to Desalter Wash Water
                      </span>
                      <span className="typography--label-medium typography--color-primary input-display">
                        {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.percentageSSWtoDesalter
                        ? props.ionicInput.desalter.percentageSSWtoDesalter.toFixed() : '0'}
                      </span>
                    </div>
                  )}
                </div>
                <div className="input-frame order-2">
                  {(configType === '1B' || configType === '2B') && (
                    <div className="input-block">
                      <span className="typography--decorative-medium typography--color-secondary">
                        % of Total SSW to Desalter Wash Water
                      </span>
                      <span className="typography--label-medium typography--color-primary input-display">
                        {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.percentageSSWtoDesalter
                        ? props.ionicInput.desalter.percentageSSWtoDesalter.toFixed() : '0'}
                      </span>
                    </div>
                  )}
                  <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">Desalter Temperature</span>
                    <span className="typography--label-medium typography--color-primary input-display">
                      {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.desalterTemperature
                        ? props.ionicInput.desalter.desalterTemperature.toFixed() + ' ' + deslaterTempUOM
                        : ''}
                    </span>
                  </div>
                  <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">
                      {configType === '1A' || configType === '2A' ? 'Desalter Brine pH' : '1st Stage Desalter Brine pH'}
                    </span>
                    <span className="typography--label-medium typography--color-primary input-display">
                      {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.brinepHData.brinepH
                        ? props.ionicInput.desalter.brinepHData.brinepH.toFixed(1)
                        : '0'}
                    </span>
                  </div>
                  {(configType === '1B' || configType === '2B') && (
                    <div className="input-block">
                      <span className="typography--decorative-medium typography--color-secondary">2nd Stage Desalter Brine pH</span>
                      <span className="typography--label-medium typography--color-primary input-display">
                        {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.brinepHData.brinepH2
                          ? props.ionicInput.desalter.brinepHData.brinepH2.toFixed(1)
                          : '0'}
                      </span>
                    </div>
                  )}
                  {(configType === '1A' || configType === '2A') && 
                    htmlForBrineSampleTemp()
                  }
                  {(configType === '1A' || configType === '2A') && (
                    <div className="input-block">
                      <span className="typography--decorative-medium typography--color-secondary">{props.isCokerOrFcc && isWithDesalter ? 'Desalted Charge BS&W' :'Desalted Crude BS&W'}</span>
                      <span className="typography--label-medium typography--color-primary input-display">
                        {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.desCrudeBSWData.desCrudeBSW
                          ? props.ionicInput.desalter.desCrudeBSWData.desCrudeBSW.toFixed(1)
                          : '0'}
                      </span>
                    </div>
                  )}
                </div>
                <div className="input-frame order-3">
                  {(configType === '1B' || configType === '2B') &&
                    htmlForBrineSampleTemp()
                  }
                  {(configType === '1B' || configType === '2B') && (
                    <div className="input-block">
                      <span className="typography--decorative-medium typography--color-secondary">1st Stage Desalted Crude BS&W</span>
                      <span className="typography--label-medium typography--color-primary input-display">
                        {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.desCrudeBSWData.desCrudeBSW
                          ? props.ionicInput.desalter.desCrudeBSWData.desCrudeBSW.toFixed(1)
                          : '0'}
                      </span>
                    </div>
                  )}
                  {(configType === '1B' || configType === '2B') && (
                    <div className="input-block">
                      <span className="typography--decorative-medium typography--color-secondary">2nd Stage Desalted Crude BS&W</span>
                      <span className="typography--label-medium typography--color-primary input-display">
                        {Object.keys(props.ionicInput.desalter).length && props.ionicInput.desalter.desCrudeBSWData.desCrudeBSW2
                          ? props.ionicInput.desalter.desCrudeBSWData.desCrudeBSW2.toFixed(1)
                          : '0'}
                      </span>
                    </div>
                  )}
                </div>
                 </>
                }
              </div>
              {(props.isCokerOrFcc === false || (props.isCokerOrFcc && isWithDesalter)) && 
              <BhDivider class="wd-100" marginTop="xlarge"></BhDivider>
              }
          </>
         )}
          <div className="sutitle-left">
            <h5>Tower</h5>
          </div>
          <div className="input-wrapper">
          {(configType === '1C' || configType === '2C') && (
          <div className="input-frame order-0">
              { get1c2CForTowerData()}
          </div>
             )}
            <div className="input-frame order-0">
              {(props.isCokerOrFcc === true && !isWithDesalter) && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Date</span>
                  <span className="typography--label-medium typography--color-primary input-display">{date}</span>
                </div>
              )}

              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">{props.isCokerOrFcc && isWithDesalter ? 'Total Tower Steam' :'Stripping Steam to Tower'}</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.towerData.steamTower
                    ? props.ionicInput.towerData.steamTower.toFixed(3) + ' ' + props.ionicInput.towerData.steamTower_UOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">{props.isCokerOrFcc && isWithDesalter ? 'Total SS Steam' :'1st Side Stripper Steam'}</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.towerData.steam1SS
                    ? props.ionicInput.towerData.steam1SS.toFixed(3) + ' ' + props.ionicInput.towerData.steam1SS_UOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">{props.isCokerOrFcc && isWithDesalter ? 'Total Furnace Steam' :'2nd Side Stripper Steam'}</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.towerData.steam2SS
                    ? props.ionicInput.towerData.steam2SS.toFixed(3) + ' ' + props.ionicInput.towerData.steam2SS_UOM
                    : '0'}
                </span>
              </div>
              {(props.isCokerOrFcc === true && isWithDesalter) && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Total Other Steam</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput.towerData.steam3SS
                      ? props.ionicInput.towerData.steam3SS.toFixed(3) + ' ' + props.ionicInput.towerData.steam3SS_UOM
                      : '0'}
                  </span>
                </div>
              )}
              {props.isCokerOrFcc === false && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">3rd Side Stripper Steam</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput.towerData.steam3SS
                      ? props.ionicInput.towerData.steam3SS.toFixed(3) + ' ' + props.ionicInput.towerData.steam3SS_UOM
                      : '0'}
                  </span>
                </div>
              )}
            </div>
            <div className="input-frame order-1">
            {(props.isCokerOrFcc === true && !isWithDesalter) && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">3rd Side Stripper Steam</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput.towerData.steam3SS
                      ? props.ionicInput.towerData.steam3SS.toFixed(3) + ' ' + props.ionicInput.towerData.steam3SS_UOM
                      : '0'}
                  </span>
                </div>
              )}
              {(!props.isCokerOrFcc || (props.isCokerOrFcc && !isWithDesalter)) && 
                  <>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">4th Side Stripper Steam</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.towerData.steam4SS
                    ? props.ionicInput.towerData.steam4SS.toFixed(3) + ' ' + props.ionicInput.towerData.steam4SS_UOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">5th Side Stripper Steam</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.towerData.steam5SS
                    ? props.ionicInput.towerData.steam5SS.toFixed(3) + ' ' + props.ionicInput.towerData.steam5SS_UOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">6th Side Stripper Steam</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.towerData.steam6SS
                    ? props.ionicInput.towerData.steam6SS.toFixed(3) + ' ' + props.ionicInput.towerData.steam6SS_UOM
                    : '0'}
                </span>
              </div>
              </>
              }
            </div>
          </div>
          <BhDivider class="wd-100" marginTop="xlarge"></BhDivider>
          <div className="sutitle-left">
            <h5>Overhead Conditions</h5>
          </div>
          <div className="input-wrapper">
            <div className="input-frame order-0">
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Atm Tower Top Temperature</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.overheadConditions.OHTemp
                    ? props.ionicInput.overheadConditions.OHTemp.toFixed() + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Atm Tower Top Pressure</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.overheadConditions.OHPress
                    ? props.ionicInput.overheadConditions.OHPress.toFixed(3) + ' ' + props.ionicInput.overheadConditions.OHPress_UOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Overhead Primary Wash Injection Rate</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.overheadConditions.washRate
                    ? props.ionicInput.overheadConditions.washRate.toFixed() + ' ' + props.ionicInput.overheadConditions.washRate_UOM
                    : '0'}
                </span>
              </div>
       
                <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Total External Wash Rate</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.overheadConditions.totalExternalWashRate
                    ? props.ionicInput.overheadConditions.totalExternalWashRate.toFixed() + ' ' + props.ionicInput.overheadConditions.totalExternalWashRate_UOM
                    : '0'}
                </span>
              </div>
    

        
            </div>
            <div className="input-frame order-1">
         
            <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Overhead Water Wash Temperature</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.overheadConditions.washTemp
                    ? props.ionicInput.overheadConditions.washTemp.toFixed() + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
          
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Temp Before Water Wash</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.overheadConditions.washProcessTemp
                    ? props.ionicInput.overheadConditions.washProcessTemp.toFixed() + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Pressure Before Water Wash</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.overheadConditions.washProcessPress
                    ? props.ionicInput.overheadConditions.washProcessPress.toFixed(3) +
                      ' ' +
                      props.ionicInput.overheadConditions.washProcessPress_UOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Temp at Velocity Calc</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.overheadConditions.velocityTemp
                    ? props.ionicInput.overheadConditions.velocityTemp.toFixed() + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>

   
        
            </div>
            <div className="input-frame order-2">
       
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Pressure at Velocity Calc</span>
                <span className="typography--label-medium typography--color-primary input-display">
                {props.ionicInput.overheadConditions.velocityPress
                ? props.ionicInput.overheadConditions.velocityPress.toFixed(3) +
                ' ' +
                props.ionicInput.overheadConditions.velocityPress_UOM
             : '0'}
            </span>
               </div>
        
          
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C' ? 'Drum Temperature' : 'Hot Drum Temperature'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.overheadConditions.drumTemp
                    ? props.ionicInput.overheadConditions.drumTemp.toFixed() + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C' ? 'Drum Pressure' : 'Hot Drum Pressure'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput.overheadConditions.drumPress
                    ? props.ionicInput.overheadConditions.drumPress.toFixed(3) + ' ' + props.ionicInput.overheadConditions.drumPress_UOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Is Wet Reflux Present?</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.liquidAssayStreams && props.ionicInput.liquidAssayStreams.isWetReflux
                    ? 'TRUE'
                    : 'FALSE'}
                </span>
              </div>
            </div>
          </div>
          <BhDivider class="wd-100" marginTop="xlarge"></BhDivider>
          <div className="sutitle-left">
            <h5>Hydrocarbon Liquid</h5>
          </div>
          {(configType === '2A' || configType === '2B' || configType === '2C') && (
            <div className="sutitle-left">
              <span className="typography--decorative-medium typography--color-secondary">Hydrocarbon Liquid Hot</span>
            </div>
          )}
          <div className="input-wrapper">
            <div className="input-frame order-0">
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C' ? 'Overhead Drum Reflux Rate' : 'Hot Drum Reflux Rate'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? props.ionicInput &&
                      Object.keys(props.ionicInput).length > 0 &&
                      props.ionicInput.liquidAssayStreams &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.reflux
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.reflux.toFixed(3) +
                        ' ' +
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.reflux_UOM
                      : '0'
                    : props.ionicInput &&
                      Object.keys(props.ionicInput).length > 0 &&
                      props.ionicInput.liquidAssayStreams &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.reflux
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.reflux.toFixed(3) +
                      ' ' +
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.reflux_UOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C' ? 'Overhead Drum Product Rate' : 'Hot Drum Product Rate'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? props.ionicInput &&
                      Object.keys(props.ionicInput).length > 0 &&
                      props.ionicInput.liquidAssayStreams &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.product
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.product.toFixed(3) +
                        ' ' +
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.product_UOM
                      : '0'
                    : props.ionicInput &&
                      props.ionicInput.liquidAssayStreams &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.product
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.product.toFixed(3) +
                      ' ' +
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.product_UOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C' ? 'Overhead Drum Naphtha Gravity' : 'Hot Drum Naphtha Gravity'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? props.ionicInput &&
                      Object.keys(props.ionicInput).length > 0 &&
                      props.ionicInput.liquidAssayStreams &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.gravity
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.gravity.toFixed(2)
                      : '0'
                    : props.ionicInput &&
                      Object.keys(props.ionicInput).length > 0 &&
                      props.ionicInput.liquidAssayStreams &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.gravity
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.gravity.toFixed(2)
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? 'Overhead Drum Naphtha Distillation Type'
                    : 'Hot Drum Naphtha Distillation Type'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType!
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType!}
                </span>
              </div>
            </div>
            <div className="input-frame order-1">
              {configType !== '1B' && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {configType === '1A'
                      ? hydrocarbonLiquidDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                        ? '0 wt% Distilled'
                        : '0 vol% Distilled'
                      : hydrocarbonLiquidHotDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                      ? '0 wt% Distilled'
                      : '0 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {configType === '1A'
                      ? hydrocarbonLiquidDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[0]
                        ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[0].toFixed(1) + ' ' + deslaterTempUOM
                        : '0'
                      : hydrocarbonLiquidHotDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[0]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[0].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
              )}
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '5 wt% Distilled'
                      : '5 vol% Distilled'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                    ? '5 wt% Distilled'
                    : '5 vol% Distilled'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[5]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[5].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[5]
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[5].toFixed(1) + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1B'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '10 wt% Distilled'
                      : '10 vol% Distilled'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                    ? '10 wt% Distilled'
                    : '10 vol% Distilled'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[10]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[10].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10]
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10].toFixed(1) + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '20 wt% Distilled'
                      : '20 vol% Distilled'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                    ? '20 wt% Distilled'
                    : '20 vol% Distilled'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[20]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[20].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[20]
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[20].toFixed(1) + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
              {configType === '1B' && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '30 wt% Distilled'
                      : '30 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[30]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[30].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
              )}
            </div>
            <div className="input-frame order-2">
              {configType !== '1B' && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {configType === '1A' || configType === '1B' || configType === '1C'
                      ? hydrocarbonLiquidDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                        ? '30 wt% Distilled'
                        : '30 vol% Distilled'
                      : hydrocarbonLiquidHotDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                      ? '30 wt% Distilled'
                      : '30 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {configType === '1A' || configType === '1B' || configType === '1C'
                      ? hydrocarbonLiquidDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[30]
                        ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[30].toFixed(1) + ' ' + deslaterTempUOM
                        : '0'
                      : hydrocarbonLiquidHotDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[30]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[30].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
              )}
              {configType === '1B' && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '40 wt% Distilled'
                      : '40 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[40]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[40].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
              )}
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '50 wt% Distilled'
                      : '50 vol% Distilled'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                    ? '50 wt% Distilled'
                    : '50 vol% Distilled'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[50]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[50].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50]
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50].toFixed(1) + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
              {configType === '1B' && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '60 wt% Distilled'
                      : '60 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[60]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[60].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
              )}
              {/* {configType !== '1B' && ( */}
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '70 wt% Distilled'
                      : '70 vol% Distilled'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                    ? '70 wt% Distilled'
                    : '70 vol% Distilled'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[70]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[70].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[70]
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[70].toFixed(1) + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
              {/* )} */}
              {configType !== '1B' && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {configType === '1A'
                      ? hydrocarbonLiquidDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                        ? '80 wt% Distilled'
                        : '80 vol% Distilled'
                      : hydrocarbonLiquidHotDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                      ? '80 wt% Distilled'
                      : '80 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {configType === '1A' || configType === '1B' || configType === '1C'
                      ? hydrocarbonLiquidDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[80]
                        ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[80].toFixed(1) + ' ' + deslaterTempUOM
                        : '0'
                      : hydrocarbonLiquidHotDistillationHasValue() &&
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[80]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[80].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
              )}
            </div>
            <div className="input-frame order-3">
              {configType === '1B' && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '80 wt% Distilled'
                      : '80 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[80]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[80].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
              )}
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '90 wt% Distilled'
                      : '90 vol% Distilled'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                    ? '90 wt% Distilled'
                    : '90 vol% Distilled'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[90]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[90].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90]
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90].toFixed(1) + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '95 wt% Distilled'
                      : '95 vol% Distilled'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                    ? '95 wt% Distilled'
                    : '95 vol% Distilled'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[95]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[95].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[95]
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[95].toFixed(1) + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillationType === 'D2887'
                      ? '100 wt% Distilled'
                      : '100 vol% Distilled'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillationType === 'D2887'
                    ? '100 wt% Distilled'
                    : '100 vol% Distilled'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {configType === '1A' || configType === '1B' || configType === '1C'
                    ? hydrocarbonLiquidDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[100]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquid.distillation[100].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'
                    : hydrocarbonLiquidHotDistillationHasValue() &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[100]
                    ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidHot.distillation[100].toFixed(1) + ' ' + deslaterTempUOM
                    : '0'}
                </span>
              </div>
            </div>
          </div>
          {(configType === '2A' || configType === '2B' || configType === '2C') && <div className="hr-border"></div>}
          {(configType === '2A' || configType === '2B' || configType === '2C') && (
            <div className="sutitle-left">
              <span className="typography--decorative-medium typography--color-secondary">Hydrocarbon Liquid Cold</span>
            </div>
          )}
          {(configType === '2A' || configType === '2B' || configType === '2C') && (
            <div className="input-wrapper">
              <div className="input-frame order-0">
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Cold Drum Reflux Rate</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                    Object.keys(props.ionicInput).length > 0 &&
                    props.ionicInput.liquidAssayStreams &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.reflux
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.reflux.toFixed(3) +
                        ' ' +
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.reflux_UOM
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Cold Drum Product Rate</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                    Object.keys(props.ionicInput).length > 0 &&
                    props.ionicInput.liquidAssayStreams &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.product
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.product.toFixed(3) +
                        ' ' +
                        props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.product_UOM
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Cold Drum Naphtha Gravity</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                    Object.keys(props.ionicInput).length > 0 &&
                    props.ionicInput.liquidAssayStreams &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.gravity
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.gravity.toFixed(2)
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Cold Drum Naphtha Distillation Type</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                      Object.keys(props.ionicInput).length > 0 &&
                      props.ionicInput.liquidAssayStreams &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold &&
                      props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType}
                  </span>
                </div>
              </div>
              <div className="input-frame order-1">
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '0 wt% Distilled'
                      : '0 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[0]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[0].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '5 wt% Distilled'
                      : '5 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[5]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[5].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '10 wt% Distilled'
                      : '10 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '20 wt% Distilled'
                      : '20 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[20]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[20].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
              </div>
              <div className="input-frame order-2">
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '30 wt% Distilled'
                      : '30 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[30]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[30].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '50 wt% Distilled'
                      : '50 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '70 wt% Distilled'
                      : '70 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[70]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[70].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '80 wt% Distilled'
                      : '80 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[80]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[80].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
              </div>
              <div className="input-frame order-3">
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '90 wt% Distilled'
                      : '90 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '95 wt% Distilled'
                      : '95 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[95]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[95].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillationType === 'D2887'
                      ? '100 wt% Distilled'
                      : '100 vol% Distilled'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {hydrocarbonLiquidColdDistillationHasValue() &&
                    props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[100]
                      ? props.ionicInput.liquidAssayStreams.hydrocarbonLiquidCold.distillation[100].toFixed(1) + ' ' + deslaterTempUOM
                      : '0'}
                  </span>
                </div>
              </div>
            </div>
          )}
          <BhDivider class="wd-100" marginTop="xlarge"></BhDivider>
          <div className="sutitle-left">
            <h5>Off Gas</h5>
          </div>
          <div className="input-wrapper">
            <div className="input-frame order-0">
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Overhead Off Gas Rate</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.offGas
                    ? props.ionicInput.offGasData.offGas.toFixed(3) + ' ' + props.ionicInput.offGasData.offGas_UOM
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - H2</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.H2
                    ? props.ionicInput.offGasData.H2.toFixed(1)
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - H2S</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.H2S
                    ? props.ionicInput.offGasData.H2S.toFixed(1)
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - CO2</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.CO2
                    ? props.ionicInput.offGasData.CO2.toFixed(1)
                    : '0'}
                </span>
              </div>
            </div>
            <div className="input-frame order-1">
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - Methane</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.C1
                    ? props.ionicInput.offGasData.C1.toFixed(1)
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - Ethane</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.C2
                    ? props.ionicInput.offGasData.C2.toFixed(1)
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - Propane</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.C3
                    ? props.ionicInput.offGasData.C3.toFixed(1)
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - Isobutane</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.iC4
                    ? props.ionicInput.offGasData.iC4.toFixed(1)
                    : '0'}
                </span>
              </div>
            </div>
            <div className="input-frame order-2">
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - n-Butane</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.nC4
                    ? props.ionicInput.offGasData.nC4.toFixed(1)
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - Isopentane</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.iC5
                    ? props.ionicInput.offGasData.iC5.toFixed(1)
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - n-Pentane</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.nC5
                    ? props.ionicInput.offGasData.nC5.toFixed(1)
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Off Gas Composition - n-Hexane+</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput && props.ionicInput.offGasData && props.ionicInput.offGasData.nC6
                    ? props.ionicInput.offGasData.nC6.toFixed(1)
                    : '0'}
                </span>
              </div>
            </div>
          </div>
          <BhDivider class="wd-100" marginTop="xlarge"></BhDivider>
          <div className="sutitle-left">
            <h5>Overhead Water</h5>
          </div>
          <div className="input-wrapper">
            <div className="input-frame order-0">
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C' ? 'Overhead Drum Water pH' : 'Hot Drum Water pH'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput &&
                  props.ionicInput.overheadWater &&
                  props.ionicInput.overheadWater.pHData &&
                  props.ionicInput.overheadWater.pHData.pH
                    ? props.ionicInput.overheadWater.pHData.pH.toFixed(1)
                    : '0'}
                </span>
              </div>
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C' ? 'Overhead Drum Water Cl' : 'Hot Drum Water Cl'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput &&
                    props.ionicInput.overheadWater &&
                    props.ionicInput.overheadWater.clData &&
                    props.ionicInput.overheadWater.clData.cl.toFixed()!}
                </span>
              </div>
              {(props.isCokerOrFcc === false &&
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">
                  {configType === '1A' || configType === '1B' || configType === '1C' ? 'Overhead Drum Water Br' : 'Hot Drum Water Br'}
                </span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput &&
                    props.ionicInput.overheadWater &&
                    props.ionicInput.overheadWater.bromideData &&
                    props.ionicInput.overheadWater.bromideData.bromide &&
                    props.ionicInput.overheadWater.bromideData.bromide.toFixed()!}
                </span>
              </div>
              )}
              {(configType === '2A' || configType === '2B' || configType === '2C') && (
                htmlForOverheadWaterNH3()
              )}
              {(configType === '1A' || configType === '1B' || configType === '1C') && (
                <>
                  {htmlForOverheadWaterNH3()}
                  {props.isCokerOrFcc === true && (
                    <div className="input-block">
                      <span className="typography--decorative-medium typography--color-secondary">Sulfide</span>
                      <span className="typography--label-medium typography--color-primary input-display">
                        {props.ionicInput && props.ionicInput.overheadWater && props.ionicInput.overheadWater.sulfide!}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="input-frame order-1">
              {(configType === '2A' || configType === '2B' || configType === '2C') && (
                <>
                                <div className="input-block">
                                <span className="typography--decorative-medium typography--color-secondary">Cold Drum Water pH</span>
                                <span className="typography--label-medium typography--color-primary input-display">
                                  {props.ionicInput &&
                                  props.ionicInput.overheadWater &&
                                  props.ionicInput.overheadWater.pHData &&
                                  props.ionicInput.overheadWater.pHData.pH_2
                                    ? props.ionicInput.overheadWater.pHData.pH_2.toFixed(1)
                                    : '0'}
                                </span>
                              </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Cold Drum Water Cl</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                      props.ionicInput.overheadWater &&
                      props.ionicInput.overheadWater.clData &&
                      props.ionicInput.overheadWater.clData.cl_2.toFixed()!}
                  </span>
                </div>
                </>
              )}
              {(configType === '2A' || configType === '2B' || configType === '2C') && (
                <>
                <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Cold Drum Water Br</span>
                <span className="typography--label-medium typography--color-primary input-display">
                  {props.ionicInput &&
                    props.ionicInput.overheadWater &&
                    props.ionicInput.overheadWater.bromideData &&
                    props.ionicInput.overheadWater.bromideData.bromide_2 &&
                    props.ionicInput.overheadWater.bromideData.bromide_2.toFixed()!}
                </span>
              </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Cold Drum Water NH3</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                      props.ionicInput.overheadWater &&
                      props.ionicInput.overheadWater.NH3Data &&
                      props.ionicInput.overheadWater.NH3Data.NH3_2.toFixed()!}
                  </span>
                </div>
                </>
              )}
                     
              {(configType === '1A' || configType === '1B' || configType === '1C') && (
                <>
                {props.isCokerOrFcc === false && (
                  <>
                 <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">Overhead Neutralizer</span>
                    <span className="typography--label-medium typography--color-primary input-display">
                      {props.ionicInput && props.ionicInput.overheadWater && props.ionicInput.overheadWater.neutralizerName!}
                    </span>
                  </div>
                 <div className="input-block">
                 <span className="typography--decorative-medium typography--color-secondary">Overhead Neutralizer Rate</span>
                 <span className="typography--label-medium typography--color-primary input-display">
                   {props.ionicInput && props.ionicInput.overheadWater && props.ionicInput.overheadWater.neutralizerRate
                     ? props.ionicInput.overheadWater.neutralizerRate.toFixed(1) + ' ' + props.ionicInput.overheadWater.neutralizerRate_UOM
                     : '0'}
                 </span>
               </div>
               </>
                )}
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Overhead Tramp Amine</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                      props.ionicInput.overheadWater &&
                      props.ionicInput.overheadWater.trampAmineData &&
                      props.ionicInput.overheadWater.trampAmineData.trampAmine!}
                  </span>
                </div>
                </>
              )}
              {(configType === '1A' || configType === '1B' || configType === '1C') && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">
                    {configType === '1A' || configType === '1B' || configType === '1C' ? 'Overhead Tramp Amine ppm' : '1° Tramp Amine ppm'}
                  </span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                      props.ionicInput.overheadWater &&
                      props.ionicInput.overheadWater.trampAmineData &&
                      props.ionicInput.overheadWater.trampAmineData.trampAmineppm.toFixed()!}
                  </span>
                </div>
              )}
              {props.isCokerOrFcc === true && (configType === '1A' || configType === '1B' || configType === '1C') && (
                <>
                {htmlForOverheadSteamAmine()}
                {htmlForOverheadSteamAminePpm()}
                </>
              )}
            </div>
            <div className="input-frame order-2">
              {(configType === '2A' || configType === '2B' || configType === '2C') && (
                <>
                  <div className="input-block">
                    <span className="typography--decorative-medium typography--color-secondary">Overhead Neutralizer</span>
                    <span className="typography--label-medium typography--color-primary input-display">
                      {props.ionicInput && props.ionicInput.overheadWater && props.ionicInput.overheadWater.neutralizerName!}
                    </span>
                  </div>
                  <div className="input-block">
                 <span className="typography--decorative-medium typography--color-secondary">Overhead Neutralizer Rate</span>
                 <span className="typography--label-medium typography--color-primary input-display">
                   {props.ionicInput && props.ionicInput.overheadWater && props.ionicInput.overheadWater.neutralizerRate
                     ? props.ionicInput.overheadWater.neutralizerRate.toFixed(1) + ' ' + props.ionicInput.overheadWater.neutralizerRate_UOM
                     : '0'}
                 </span>
               </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Overhead Tramp Amine</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                      props.ionicInput.overheadWater &&
                      props.ionicInput.overheadWater.trampAmineData &&
                      props.ionicInput.overheadWater.trampAmineData.trampAmine!}
                  </span>
                </div>
                </>
              )}
              {(configType === '2A' || configType === '2B' || configType === '2C') && (
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">1° Tramp Amine ppm</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                      props.ionicInput.overheadWater &&
                      props.ionicInput.overheadWater.trampAmineData &&
                      props.ionicInput.overheadWater.trampAmineData.trampAmineppm.toFixed()!}
                  </span>
                </div>
              )}
              {props.isCokerOrFcc === false && (configType === '1A' || configType === '1B' || configType === '1C') && (
                <>
                {htmlForOverheadSteamAmine()}
                {htmlForOverheadSteamAminePpm()}
                </>
              )}
               {props.isCokerOrFcc === false && (configType === '1A' || configType === '1B' || configType === '1C') &&
              <>
                {htmlForCombinerRefluxFlow()}
                {htmlForCombinerRefluxTemp()}
              </>
              }
            </div>
            <div className="input-frame order-3">    
            {(configType === '2A' || configType === '2B' || configType === '2C') && (
              <>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">2° Tramp Amine ppm</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                      props.ionicInput.overheadWater &&
                      props.ionicInput.overheadWater.trampAmineData &&
                      props.ionicInput.overheadWater.trampAmineData.trampAmineppm2.toFixed()!}
                  </span>
                </div>
                {htmlForOverheadSteamAmine()}
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">1° Steam Amine ppm</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                      props.ionicInput.overheadWater &&
                      props.ionicInput.overheadWater.steamAmineData &&
                      props.ionicInput.overheadWater.steamAmineData.steamAmineppm.toFixed()!}
                  </span>
                </div>
                <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">2° Steam Amine ppm</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput &&
                      props.ionicInput.overheadWater &&
                      props.ionicInput.overheadWater.steamAmineData &&
                      props.ionicInput.overheadWater.steamAmineData.steamAmineppm2.toFixed()!}
                  </span>
                </div>
                </>
              )}
              {props.isCokerOrFcc === false && (configType === '1A' || configType === '1B' || configType === '1C') && (
              <>
              {htmlForOverheadWaterFlow()}
              <div className="input-block">
                <span className="typography--decorative-medium typography--color-secondary">Total External Neutralizer Flow</span>
                <span className="typography--label-medium typography--color-primary input-display">
                {props.ionicInput && props.ionicInput.overheadWater && props.ionicInput.overheadWater.outsideNeutralizer
                      ? props.ionicInput.overheadWater.outsideNeutralizer.toFixed(1) + ' ' + props.ionicInput.overheadWater.outsideNeutralizer_UOM
                      : '0'}
                </span>
              </div>
              </>
              )}
            </div>
            <div className="input-frame order-4">
            {props.isCokerOrFcc === false && (configType === '2A' || configType === '2B' || configType === '2C') &&
            <>
             {htmlForCombinerRefluxFlow()}
             {htmlForCombinerRefluxTemp()}
             {htmlForOverheadWaterFlow()}
             {htmlForOverheadWaterFlow1()}
            </>
            }
            </div>
            {props.isCokerOrFcc === false && (configType === '2A' || configType === '2B' || configType === '2C') &&
            <div className="input-frame order-5">
            <div className="input-block">
                  <span className="typography--decorative-medium typography--color-secondary">Total External Neutralizer Flow</span>
                  <span className="typography--label-medium typography--color-primary input-display">
                    {props.ionicInput && props.ionicInput.overheadWater && props.ionicInput.overheadWater.outsideNeutralizer
                      ? props.ionicInput.overheadWater.outsideNeutralizer.toFixed(1) + ' ' + props.ionicInput.overheadWater.outsideNeutralizer_UOM
                      : '0'}
                  </span>
            </div>
            </div>
            }
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
