import { useEffect, useState } from 'react';
import PrimaryReport from './PrimaryReport';
import PrimaryReportPDF from './PrimaryReportPDF';
import { useLocation } from 'react-router-dom';
import SaltTemperatureGraph from './SaltTemperatureGraph';
import SaltTemperatureGraphPDF from './SaltTemperatureGraphPDF';
import ReportComments from './ReportComments';
import { BhActionBar, BhSpinner, BhTitleWrapper, BhDivider } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import html2canvas from 'html2canvas';
import { Backdrop, Breadcrumbs, Link, Typography } from '@mui/material';
import { format } from 'date-fns-tz';
import apiRequest from '../../services/api-helper';
import { APIEnum } from '../../constants/api-enum';
import PublicIcon from '@mui/icons-material/Public';
import SimulationInput from './SimulationInput';
declare const window: any;

const { jsPDF } = require('jspdf');

const CorrosionRiskReport = () => {
  const location = useLocation();
  const [mainText, setMainText] = useState('');
  const [subText, setSubText] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [open, setOpen] = useState(false);
  const [recommendations, setRecommendations] = useState('');
  const [dateReport, setDateReport] = useState('');
  const [saltGraphApiResult, setSaltGraphApiResult] = useState<any>({});
  const [breadCrumbDate, setBreadCrumbDate] = useState('');
  const [ionicInput, setIonicInput] = useState({});
  const [defaultUOM, setDefaultUOM] = useState({});
  const [reportModified, setReportModified] = useState('');
  const [sandBoxSimulationName, setSandBoxSimulationName]= useState('');
  const [isCokerOrFcc, setIsCokerOrFcc]= useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const downloadPdfDocument = () => {
    setOpen(true);
    let input: any = <div></div>;
    input = document.getElementById('download-pdf');
    input.removeAttribute('style');
    setTimeout(() => {
      html2canvas(input).then((canvas: any) => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;
        heightLeft -= pageHeight;
        const doc = new jsPDF('p', 'mm', 'a4');
        doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
        while (heightLeft >= 16) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.page++;
          doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
          heightLeft -= pageHeight;
        }

        input.style.display = 'none';
        var reportName = location.state && location.state.modeType && location.state.modeType === 'Actual' ? 
        `TOPGUARD CRM - ${subHeading} - ${format(new Date(dateReport), 'yyyyMMdd')}.pdf` : `TOPGUARD CRM - ${subHeading} - Sandbox - ${format(new Date(dateReport), 'yyyyMMdd')}.pdf`;
        doc.save(reportName);
        setOpen(false);
      });
    }, 2000);
  };

  const updateReportDate = (date: any, formattedDate: any) => {
    setDateReport(date);
    setBreadCrumbDate(formattedDate);
  };
  useEffect(() => {
    setSubHeading(localStorage.getItem('subtitle')!)
    //if redirected from history, take config data from history api result, otherwise take from local storage
    let configData =
    location.state && location.state.configDetails ? location.state.configDetails : JSON.parse(localStorage.getItem('defaultConfig')!);
  if(configData?.refinery?.unit[0]?.unitType) {
    if(configData?.refinery?.unit[0]?.unitType.trim().toLowerCase() === 'coker' 
    || configData?.refinery?.unit[0]?.unitType.trim().toLowerCase() === 'fcc' 
    || configData?.refinery?.unit[0]?.unitType.trim().toLowerCase() === 'hydrocracker' 
    || configData?.refinery?.unit[0]?.unitType.trim().toLowerCase() === 'hydrotreater-distillate'
    || configData?.refinery?.unit[0]?.unitType.trim().toLowerCase() === 'hydrotreater-gas oil'
    || configData?.refinery?.unit[0]?.unitType.trim().toLowerCase() === 'hydrotreater-naphtha'
    || configData?.refinery?.unit[0]?.unitType.trim().toLowerCase() === 'hydrotreater-naphtha renewable'
    || configData?.refinery?.unit[0]?.unitType.trim().toLowerCase() === 'hydrotreater-resid') {
      setIsCokerOrFcc(true);
    }
    else {
      setIsCokerOrFcc(false);
    }
  }
    if(location.state && location.state.modeType && location.state.modeType ==='Actual'){
    //salt graph api call in case of normal simulations
    getSaltGraphData(configData);
    }
    
      const unitName =
      configData.refinery &&
      configData.refinery.unit &&
      configData.refinery.unit[0] &&
      configData.refinery.unit[0].unitName;
   const subUnitName = 
      configData.refinery &&
      configData.refinery.unit &&
      configData.refinery.unit[0] &&
      configData.refinery.unit[0].subUnit &&
      configData.refinery.unit[0].subUnit[0] &&
      configData.refinery.unit[0].subUnit[0].subUnitName;
      setSubText(unitName + ' | ' + subUnitName);
      setMainText(configData.refinery.customerName + ' | ' + configData.refinery.refineryName);
    getReportResults();
  }, []);

  const getReportResults = () => {
    let resultDataId = location.state ? parseInt(location.state.reportData[Object.keys(location.state.reportData)[0]].resultDataId) : null;
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulations?resultDataId=${resultDataId}`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET, true, location.state?.modeType).then((response: any) => {
      setIonicInput(response?.data?.inputSimulationData.ionicInput[0]);
      setDefaultUOM(response.data.result.defaultUOM);
      setRecommendations(response.data.result.reportComments);
      setReportModified(response.data.result.lastUpdatedBy);
      setSandBoxSimulationName(response?.data?.inputSimulationData?.sandBoxModelDetails?.sandBoxSimulationName)
    });
  };

  const updateComments = (comment: any) => {
    setRecommendations(comment);
  };

  const getSaltGraphData = (configData: any) => {
    //let configFromStorage: any = JSON.parse(localStorage.getItem('defaultConfig')!);
    if (configData && configData.refinery) {
      if (
        configData.refinery.unit &&
        configData.refinery.unit[0] &&
        configData.refinery.unit[0].subUnit &&
        configData.refinery.unit[0].subUnit[0]
      ) {
        let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/simulations/saltgraph/${configData.refinery.unit[0].subUnit[0].subUnitId}/${configData.refinery.unit[0].subUnit[0].configId}`;
        apiRequest(encodeURI(apiUrl), {}, APIEnum.GET).then((result) => {
          setSaltGraphApiResult(result?.data);
        });
      }
    }
  };

  return (
    <div>
      {Object.keys(ionicInput).length > 0 && (
        <>
          <div>
            <div style={{ marginLeft: '20px', marginTop: '-17px', height: '30px' }}>
              {location.state && location.state.pageName === 'All Simulation' ? (
                <Breadcrumbs
                  aria-label="breadcrumb"
                  style={{
                    position: 'absolute',
                  }}
                >
                  <Link color="inherit" href="/">
                    <PublicIcon />
                  </Link>
                  <Link
                    className="bread-crumb-font inactive-breadcrumb-label"
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/simulations"
                  >
                    All Simulations{'    '}
                  </Link>
                  <Typography style={{ color: 'black', fontWeight: '500' }} className="bread-crumb-font">
                    Corrosion risk report - {breadCrumbDate}
                  </Typography>
                </Breadcrumbs>
              ) : (
                <Breadcrumbs
                  aria-label="breadcrumb"
                  style={{
                    position: 'absolute',
                  }}
                >
                  <Link color="inherit" href="/">
                    <PublicIcon />
                  </Link>
                  <Link
                    className="bread-crumb-font inactive-breadcrumb-label"
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/new-simulation"
                  >
                    New Simulation{'    '}
                  </Link>
                  <Link
                    underline="hover"
                    className="bread-crumb-font inactive-breadcrumb-label"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/simulation-report"
                  >
                    Simulation Report(s)
                  </Link>
                  <Typography style={{ color: 'black', fontWeight: '500' }} className="bread-crumb-font">
                    Corrosion risk report - {breadCrumbDate}
                  </Typography>
                </Breadcrumbs>
              )}
            </div>
            <div style={{ marginTop: '-15px' }}>
              <BhDivider></BhDivider>
            </div>

            <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px', flexDirection: 'column', marginBottom: '20px' }}>
              <div style={{ height: '50px' }}>
                <BhTitleWrapper
                  data-testid="primary-report-title"
                  type="subtitle"
                  header="Corrosion risk report"
                  subtext={subHeading}
                ></BhTitleWrapper>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px', marginRight: '30px' }}>
              <PrimaryReport
                primaryReportData={location.state ? location.state.reportData : null}
                inputData={ionicInput}
                defaultUOM={defaultUOM}
                configData={location.state ? location.state.configDetails : null}
                pageName={location.state ? location.state.pageName : null}
                setDateOfReport={updateReportDate}
              />
              <ReportComments
                pageName={location.state ? location.state.pageName : null}
                reportData={location.state ? location.state.reportData : null}
                resultDataId={
                  location.state ? parseInt(location.state.reportData[Object.keys(location.state.reportData)[0]].resultDataId) : null
                }
                setReportComments={updateComments}
                recommendations={recommendations}
                modifiedBy={reportModified}
              ></ReportComments>
              {(location.state && location.state.modeType && location.state.modeType ==='Actual') ?
              <SaltTemperatureGraph saltGraphApiResult={saltGraphApiResult} defaultUOM={defaultUOM} isCokerOrFcc={isCokerOrFcc}></SaltTemperatureGraph>:null}
              <SimulationInput isCokerOrFcc={isCokerOrFcc} ionicInput={ionicInput} configData={location.state ? location.state.configDetails : null}></SimulationInput>
              <div>
                <BhActionBar
                  footerActions={[
                    {
                      type: 'primary',
                      label: 'Download Report',
                      key: 'btnDownload',
                    },
                  ]}
                  onBhEventCtaClick={(event: any) => downloadPdfDocument()}
                ></BhActionBar>
              </div>
            </div>
          </div>
          <div id="download-pdf" className="corrosion-pdf" style={{ display: 'none' }}>
            {/* <div id="download-pdf" className="corrosion-pdf"> */}
            <div className="corrosion-risk-pdf-header">
              <p className="corrosion-risk-header">TOPGUARD™ Corrosion Risk Monitor</p>
              <img className="bh-logo-pdf" src="/assets/images/bh_pdf_logo.jpg"></img>
            </div>

            <PrimaryReportPDF
              primaryReportData={location.state ? location.state.reportData : null}
              inputData={ionicInput}
              defaultUOM={defaultUOM}
              configData={location.state ? location.state.configDetails : null}
              recommComments={recommendations}
              subText={subText}
              mainText={mainText}
              modeType={location.state && location.state.modeType}
              sandBoxSimulationName={sandBoxSimulationName}
            />
            <div className="corrosion-risk-pdf-header">
              <p className="corrosion-risk-header">TOPGUARD™ Corrosion Risk Monitor</p>
              <img
                className="bh-logo-pdf"
                src="/assets/images/bh_pdf_logo.jpg
          "
              ></img>
            </div>
            {(location.state && location.state.modeType && location.state.modeType ==='Actual') ?
            <SaltTemperatureGraphPDF
              primaryReportData={location.state ? location.state.reportData : null}
              saltGraphApiResult={saltGraphApiResult}
              defaultUOM={defaultUOM}
              subText={subText}
              mainText={mainText}
              modeType={location.state && location.state.modeType}
              sandBoxSimulationName={sandBoxSimulationName}
              isCokerOrFcc={isCokerOrFcc}
            /> :null}
          </div>

          <div>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
              open={open}
              onClick={handleClose}
            >
              {open ? <BhSpinner size="large"></BhSpinner> : null}
            </Backdrop>
          </div>
        </>
      )}
    </div>
  );
};

export default CorrosionRiskReport;
