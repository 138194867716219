import { exit } from 'process';
import React from 'react';
import { APIEnum } from '../constants/api-enum';
export type AssetType = {
  assetId: string;
  assetName: string;
  parentAssetId: string;
};

export type requestType = 'GET' | 'POST' | 'PUT' | 'DELETE';

const apiRequest = async (url = '', data = {}, type: requestType, isModeGiven?:boolean, modeType?:string) => {
  // Default options are marked with *
  let methodType: requestType = type;
  let headerObject: any = {
    method: methodType, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      //commnet line 48 and  un comment 49  and  replace the token to  work in local environment
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('okta-token-storage') || '{}')?.accessToken?.accessToken}`,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };
  //add new header based on the mode given
  if(isModeGiven)
  headerObject = {...headerObject, headers: {...headerObject.headers, 'Mode-Type': modeType}};
  // For get request body no need
  if (type !== APIEnum.GET) {
    headerObject.body = JSON.stringify(data);
  }

  const response = await fetch(url, headerObject);

  if (response.status === 401) {
    return;
  } else if (response.status === 503) {
    let errJson = {
      status: 'Failure',
      errors: [
        {
          status: 'ROD-9001',
        },
      ],
    };
    return errJson;
  } else if (response.status === 204 || response.status === 404) {
    return;
  } else {
    return response.json(); // parses JSON response into native JavaScript objects
  }
};

export default apiRequest;
