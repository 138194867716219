export const validateSimulationData = (configType: any, unitType: any, values: any, isWithDesalter: any, isWaterFlow:boolean) => {
  let isMandatoryFieldsFilled = false;
  if (configType === '1A') {
    if (unitType !== 'coker' && unitType !== 'fcc' && unitType !== 'hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') {
      if (
        Object.keys(values).length > 0 &&
        values.crudecharge &&
        values.crudegravity &&
        values.washpercentage &&
        values.desaltertemp &&
        values.brineph &&
        values.brinesampletemp &&
        values.steamtower &&
        values.washtemp &&
        values.washprocesstemp &&
        values.velocitytemp &&
        values.drumtemp &&
        values.product &&
        values.gravity &&
        values.distillation10 &&
        values.distillation50 &&
        values.distillation90 &&
        values.ph &&
        values.combinedRefluxFlow &&
        values.combinedRefluxTemperature &&
        ((isWaterFlow && values.overHeadWaterFlow) || !isWaterFlow)
      )
        isMandatoryFieldsFilled = true;
    } else if ((unitType === 'coker' || unitType === 'fcc' || unitType === 'hydrocracker'|| unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && !isWithDesalter) {
      if (
        Object.keys(values).length > 0 &&
        values.steamtower &&
        values.washtemp &&
        values.washprocesstemp &&
        values.velocitytemp &&
        values.drumtemp &&
        values.product &&
        values.gravity &&
        values.distillation10 &&
        values.distillation50 &&
        values.distillation90 &&
        values.ph
      )
        isMandatoryFieldsFilled = true;
    } else {
      if (
        Object.keys(values).length > 0 &&
        values.crudecharge &&
        values.steamtower &&
        values.washtemp &&
        values.washprocesstemp &&
        values.velocitytemp &&
        values.drumtemp &&
        values.product &&
        values.gravity &&
        values.distillation10 &&
        values.distillation50 &&
        values.distillation90 &&
        values.ph &&
        values.combinedRefluxFlow &&
        values.combinedRefluxTemperature
      )
        isMandatoryFieldsFilled = true;
    }
  } else if (configType === '1B') {
    if (
      Object.keys(values).length > 0 &&
      values.crudecharge &&
      values.crudegravity &&
      values.washpercentage &&
      values.wash2percentage &&
      values.desaltertemp &&
      values.brineph &&
      values.brineph2 &&
      values.brinesampletemp &&
      values.steamtower &&
      values.washtemp &&
      values.washprocesstemp &&
      values.velocitytemp &&
      values.drumtemp &&
      values.product &&
      values.gravity &&
      values.distillation10 &&
      values.distillation50 &&
      values.distillation90 &&
      values.ph &&
      values.combinedRefluxFlow &&
      values.combinedRefluxTemperature &&
      ((isWaterFlow && values.overHeadWaterFlow) || !isWaterFlow)
    )
      isMandatoryFieldsFilled = true;
  } else if (configType === '2A') {
    if (
      Object.keys(values).length > 0 &&
      values.crudecharge &&
      values.crudegravity &&
      values.washpercentage &&
      values.desaltertemp &&
      values.brineph &&
      values.brinesampletemp &&
      values.steamtower &&
      values.washtemp &&
      values.washprocesstemp &&
      values.velocitytemp &&
      values.drumtemp &&
      values.product &&
      values.gravity &&
      values.distillation10 &&
      values.distillation50 &&
      values.distillation90 &&
      values.product_1 &&
      values.gravity_1 &&
      values.distillation10_1 &&
      values.distillation50_1 &&
      values.distillation90_1 &&
      values.ph &&
      values.ph2 &&
      values.combinedRefluxFlow &&
      values.combinedRefluxTemperature &&
      ((isWaterFlow && values.overHeadWaterFlow) || !isWaterFlow) &&
      ((isWaterFlow && values.overHeadWaterFlow2) || !isWaterFlow)
    )
      isMandatoryFieldsFilled = true;
  } else if (configType === '2C') {
    if (
      Object.keys(values).length > 0 &&
      values.crudecharge &&
      values.crudegravity &&
      values.steamtower &&
      values.washtemp &&
      values.washprocesstemp &&
      values.velocitytemp &&
      values.drumtemp &&
      values.product &&
      values.gravity &&
      values.distillation10 &&
      values.distillation50 &&
      values.distillation90 &&
      values.product_1 &&
      values.gravity_1 &&
      values.distillation10_1 &&
      values.distillation50_1 &&
      values.distillation90_1 &&
      values.ph &&
      values.ph2 &&
      values.combinedRefluxFlow &&
      values.combinedRefluxTemperature &&
      ((isWaterFlow && values.overHeadWaterFlow) || !isWaterFlow) &&
      ((isWaterFlow && values.overHeadWaterFlow2) || !isWaterFlow)
    )
      isMandatoryFieldsFilled = true;
  } else if (configType === '1C') {
    if (
      Object.keys(values).length > 0 &&
      values.crudecharge &&
      values.crudegravity &&
      values.washpercentage &&
      values.desaltertemp &&
      values.brineph &&
      values.brinesampletemp &&
      values.steamtower &&
      values.washtemp &&
      values.washprocesstemp &&
      values.velocitytemp &&
      values.drumtemp &&
      values.product &&
      values.gravity &&
      values.distillation10 &&
      values.distillation50 &&
      values.distillation90 &&
      values.ph &&
      values.combinedRefluxFlow &&
      values.combinedRefluxTemperature &&
      ((isWaterFlow && values.overHeadWaterFlow) || !isWaterFlow)
    )
      isMandatoryFieldsFilled = true;
  } else {
    if (
      Object.keys(values).length > 0 &&
      values.crudecharge &&
      values.crudegravity &&
      values.washpercentage &&
      values.wash2percentage &&
      values.desaltertemp &&
      values.brineph &&
      values.brineph2 &&
      values.brinesampletemp &&
      values.steamtower &&
      values.washtemp &&
      values.washprocesstemp &&
      values.velocitytemp &&
      values.drumtemp &&
      values.product &&
      values.gravity &&
      values.distillation10 &&
      values.distillation50 &&
      values.distillation90 &&
      values.product_1 &&
      values.gravity_1 &&
      values.distillation10_1 &&
      values.distillation50_1 &&
      values.distillation90_1 &&
      values.ph &&
      values.ph2 &&
      values.combinedRefluxFlow &&
      values.combinedRefluxTemperature &&
      ((isWaterFlow && values.overHeadWaterFlow) || !isWaterFlow) &&
      ((isWaterFlow && values.overHeadWaterFlow2) || !isWaterFlow)
    )
      isMandatoryFieldsFilled = true;
  }

  return isMandatoryFieldsFilled;
};

export const validateSimulationManual = (configType: any, unitType: any, simulationData: any, isWithDesalter: any, isOverheadWaterFlow:any) => {
  let isMandatoryFieldsFilled = false;
  if (configType === '1A') {
    if (unitType !== 'coker' && unitType !== 'fcc' && unitType !== 'hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') {
      if (
        simulationData.desalter.crudeCharge &&
        simulationData.desalter.crudeGravity &&
        simulationData.desalter.washPercentageData &&
        simulationData.desalter.washPercentageData.washPercentage &&
        simulationData.desalter.desalterTemperature &&
        simulationData.desalter.brinepHData &&
        simulationData.desalter.brinepHData.brinepH &&
        simulationData.desalter.brineSampleTemperature &&
        simulationData.towerData &&
        simulationData.towerData.steamTower &&
        simulationData.overheadConditions &&
        simulationData.overheadConditions.washTemp &&
        simulationData.overheadConditions.washProcessTemp &&
        simulationData.overheadConditions.velocityTemp &&
        simulationData.overheadConditions.drumTemp &&
        simulationData.liquidAssayStreams &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.product &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.gravity &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[10] &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[50] &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[90] &&
        simulationData.overheadWater &&
        simulationData.overheadWater.pHData &&
        simulationData.overheadWater.pHData.pH &&
        simulationData.overheadWater.combinedRefluxData.combinedRefluxFlow &&
        simulationData.overheadWater.combinedRefluxData.combinedRefluxTemp &&
        ((isOverheadWaterFlow && simulationData.overheadWater.overHeadWaterFlowData.overHeadWaterFlow) || !isOverheadWaterFlow)
      )
        isMandatoryFieldsFilled = true;
    } else if ((unitType === 'coker' || unitType === 'fcc' || unitType === 'hydrocracker'|| unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && !isWithDesalter) {
      if (
        simulationData.towerData &&
        simulationData.towerData.steamTower &&
        simulationData.overheadConditions &&
        simulationData.overheadConditions.washTemp &&
        simulationData.overheadConditions.washProcessTemp &&
        simulationData.overheadConditions.velocityTemp &&
        simulationData.overheadConditions.drumTemp &&
        simulationData.liquidAssayStreams &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.product &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.gravity &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[10] &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[50] &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[90] &&
        simulationData.overheadWater &&
        simulationData.overheadWater.pHData &&
        simulationData.overheadWater.pHData.pH
      )
        isMandatoryFieldsFilled = true;
    } else {
      if (
        simulationData.desalter.crudeCharge &&
        simulationData.towerData &&
        simulationData.towerData.steamTower &&
        simulationData.overheadConditions &&
        simulationData.overheadConditions.washTemp &&
        simulationData.overheadConditions.washProcessTemp &&
        simulationData.overheadConditions.velocityTemp &&
        simulationData.overheadConditions.drumTemp &&
        simulationData.liquidAssayStreams &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.product &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.gravity &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[10] &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[50] &&
        simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[90] &&
        simulationData.overheadWater &&
        simulationData.overheadWater.pHData &&
        simulationData.overheadWater.pHData.pH
      )
        isMandatoryFieldsFilled = true;
    }
  } else if (configType === '1B') {
    if (
      simulationData.desalter.crudeCharge &&
      simulationData.desalter.crudeGravity &&
      simulationData.desalter.washPercentageData &&
      simulationData.desalter.washPercentageData.washPercentage &&
      simulationData.desalter.washPercentageData.washPercentage2 &&
      simulationData.desalter.desalterTemperature &&
      simulationData.desalter.brinepHData &&
      simulationData.desalter.brinepHData.brinepH &&
      simulationData.desalter.brinepHData.brinepH2 &&
      simulationData.desalter.brineSampleTemperature &&
      simulationData.towerData &&
      simulationData.towerData.steamTower &&
      simulationData.overheadConditions &&
      simulationData.overheadConditions.washTemp &&
      simulationData.overheadConditions.washProcessTemp &&
      simulationData.overheadConditions.velocityTemp &&
      simulationData.overheadConditions.drumTemp &&
      simulationData.liquidAssayStreams &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid.product &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid.gravity &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[10] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[50] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[90] &&
      simulationData.overheadWater &&
      simulationData.overheadWater.pHData &&
      simulationData.overheadWater.pHData.pH &&
      simulationData.overheadWater.combinedRefluxData.combinedRefluxFlow &&
      simulationData.overheadWater.combinedRefluxData.combinedRefluxTemp &&
      ((isOverheadWaterFlow && simulationData.overheadWater.overHeadWaterFlowData.overHeadWaterFlow) || !isOverheadWaterFlow)
    )
      isMandatoryFieldsFilled = true;
  } else if (configType === '2A') {
    if (
      simulationData.desalter.crudeCharge &&
      simulationData.desalter.crudeGravity &&
      simulationData.desalter.washPercentageData &&
      simulationData.desalter.washPercentageData.washPercentage &&
      simulationData.desalter.desalterTemperature &&
      simulationData.desalter.brinepHData &&
      simulationData.desalter.brinepHData.brinepH &&
      simulationData.desalter.brineSampleTemperature &&
      simulationData.towerData &&
      simulationData.towerData.steamTower &&
      simulationData.overheadConditions &&
      simulationData.overheadConditions.washTemp &&
      simulationData.overheadConditions.washProcessTemp &&
      simulationData.overheadConditions.velocityTemp &&
      simulationData.overheadConditions.drumTemp &&
      simulationData.liquidAssayStreams &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.product &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.gravity &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.product &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.gravity &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90] &&
      simulationData.overheadWater &&
      simulationData.overheadWater.pHData &&
      simulationData.overheadWater.pHData.pH &&
      simulationData.overheadWater.pHData.pH_2 &&
      simulationData.overheadWater.combinedRefluxData.combinedRefluxFlow &&
      simulationData.overheadWater.combinedRefluxData.combinedRefluxTemp &&
      ((isOverheadWaterFlow && simulationData.overheadWater.overHeadWaterFlowData.overHeadWaterFlow) || !isOverheadWaterFlow) &&
      ((isOverheadWaterFlow && simulationData.overheadWater.overHeadWaterFlowData.overHeadWaterFlow2) || !isOverheadWaterFlow)
    )
      isMandatoryFieldsFilled = true;
  } else if (configType === '1C') {
    if (
      simulationData.desalter.crudeCharge &&
      simulationData.desalter.crudeGravity &&
      simulationData.towerData &&
      simulationData.towerData.steamTower &&
      simulationData.overheadConditions &&
      simulationData.overheadConditions.washTemp &&
      simulationData.overheadConditions.washProcessTemp &&
      simulationData.overheadConditions.velocityTemp &&
      simulationData.overheadConditions.drumTemp &&
      simulationData.liquidAssayStreams &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid.product &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid.gravity &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[10] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[50] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquid.distillation[90] &&
      simulationData.overheadWater &&
      simulationData.overheadWater.pHData &&
      simulationData.overheadWater.pHData.pH &&
      simulationData.overheadWater.combinedRefluxData.combinedRefluxFlow &&
      simulationData.overheadWater.combinedRefluxData.combinedRefluxTemp &&
      ((isOverheadWaterFlow && simulationData.overheadWater.overHeadWaterFlowData.overHeadWaterFlow) || !isOverheadWaterFlow)
    )
      isMandatoryFieldsFilled = true;
  } else if (configType === '2C') {
    if (
      simulationData.desalter.crudeCharge &&
      simulationData.desalter.crudeGravity &&
      simulationData.towerData &&
      simulationData.towerData.steamTower &&
      simulationData.overheadConditions &&
      simulationData.overheadConditions.washTemp &&
      simulationData.overheadConditions.washProcessTemp &&
      simulationData.overheadConditions.velocityTemp &&
      simulationData.overheadConditions.drumTemp &&
      simulationData.liquidAssayStreams &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.product &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.gravity &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.product &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.gravity &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90] &&
      simulationData.overheadWater &&
      simulationData.overheadWater.pHData &&
      simulationData.overheadWater.pHData.pH &&
      simulationData.overheadWater.pHData.pH_2 &&
      simulationData.overheadWater.combinedRefluxData.combinedRefluxFlow &&
      simulationData.overheadWater.combinedRefluxData.combinedRefluxTemp &&
      ((isOverheadWaterFlow && simulationData.overheadWater.overHeadWaterFlowData.overHeadWaterFlow) || !isOverheadWaterFlow) &&
      ((isOverheadWaterFlow && simulationData.overheadWater.overHeadWaterFlowData.overHeadWaterFlow2) || !isOverheadWaterFlow)
    )
      isMandatoryFieldsFilled = true;
  } else {
    if (
      simulationData.desalter.crudeCharge &&
      simulationData.desalter.crudeGravity &&
      simulationData.desalter.washPercentageData &&
      simulationData.desalter.washPercentageData.washPercentage &&
      simulationData.desalter.washPercentageData.washPercentage2 &&
      simulationData.desalter.desalterTemperature &&
      simulationData.desalter.brinepHData &&
      simulationData.desalter.brinepHData.brinepH &&
      simulationData.desalter.brinepHData.brinepH2 &&
      simulationData.desalter.brineSampleTemperature &&
      simulationData.towerData &&
      simulationData.towerData.steamTower &&
      simulationData.overheadConditions &&
      simulationData.overheadConditions.washTemp &&
      simulationData.overheadConditions.washProcessTemp &&
      simulationData.overheadConditions.velocityTemp &&
      simulationData.overheadConditions.drumTemp &&
      simulationData.liquidAssayStreams &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.product &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.gravity &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.product &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.gravity &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50] &&
      simulationData.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90] &&
      simulationData.overheadWater &&
      simulationData.overheadWater.pHData &&
      simulationData.overheadWater.pHData.pH &&
      simulationData.overheadWater.pHData.pH_2 &&
      simulationData.overheadWater.combinedRefluxData.combinedRefluxFlow &&
      simulationData.overheadWater.combinedRefluxData.combinedRefluxTemp &&
      ((isOverheadWaterFlow && simulationData.overheadWater.overHeadWaterFlowData.overHeadWaterFlow) || !isOverheadWaterFlow) &&
      ((isOverheadWaterFlow && simulationData.overheadWater.overHeadWaterFlowData.overHeadWaterFlow2) || !isOverheadWaterFlow)
    )
      isMandatoryFieldsFilled = true;
  }

  return isMandatoryFieldsFilled;
};

export const overHeadConditions = (element: any, isFromReport: any, tempUnit: any) => {
  return {
    OHTemp: parseFloat(element.overheadConditions.OHTemp ? element.overheadConditions.OHTemp : 0),
    OHTemp_UOM: !isFromReport ? tempUnit : element.overheadConditions.OHTemp_UOM,
    OHPress: parseFloat(element.overheadConditions.OHPress ? element.overheadConditions.OHPress : 0),
    OHPress_UOM: element.overheadConditions.OHPress_UOM,
    washRate: parseFloat(element.overheadConditions.washRate ? element.overheadConditions.washRate : 0),
    washRate_UOM: element.overheadConditions.washRate_UOM,
    washTemp: parseFloat(element.overheadConditions.washTemp),
    washTemp_UOM: !isFromReport ? tempUnit : element.overheadConditions.washTemp_UOM,
    washProcessTemp: parseFloat(element.overheadConditions.washProcessTemp),
    washProcessPress: parseFloat(element.overheadConditions.washProcessPress ? element.overheadConditions.washProcessPress : 0),
    washProcessPress_UOM: element.overheadConditions.washProcessPress_UOM,
    velocityTemp: parseFloat(element.overheadConditions.velocityTemp),
    velocityPress: parseFloat(element.overheadConditions.velocityPress ? element.overheadConditions.velocityPress : 0),
    velocityPress_UOM: element.overheadConditions.velocityPress_UOM,
    drumTemp: parseFloat(element.overheadConditions.drumTemp ? element.overheadConditions.drumTemp : 0),
    drumTemp_UOM: !isFromReport ? tempUnit : element.overheadConditions.drumTemp_UOM,
    drumPress: parseFloat(element.overheadConditions.drumPress ? element.overheadConditions.drumPress : 0),
    drumPress_UOM: element.overheadConditions.drumPress_UOM,
    totalExternalWashRate_UOM: element.overheadConditions.totalExternalWashRate_UOM,
    totalExternalWashRate: parseFloat(
      element.overheadConditions.totalExternalWashRate ? element.overheadConditions.totalExternalWashRate : 0
    ),
  };
};

export const towerData = (element: any, unitType: string, isWithDesalter: boolean) => {
  return {
    steamTower: parseFloat(element.towerData.steamTower),
    steamTower_UOM: element.towerData.steamTower_UOM,
    steam1SS: parseFloat(element.towerData.steam1SS ? element.towerData.steam1SS : 0),
    steam1SS_UOM: element.towerData.steam1SS_UOM,
    steam2SS: parseFloat(element.towerData.steam2SS ? element.towerData.steam2SS : 0),
    steam2SS_UOM: element.towerData.steam2SS_UOM,
    steam3SS: parseFloat(element.towerData.steam3SS ? element.towerData.steam3SS : 0),
    steam3SS_UOM: element.towerData.steam3SS_UOM,
    steam4SS:
      (unitType !== 'coker' && unitType !== 'fcc' && unitType !== 'hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') || ((unitType === 'coker' || unitType === 'fcc' || unitType === 'hydrocracker'|| unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && !isWithDesalter)
        ? parseFloat(element.towerData.steam4SS ? element.towerData.steam4SS : 0)
        : null,
    steam4SS_UOM:
      (unitType !== 'coker' && unitType !== 'fcc' && unitType !== 'hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') || ((unitType === 'coker' || unitType === 'fcc' || unitType === 'hydrocracker'|| unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && !isWithDesalter)
        ? element.towerData.steam4SS_UOM
        : null,
    steam5SS:
      (unitType !== 'coker' && unitType !== 'fcc' && unitType !== 'hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') || ((unitType === 'coker' || unitType === 'fcc' || unitType === 'hydrocracker'|| unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && !isWithDesalter)
        ? parseFloat(element.towerData.steam5SS ? element.towerData.steam5SS : 0)
        : null,
    steam5SS_UOM:
      (unitType !== 'coker' && unitType !== 'fcc' && unitType !== 'hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') || ((unitType === 'coker' || unitType === 'fcc' || unitType === 'hydrocracker'|| unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && !isWithDesalter)
        ? element.towerData.steam5SS_UOM
        : null,
    steam6SS:
      (unitType !== 'coker' && unitType !== 'fcc' && unitType !== 'hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') || ((unitType === 'coker' || unitType === 'fcc' || unitType === 'hydrocracker'|| unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && !isWithDesalter)
        ? parseFloat(element.towerData.steam6SS ? element.towerData.steam6SS : 0)
        : null,
    steam6SS_UOM:
      (unitType !== 'coker' && unitType !== 'fcc' && unitType !== 'hydrocracker' && unitType !=='hydrotreater-distillate' && unitType !=='hydrotreater-gas oil' && unitType !=='hydrotreater-naphtha' && unitType !=='hydrotreater-naphtha renewable' && unitType !=='hydrotreater-resid') || ((unitType === 'coker' || unitType === 'fcc' || unitType === 'hydrocracker'|| unitType ==='hydrotreater-distillate' || unitType ==='hydrotreater-gas oil' || unitType ==='hydrotreater-naphtha' || unitType ==='hydrotreater-naphtha renewable' || unitType ==='hydrotreater-resid') && !isWithDesalter)
        ? element.towerData.steam6SS_UOM
        : null,
  };
};

export const MAX_CHECK = 'It should be less than Wash Injection Rate';

export const getMax = (simulationData: any) => {
  if (simulationData?.overheadConditions?.washRateMax) {
    return simulationData?.overheadConditions?.washRateMax;
  } else {
    return 500;
  }
};

export const getLabel = () => {
  return {
    title: 'Overhead Primary Wash Injection Rate',
    toolTip:
      'Total wash rate injected into first injection including the sum of all parallel injections regardless of recirculated or external source.  Do not include downstream supplemental injection rate.',
  };
};

export const distillationFor_2A_2B = (element: any, isFromReport: any, tempUnit: any) => {
  return {
    0: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[0]),
    5: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[5]),
    10: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[10]),
    20: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[20]),
    30: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[30]),
    50: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[50]),
    70: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[70]),
    80: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[80]),
    90: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[90]),
    95: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[95]),
    100: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.distillation[100]),
    distillation_UOM: !isFromReport ? tempUnit : element.liquidAssayStreams.hydrocarbonLiquidCold.distillation.distillation_UOM,
  };
};

export const distillationFor_HOT_2A_2B = (element: any, isFromReport: any, tempUnit: any) => {
  return {
    0: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[0]),
    5: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[5]),
    10: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[10]),
    20: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[20]),
    30: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[30]),
    50: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[50]),
    70: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[70]),
    80: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[80]),
    90: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[90]),
    95: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[95]),
    100: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.distillation[100]),
    distillation_UOM: !isFromReport ? tempUnit : element.liquidAssayStreams.hydrocarbonLiquidHot.distillation.distillation_UOM,
  };
};

export const getToolTipForWaterToDesalter = () => {
  return 'Percentage of the total atmospheric overhead water that goes directly to the desalter wash \n%Ovhd to Desalters = Ovhd Flow to Desalters / Total Ovhd Flow x 100';
};

export const getToolTipForOverheadWaterToSWS = () => {
  return 'Percentage of the total atmospheric overhead water that goes directly to the sour water stripper \n%Ovhd to SWS = Ovhd Flow to SWS / Total Ovhd Flow x 100';
};

export const getToolTipForTotalSSWToDesalterWashWater = () => {
  return 'Percentage of the total stripped sour water that goes directly to the desalter wash \n%SSW to Desalters = SSW Flow to Desalters / Total SSW Flow x 100';
};


export const getDesalterFor1CAnd2C = (elementFor1C2C :any, tempUnitFor1C2C :any) => {
  return {
    chargeGravity: null,
    crudeCharge: parseFloat(elementFor1C2C.desalter.crudeCharge),
    crudeCharge_UOM : elementFor1C2C.desalter.crudeCharge_UOM,
    crudeGravity:  parseFloat(elementFor1C2C.desalter.crudeGravity),
    washPercentageData: {
      washPercentage: null,
    },
    brineRecyclePercentage: null,
    percentageAtmH2OtoDesalter:  null,
    percentageAtmH2OtoSWS:  null,
    percentageSSWtoDesalter: null,
    desalterTemperature: null,
    desalterTemperature_UOM:  tempUnitFor1C2C,
    brinepHData: {
      brinepH:  null,
    },
    brineSampleTemperature:  null,
    desCrudeBSWData: {
      desCrudeBSW:  parseFloat(elementFor1C2C.desalter.desCrudeBSWData.desCrudeBSW ? elementFor1C2C.desalter.desCrudeBSWData.desCrudeBSW : 0),
    }
  }

};